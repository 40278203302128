import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  FastField,
  Field,
  Form,
  Formik,
} from 'formik';
import {
  Select,
  CheckboxWithLabel,
  TextField,
} from 'formik-mui';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  MenuItem,
  FormLabel,
  FormControl,
  FormControlLabel,
  Switch as MUISwitch,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { exportDataPropType } from 'generic/core/qes/proptypes';

const ExportDialog = ({
  maxNbMassiveExport,
  handleClose,
  handleSubmit,
  exportFields,
  exportFormats,
  exportMassiveFormats,
  checkedItems,
  numberResults,
  loading,
}) => {
  const { t } = useTranslation();
  const numberToExport = numberResults < maxNbMassiveExport ? numberResults : maxNbMassiveExport;
  const numberInSelection = _.size(checkedItems);

  const handleMassiveChange = (event, form) => {
    const massive = event.target.value !== '1';
    const number = massive ? numberToExport : numberInSelection;
    form.setFieldValue('togglemassif', event.target.value);
    form.setFieldValue('massif', massive);
    form.setFieldValue('nombre', number);
  };

  const handleFormatChange = (event, form) => {
    const format = event.target.value;
    if (!exportMassiveFormats[format]) {
      form.setFieldValue('togglemassif', '1');
      form.setFieldValue('massif', false);
      form.setFieldValue('nombre', numberInSelection);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        format: exportFormats[0].code,
        togglemassif: '1',
        massif: false,
        nombre: numberInSelection,
        champ: '',
      }}
      validationSchema={Yup.object().shape({
        nombre: Yup.number()
          .typeError(t('form.errors.must_be_integer'))
          .integer(t('form.errors.must_be_integer'))
          .min(0, `${t('form.errors.must_be_more_than')} 0`)
          .max(numberToExport, `${t('form.errors.must_be_less_than')} ${numberToExport}`),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {(form) => (
        <Form>
          <Dialog
            scroll="paper"
            fullWidth
            maxWidth="lg"
            PaperProps={{
              sx: { minHeight: '60%' },
            }}
            open
          >
            <DialogTitle
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '39px' }}
            >
              {form.values.massif
                ? t('export.number_exported_docs', { count: +form.values.nombre })
                : t('export.number_exported_docs', { count: numberInSelection })}
              { exportMassiveFormats[form.values.format] && (
                <FormControlLabel
                  control={(
                    <ToggleButtonGroup
                      color="primary"
                      exclusive
                      onChange={(event) => handleMassiveChange(event, form)}
                      value={form.values.togglemassif}
                      size="small"
                    >
                      <ToggleButton value="1">
                        {t('export.selection')}
                      </ToggleButton>
                      <ToggleButton value="2">
                        {t('export.massive')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              )}
            </DialogTitle>
            <DialogContent
              dividers
              sx={{ display: 'flex', flexDirection: 'column', minHeight: '70%' }}
            >
              <Box
                mb={2}
              >
                {!_.isEmpty(exportFormats) && (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Field
                      component={Select}
                      name="format"
                      label={t('export.select_format')}
                      sx={{ maxWidth: '200px' }}
                      MenuProps={{
                        disablePortal: true,
                      }}
                      onChange={(event) => handleFormatChange(event, form)}
                    >
                      {_.map(
                        exportFormats,
                        (format) => (
                          <MenuItem
                            key={format.code}
                            value={format.code}
                          >
                            {format.libelle}
                          </MenuItem>
                        ),
                      )}
                    </Field>
                    { exportMassiveFormats[form.values.format] && form.values.massif ? (
                      <FastField
                        component={TextField}
                        label={t('export.number_to_export', { max: numberToExport })}
                        name="nombre"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        sx={{ marginLeft: '1em' }}
                      />
                    ) : (
                      <FastField
                        component={TextField}
                        label={t('export.number_to_export_sel')}
                        name="nombre"
                        disabled
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        sx={{ marginLeft: '1em' }}
                      />
                    )}
                  </Box>
                )}
                {!_.isEmpty(exportFields) && (
                  <FormControl
                    component="fieldset"
                    className="formFieldset"
                    sx={{ marginTop: '2em' }}
                    fullWidth
                  >
                    <FormLabel
                      component="legend"
                      disabled={form.values.format !== 'csv' && form.values.format !== 'excel'}
                    >
                      <Box component="span" mr={2}>{t('export.select_fields')}</Box>
                      <FormControlLabel
                        control={(
                          <MUISwitch
                            size="small"
                            type="checkbox"
                            color="primary"
                            onChange={(e) => {
                              form.setFieldValue(
                                'champ',
                                e.target.checked ? _.map(exportFields, (item) => item.code) : [],
                              );
                            }}
                            disabled={form.values.format !== 'csv' && form.values.format !== 'excel'}
                          />
                        )}
                        sx={{ mr: '0' }}
                        label={t('form.checkbox.all')}
                      />
                    </FormLabel>
                    <Box
                      sx={{
                        columnCount: {
                          xs: 1,
                          sm: 2,
                          md: 3,
                          lg: 4,
                          xl: 5,
                        },
                      }}
                    >
                      {_.map(
                        exportFields,
                        (field) => (
                          <Field
                            component={CheckboxWithLabel}
                            name="champ"
                            key={field.code}
                            value={field.code}
                            Label={{
                              label: field.libelle,
                              sx: { width: '100%' },
                            }}
                            type="checkbox"
                            color="primary"
                            disabled={form.values.format !== 'csv' && form.values.format !== 'excel'}
                          />
                        ),
                      )}
                    </Box>
                  </FormControl>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('dialog.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                loading={loading}
                disabled={
                  !form.isValid
                  || (form.values.format.length === 0
                  || ((form.values.format === 'csv' || form.values.format === 'excel')
                  && form.values.champ.length === 0))
                }
              >
                {t('actions.export')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

ExportDialog.propTypes = {
  maxNbMassiveExport: PropTypes.number,
  exportFields: PropTypes.arrayOf(exportDataPropType),
  exportFormats: PropTypes.arrayOf(PropTypes.shape({
    libelle: PropTypes.string,
    code: PropTypes.string,
  })),
  exportMassiveFormats: PropTypes.shape(),
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  checkedItems: PropTypes.shape(),
  numberResults: PropTypes.number,
};

ExportDialog.defaultProps = {
  maxNbMassiveExport: 1000,
  exportFields: [],
  exportFormats: [],
  exportMassiveFormats: {},
  checkedItems: {},
  numberResults: 0,
};

export default ExportDialog;
