/* eslint-disable camelcase */
import i18n from 'i18next';
import _ from 'lodash';
import { types } from 'generic/core/anr/actions';
import { treeFlattener } from 'generic/utils/utils';
import { getArticleIframeUrl } from 'generic/utils/anrUtils';
import ANR_CONSTANTS from 'generic/core/anr/constants';

const { URL } = ANR_CONSTANTS;

const initialFiltersParams = {
  subquery: '',
};

const anrReducer = (
  state = {
    activeItem: {},
    articles: [],
    bannedSites: null,
    dialogBanSite: {},
    dialogDemandSource: {},
    dialogShare: {},
    editFolder: {},
    editFolderLoading: false,
    folders: null,
    loading: false,
    responseData: {},
    saveFolderLoading: false,
    sources: null,
    user: {},
  },
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_ANR_USER: {
      return {
        ...state,
        user: {},
      };
    }
    case types.FETCH_ANR_USER: {
      return {
        ...state,
        user: {},
        loading: true,
      };
    }
    case types.FETCH_ANR_USER_SUCCESS: {
      const alteredUser = { ...action.user };
      alteredUser.rights = {};
      _.map(action.user.roles, (role) => {
        alteredUser.rights[role.name] = true;
      });
      alteredUser.rights.glimpse = action.user.glimpse;
      alteredUser.rights.quotes = action.user.quotes;
      return {
        ...state,
        user: alteredUser,
        loading: false,
      };
    }
    case types.FETCH_ANR_USER_ERROR: {
      return {
        ...state,
        user: {},
        loading: false,
      };
    }
    case types.CLEANUP_ANR_FOLDERS: {
      return {
        ...state,
        activeItem: {},
        articles: [],
        folders: null,
      };
    }
    case types.FETCH_ANR_FOLDERS: {
      return {
        ...state,
        folders: [],
        foldersLoading: true,
      };
    }
    case types.FETCH_ANR_FOLDERS_SUCCESS: {
      return {
        ...state,
        folders: action.folders,
        foldersLoading: false,
      };
    }
    case types.FETCH_ANR_FOLDERS_ERROR: {
      return {
        ...state,
        folders: [],
        foldersLoading: false,
      };
    }
    case types.CHANGE_ACTIVE_ITEM: {
      const { id, view } = action.routeParams;
      const flattenedFolders = treeFlattener(_.flatten(state.folders));
      let activeItem;
      if (view === 'glimpse') {
        activeItem = {
          id: 'glimpse',
          title: i18n.t('anr.glimpse'),
          has_news: true,
        };
      } else if (_.includes(['copy', 'new'], view)) {
        activeItem = {
          id: 'new',
          title: i18n.t('anr.new_folder'),
        };
      } else {
        activeItem = _.find(flattenedFolders, { id, is_root: false });
      }
      if (!_.isEmpty(activeItem)) {
        activeItem.view = view;
      }
      return {
        ...state,
        activeItem: activeItem || {},
        filtersParams: initialFiltersParams,
        responseData: {},
        articles: [],
      };
    }
    case types.FETCH_ANR_FOLDER_NEWS: {
      return {
        ...state,
        resultsLoading: true,
        openArticleOption: action.openArticleOption,
      };
    }
    case types.FETCH_ANR_FOLDER_NEWS_SUCCESS: {
      const folder = _.get(action, 'results.folder', {});
      const articlesList = _.get(folder, 'articles', []);
      const userId = state.user.id;
      const articles = _.map(articlesList, (article) => {
        const anrUrl = `${URL}/api/article?article_id=${article.id}`;
        const shareUrl = `${anrUrl}&uid=${userId}&sh=1`;
        return {
          ...article,
          titre: article.title,
          display_date: article.date_collected,
          source: article.is_mail ? (
            article.author
          ) : (
            article.site_host
          ),
          is_quote: false,
          opened: false,
          iframeUrl: getArticleIframeUrl(article),
          anrUrl,
          shareUrl,
          shareUrlFacebook: `http://www.facebook.com/sharer.php?u=${encodeURIComponent(shareUrl)}`,
          shareUrlLinkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`,
          shareUrlTwitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(article.title)}&url=${encodeURIComponent(shareUrl)}`,
          shareUrlWhatsapp: `https://wa.me/?text=${encodeURIComponent(article.title)}%20${encodeURIComponent(shareUrl)}`,
          folders: [{
            id: folder.id,
            title: folder.title,
          }],
        };
      });
      return {
        ...state,
        responseData: action.results.response,
        articles,
        resultsLoading: false,
      };
    }
    case types.FETCH_ANR_FOLDER_NEWS_ERROR: {
      return {
        ...state,
        responseData: action.response,
        articles: [],
        resultsLoading: false,
      };
    }
    case types.FETCH_ANR_FOLDER_QUOTES: {
      return {
        ...state,
        resultsLoading: true,
        openArticleOption: action.openArticleOption,
      };
    }
    case types.FETCH_ANR_FOLDER_QUOTES_SUCCESS: {
      const folder = _.get(action, 'results.folder', {});
      const quotesList = _.get(folder, 'quotes_list', []);
      const userId = state.user.id;
      const quotes = _.map(quotesList, (quote) => {
        const iframeUrl = getArticleIframeUrl(quote);
        let { ext_opening } = quote;
        if (!_.isEmpty(iframeUrl) && (
          iframeUrl.startsWith('https://www.youtube.com/') || iframeUrl.startsWith('https://www.dailymotion.com/')
        )) {
          ext_opening = false;
        }
        const anrUrl = `${URL}/api/article?article_id=${quote.id}&fqu=1`;
        const shareUrl = `${anrUrl}&uid=${userId}&sh=1`;
        return {
          ...quote,
          display_date: quote.date,
          short_description: _.truncate(quote.description, {
            length: 250,
            separator: /,? +/,
          }),
          source: quote.site_host,
          titre: quote.text,
          title: quote.text,
          is_quote: true,
          opened: false,
          ext_opening,
          iframeUrl,
          anrUrl,
          shareUrl,
          shareUrlFacebook: `http://www.facebook.com/sharer.php?u=${encodeURIComponent(shareUrl)}`,
          shareUrlLinkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`,
          shareUrlTwitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(quote.text)}&url=${encodeURIComponent(shareUrl)}`,
          shareUrlWhatsapp: `https://wa.me/?text=${encodeURIComponent(quote.text)}%20${encodeURIComponent(shareUrl)}`,
          folders: [{
            id: folder.id,
            title: folder.title,
          }],
        };
      });
      return {
        ...state,
        responseData: action.results.response,
        articles: quotes,
        resultsLoading: false,
      };
    }
    case types.FETCH_ANR_FOLDER_QUOTES_ERROR: {
      return {
        ...state,
        responseData: action.response,
        articles: [],
        resultsLoading: false,
      };
    }
    case types.FETCH_ANR_GLIMPSE: {
      return {
        ...state,
        resultsLoading: true,
        openArticleOption: action.openArticleOption,
      };
    }
    case types.FETCH_ANR_GLIMPSE_SUCCESS: {
      const articlesList = _.get(action, 'results.articles', []);
      const userId = state.user.id;
      const articles = _.map(articlesList, (article) => {
        const anrUrl = `${URL}/api/article?article_id=${article.id}`;
        const shareUrl = `${anrUrl}&uid=${userId}&sh=1`;
        return {
          ...article,
          titre: article.title,
          display_date: article.date_collected,
          source: article.is_mail ? (
            article.author
          ) : (
            article.site_host
          ),
          is_quote: false,
          opened: false,
          iframeUrl: getArticleIframeUrl(article),
          anrUrl,
          shareUrl,
          shareUrlFacebook: `http://www.facebook.com/sharer.php?u=${encodeURIComponent(shareUrl)}`,
          shareUrlLinkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`,
          shareUrlTwitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(article.title)}&url=${encodeURIComponent(shareUrl)}`,
          shareUrlWhatsapp: `https://wa.me/?text=${encodeURIComponent(article.title)}%20${encodeURIComponent(shareUrl)}`,
        };
      });
      return {
        ...state,
        responseData: action.results.response,
        articles,
        resultsLoading: false,
      };
    }
    case types.FETCH_ANR_GLIMPSE_ERROR: {
      return {
        ...state,
        responseData: action.response,
        articles: [],
        resultsLoading: false,
      };
    }
    case types.SET_FILTERS_PARAM: {
      return {
        ...state,
        filtersParams: {
          ...state.filtersParams,
          ...action.param,
        },
      };
    }
    case types.UNSET_FILTERS_PARAMS: {
      return {
        ...state,
        filtersParams: initialFiltersParams,
      };
    }
    case types.SET_EDIT_FOLDER: {
      return {
        ...state,
        editFolder: {},
      };
    }
    case types.SET_EDIT_NEW_FOLDER: {
      return {
        ...state,
        editFolder: action.folder,
      };
    }
    case types.INCREMENT_OR_DECREMENT_NB_FOLDERS: {
      return {
        ...state,
        user: {
          ...state.user,
          nb_folders: state.user.nb_folders + action.nb,
        },
      };
    }
    case types.FETCH_ANR_FOLDER: {
      return {
        ...state,
        editFolderLoading: true,
      };
    }
    case types.FETCH_ANR_FOLDER_SUCCESS: {
      return {
        ...state,
        editFolder: action.results.folder,
        editFolderLoading: false,
      };
    }
    case types.FETCH_ANR_FOLDER_ERROR: {
      return {
        ...state,
        responseData: action.response,
        editFolder: {},
        editFolderLoading: false,
      };
    }
    case types.SAVE_ANR_FOLDER: {
      return {
        ...state,
        saveFolderLoading: true,
      };
    }
    case types.SAVE_ANR_FOLDER_SUCCESS: {
      return {
        ...state,
        editFolder: {},
        saveFolderLoading: false,
        resultsLoading: true,
      };
    }
    case types.SAVE_ANR_FOLDER_ERROR: {
      return {
        ...state,
        responseData: action.response,
        saveFolderLoading: false,
      };
    }
    case types.OPEN_ARTICLE: {
      return {
        ...state,
        articles: state.articles.map(
          (article) => ({
            ...article,
            opened: article.id === action.itemId,
          }),
        ),
      };
    }
    case types.CLOSE_ARTICLE: {
      return {
        ...state,
        articles: state.articles.map(
          (article) => ({
            ...article,
            opened: false,
          }),
        ),
      };
    }
    case types.CLEANUP_ANR_SOURCES: {
      return {
        ...state,
        sources: null,
        folders: null,
      };
    }
    case types.FETCH_ANR_SOURCES: {
      return {
        ...state,
        sourcesLoading: true,
      };
    }
    case types.FETCH_ANR_SOURCES_SUCCESS: {
      return {
        ...state,
        responseData: action.results.response,
        sources: action.results.sources,
        sourcesLoading: false,
      };
    }
    case types.FETCH_ANR_SOURCES_ERROR: {
      return {
        ...state,
        responseData: action.response,
        sources: [],
        sourcesLoading: false,
      };
    }
    case types.OPEN_DEMAND_NEW_SOURCE_DIALOG: {
      return {
        ...state,
        dialogDemandSource: {
          open: true,
        },
      };
    }
    case types.CLOSE_DEMAND_NEW_SOURCE_DIALOG: {
      return {
        ...state,
        dialogDemandSource: {
          open: false,
        },
      };
    }
    case types.DEMAND_NEW_SOURCE: {
      return {
        ...state,
        dialogDemandSource: {
          ...state.dialogDemandSource,
          responseData: {},
          loading: true,
        },
      };
    }
    case types.DEMAND_NEW_SOURCE_SUCCESS: {
      return {
        ...state,
        dialogDemandSource: {
          ...state.dialogDemandSource,
          responseData: action.results.response,
          loading: false,
        },
      };
    }
    case types.DEMAND_NEW_SOURCE_ERROR: {
      return {
        ...state,
        dialogDemandSource: {
          ...state.dialogDemandSource,
          responseData: action.response,
          loading: false,
        },
      };
    }
    case types.CLEANUP_ANR_BANNED_SITES: {
      return {
        ...state,
        bannedSites: null,
      };
    }
    case types.FETCH_ANR_BANNED_SITES: {
      return {
        ...state,
        bannedSitesLoading: true,
      };
    }
    case types.FETCH_ANR_BANNED_SITES_SUCCESS: {
      return {
        ...state,
        responseData: action.results.response,
        bannedSites: action.results.sites,
        bannedSitesLoading: false,
      };
    }
    case types.UNBAN_SITE_SUCCESS: {
      const alteredSites = _.filter(
        state.bannedSites,
        (bannedSite) => bannedSite.id !== action.results.site_id,
      );
      return {
        ...state,
        bannedSites: alteredSites,
      };
    }
    case types.FETCH_ANR_BANNED_SITES_ERROR: {
      return {
        ...state,
        responseData: action.response,
        bannedSites: [],
        bannedSitesLoading: false,
      };
    }
    case types.COLLECT: {
      return {
        ...state,
        collectLoading: true,
      };
    }
    case types.COLLECT_ERROR: {
      return {
        ...state,
        collectLoading: false,
      };
    }
    case types.VALIDATE_SUCCESS: {
      const {
        list,
        list_more,
      } = action.results;
      const alteredArticles = _.map(
        state.articles,
        (article) => (
          _.includes(list, article.id) ? (
            {
              ...article,
              validation: _.find(list_more, { article_id: article.id }),
            }
          ) : article
        ),
      );
      return {
        ...state,
        articles: alteredArticles,
        collectLoading: false,
      };
    }
    case types.SEND_SUCCESS: {
      const alteredArticles = _.map(
        state.articles,
        (article) => (
          _.includes(action.articlesIds, article.id) ? (
            {
              ...article,
              sendings: _.concat(article.sendings, { emails: action.emails }),
            }
          ) : article
        ),
      );
      return {
        ...state,
        articles: alteredArticles,
      };
    }
    case types.OPEN_BAN_SITE_DIALOG: {
      return {
        ...state,
        dialogBanSite: {
          ...state.dialogBanSite,
          open: true,
          article: action.article,
        },
      };
    }
    case types.CLOSE_BAN_SITE_DIALOG: {
      return {
        ...state,
        dialogBanSite: {
          ...state.dialogBanSite,
          open: false,
          article: {},
        },
      };
    }
    case types.BAN_SITE: {
      return {
        ...state,
        dialogBanSite: {
          ...state.dialogBanSite,
          loading: true,
        },
      };
    }
    case types.BAN_SITE_SUCCESS: {
      return {
        ...state,
        dialogBanSite: {
          ...state.dialogBanSite,
          responseData: action.results.response,
          loading: false,
        },
      };
    }
    case types.BAN_SITE_ERROR: {
      return {
        ...state,
        dialogBanSite: {
          ...state.dialogBanSite,
          responseData: action.response,
          loading: false,
        },
      };
    }
    case types.OPEN_SHARE_DIALOG: {
      return {
        ...state,
        dialogShare: {
          ...state.dialogShare,
          open: true,
          article: action.article,
        },
      };
    }
    case types.CLOSE_SHARE_DIALOG: {
      return {
        ...state,
        dialogShare: {
          ...state.dialogShare,
          open: false,
          article: {},
        },
      };
    }
    default:
      return state;
  }
};

export default anrReducer;
