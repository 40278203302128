import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import {
  PostAdd,
} from '@mui/icons-material';
import THEME_CONFIG from 'generic/core/theme';
import { overrideRessource } from 'generic/utils/utils';
import { interleave } from 'generic/utils/arrayUtils';
import { documentPropType } from 'custom/safran/core/qes/documentProptypes';
import { useTranslation } from 'react-i18next';

const { default: useDocumentFields } = overrideRessource('core/hooks/document', true);
const { stateDocumentColor } = THEME_CONFIG.HIGHCHARTS;

const Document = ({
  document,
  displayTitle,
  context,
  handleGetDocumentComplete,
}) => {
  const { t } = useTranslation();
  const {
    snippet,
    text,
    title,
    controle,
    numeroControle,
    themeControle,
    applicabilite,
    files,
    justification,
    piecesAFournir,
    societe,
    status,
  } = useDocumentFields(document);

  let finalTitle = `${numeroControle} - ${title}`;
  if (societe) {
    finalTitle = `${societe} - ${finalTitle}`;
  }

  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word', pt: 1 }}>
      {displayTitle && (
        <Box>
          <Typography
            variant="h6"
            component="span"
            fontSize="1.2rem"
            display="block"
          >
            <Box
              onClick={handleGetDocumentComplete}
              sx={{ cursor: 'pointer' }}
              color="text.dark"
            >
              {finalTitle}
            </Box>
          </Typography>
        </Box>
      )}

      <Chip
        size="extraSmall"
        variant="filledSecondary"
        label={t(`ged.document.status_single.${status}`)}
        sx={{ my: 0.5, backgroundColor: stateDocumentColor[status] }}
      />

      <Box mb={0.5} display="block">
        {
          interleave(
            [
              societe,
              themeControle,
              numeroControle,
              controle,
            ]
              .filter((e) => e)
              .map((e) => (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.neutral"
                  title={e}
                >
                  {e}
                </Typography>
              )),
            <Typography
              component="span"
              variant="body2"
              color="text.neutral"
              px={1}
            >
              |
            </Typography>,
          // eslint-disable-next-line react/no-array-index-key
          ).map((item, key) => React.cloneElement(item, { key: `interleave_${key}` }))
        }

        {applicabilite === 'false' && (
          <Fragment>
            <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
            <Typography
              component="span"
              variant="body2"
              color="error"
            >
              {t('safran.not_applicable')}
            </Typography>
          </Fragment>
        )}
      </Box>

      <Box display="flex" alignItems="flex-start">
        <Box flexGrow="1">
          <Box lineHeight="1.3rem">
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              {piecesAFournir !== 'N/A' && (
                <Box color="text.neutral" alignItems="center" display="flex">
                  <PostAdd fontSize="1" />
                  <Typography
                    variant="body2"
                    fontSize="1"
                    title={piecesAFournir}
                  >
                    {piecesAFournir}
                  </Typography>
                </Box>
              )}
            </Box>
            {(context === 'resultComplete')
              ? (
                <Box mt={1} mb={2} whiteSpace="pre-wrap" sx={{ clear: 'both' }}>
                  {applicabilite === 'false' ? justification : text}
                </Box>
              )
              : (
                <div>{applicabilite === 'false' ? justification : snippet}</div>
              )}
          </Box>
        </Box>
      </Box>

      {_.map(
        files,
        (file) => (
          <Box key={file.url} mb={0.5}>
            <Link
              href={file.url}
              rel="noopener"
              target="_blank"
              underline="none"
              color="secondary"
            >
              <Typography
                variant="body2"
              >
                {file.name}
              </Typography>
            </Link>
            {context === 'resultComplete' && (
              <Typography
                color="text.neutral"
                fontStyle="italic"
                fontSize="0.8rem"
              >
                {file.text}
              </Typography>
            )}
          </Box>
        ),
      )}
    </Box>
  );
};

Document.propTypes = {
  context: PropTypes.oneOf(['cart', 'newsletter', 'result', 'resultComplete']).isRequired,
  displayTitle: PropTypes.bool,
  document: documentPropType,
  handleGetDocumentComplete: PropTypes.func,
};

Document.defaultProps = {
  document: {},
  displayTitle: true,
  handleGetDocumentComplete: null,
};

export default Document;
