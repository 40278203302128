import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserForm from 'generic/components/forms/UserForm';
import { closeDialogEditUser, saveUser } from 'generic/core/users/actions';

const UserFormContainer = () => {
  const editUser = useSelector((state) => state.users.editUser);
  const saveUserLoading = useSelector((state) => state.users.saveUserLoading);
  const editDialogOpened = useSelector((state) => state.users.editDialogOpened);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDialogEditUser());
  };

  const handleSubmit = (values) => {
    dispatch(saveUser(values));
  };

  if (!editDialogOpened) {
    return '';
  }

  return (
    <UserForm
      user={editUser}
      loading={saveUserLoading}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};

export default UserFormContainer;
