import PropTypes from 'prop-types';

const qesdocumentPropType = PropTypes.shape({
  idext: PropTypes.string.isRequired,
  BASE_ID: PropTypes.string.isRequired,
  URL: PropTypes.string,
  QUOTE_URL: PropTypes.string,
  TITLE: PropTypes.string,
  PUB_DATE: PropTypes.string,
  SOURCE: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
});

const documentPropType = PropTypes.shape({
  qesdocument: qesdocumentPropType,
});

export {
  documentPropType,
  qesdocumentPropType,
};
