import { post, get } from 'generic/api/apiGenerator';

const login = (values) => post({ path: '/authenticate', bodyItems: values, withToken: false })
  .then((xhrResponse) => xhrResponse.json())
  .then((response) => response);

const loginTest = () => post({ path: '/authenticate' }).then((response) => response);

const logout = () => get({ path: '/logout' });

export {
  login,
  logout,
  loginTest,
};
