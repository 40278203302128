import React from 'react';
import { useSelector } from 'react-redux';
import { overrideRessource } from 'generic/utils/utils';

const Header = overrideRessource('components/ui/Header');

const HeaderContainer = () => {
  const logged = useSelector((state) => state.auth.logged);
  const user = useSelector((state) => state.auth.user);

  if (!logged) {
    return '';
  }
  return (
    <Header
      user={user}
    />
  );
};

export default React.memo(HeaderContainer);
