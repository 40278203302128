import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormAnRFolder from 'generic/components/forms/FormAnRFolder';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { expertToSimpleQuery } from 'generic/utils/anrUtils';
import { saveAnRFolder } from 'generic/core/anr/actions';
import { testSettings } from 'generic/api/anr';
import { snackActions } from 'generic/utils/snackbar';
import { CONSTANTS } from 'generic/core/constants';

const { LANG_MAPPING } = CONSTANTS;

const SettingsContainer = () => {
  const user = useSelector((state) => state.anr.user);
  const editFolderLoading = useSelector((state) => state.anr.editFolderLoading);
  const saveFolderLoading = useSelector((state) => state.anr.saveFolderLoading);
  const folder = useSelector((state) => state.anr.editFolder);
  const lang = useSelector((state) => state.auth.user.langue);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    view,
  } = useParams();

  if (_.isEmpty(folder) && view !== 'new') {
    if (editFolderLoading) {
      return <GlobalLoading />;
    }
    return null;
  }

  const processQueryValue = (values) => {
    const alteredValues = { ..._.omit(values, ['queryMust', 'queryMay', 'queryMustNot']) };
    if (!values.expert_mode) {
      const queries = {
        queryMust: values.queryMust,
        queryMay: values.queryMay,
        queryMustNot: values.queryMustNot,
      };
      alteredValues.query = expertToSimpleQuery(queries);
    }
    return alteredValues;
  };

  const handleSubmit = (values) => {
    const alteredValues = processQueryValue(values);
    dispatch(saveAnRFolder(alteredValues));
  };

  const handleTestSettings = (values) => {
    const alteredValues = processQueryValue(values);

    try {
      testSettings({ bodyItems: { ...alteredValues } }).then((results) => {
        if (_.isEmpty(_.get(results.response.message, 'error'))) {
          if (results.nb_articles === 0) {
            snackActions.warning(t('anr.no_articles_from_test'));
          } else {
            snackActions.info(`${results.nb_articles} ${t('anr.articles_from_test')}`);
          }
        } else {
          snackActions.error(`${results.response.message.error} : ${results.response.message.info}`);
        }
      });
    } catch (response) {
      snackActions.error(t('form.errors.error_from_test'));
    }
  };

  let alteredFolder = { ...folder };
  // Si on duplique un dossier, on va virer l'id et modifier le titre
  if (view === 'copy') {
    alteredFolder = _.omitBy(alteredFolder, (value, key) => (
      key === 'id'
    ));
    alteredFolder.title = `${alteredFolder.title} (${t('anr.copy')})`;
  } else if (view === 'new') {
    alteredFolder.langs = [LANG_MAPPING[lang]];
  }
  return (
    <FormAnRFolder
      folder={alteredFolder}
      onSubmit={handleSubmit}
      onTestSettings={handleTestSettings}
      loading={saveFolderLoading}
      user={user}
      view={view}
    />
  );
};

export default SettingsContainer;
