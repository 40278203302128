import { useSelector } from 'react-redux';

import _ from 'lodash';
import { format } from 'generic/utils/dateUtils';
import { t } from 'i18next';
import {
  generateLinkToResource, getValueByBaseFieldsConfig, getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';

const useDocumentFields = (document) => {
  const { qesdocument, base: docBaseId } = document;
  const userIsContributor = useSelector((state) => state.auth.user.isContributor);
  const bases = useSelector((state) => state.config.bases);
  const documentBase = _.find(bases, { base: docBaseId });

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');
  const titleXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTitre');
  const pdf = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieur.
  const text = getValueOrFirstValueFromArray(qesdocument?.Reponse);
  const datePubXml = getValueOrFirstValueFromArray(qesdocument?.PUB_DATE);
  const thumbXml = getValueOrFirstValueFromArray(qesdocument?.IMAGE || qesdocument?.THUMB);
  const linkout = getValueOrFirstValueFromArray(qesdocument?.URL || qesdocument?.QUOTE_URL);
  const source = getValueOrFirstValueFromArray(qesdocument?.SOURCE);

  let files = [];
  if (qesdocument?.Fichier_joint) {
    const filePaths = _.isArray(qesdocument?.Fichier_joint) ? qesdocument?.Fichier_joint : [qesdocument?.Fichier_joint];
    const fileTexts = _.isArray(qesdocument?.Fichier_joint_TEXT) ? (
      qesdocument?.Fichier_joint_TEXT
    ) : (
      [qesdocument?.Fichier_joint_TEXT]
    );
    files = _.map(
      filePaths,
      (filePath, index) => ({
        name: filePath.substring(filePath.lastIndexOf('/') + 1),
        url: generateLinkToResource(docBaseId, filePath),
        text: _.truncate(fileTexts[index], {
          length: 250,
          separator: /,? +/,
        }),
      }),
    );
  }

  // dernières retouches avant le retour du résulat
  const thumb = generateLinkToResource(docBaseId, thumbXml);
  const title = titleXml || `[${t('document.title_undefined')}]`;
  const titleTruncate = _.truncate(title, {
    length: 50,
    separator: /,? +/,
  });
  const status = +statusXml;
  const date = format(datePubXml);
  const snippet = _.truncate(text, {
    length: 250,
    separator: /,? +/,
  });

  // CHAMPS CUSTOM SAFRAN
  const societe = !userIsContributor ? getValueOrFirstValueFromArray(qesdocument?.Societe) : null;
  const controle = getValueOrFirstValueFromArray(qesdocument?.Controle);
  const numeroControle = getValueOrFirstValueFromArray(qesdocument?.Numero_controle);
  const piecesAFournir = getValueOrFirstValueFromArray(qesdocument?.Pieces_a_fournir);
  const question = getValueOrFirstValueFromArray(qesdocument?.Question);
  const themeControle = getValueOrFirstValueFromArray(qesdocument?.Theme_controle);
  const applicabilite = getValueOrFirstValueFromArray(qesdocument?.Applicabilite);
  const justification = getValueOrFirstValueFromArray(qesdocument?.Justification);

  return {
    date,
    id,
    pdf,
    thumb,
    snippet,
    status,
    text,
    title,
    titleTruncate,
    linkout,
    source,
    docBaseId,
    // custom SAFRAN
    societe,
    controle,
    numeroControle,
    piecesAFournir,
    question,
    themeControle,
    applicabilite,
    files,
    justification,
  };
};

export default useDocumentFields;
