import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import InteractiveButton from 'generic/components/ui/InteractiveButton';

const textFieldStyle = (theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontFamily: 'Rajdhani, sans-serif',
    fontWeight: 'bold',
    width: '100%',
  },
  input: {
    padding: '.25em 0 .25em .75em',
  },
});

const CustomTextField = withStyles(textFieldStyle)(({ classes, ...rest }) => (
  <TextField
    className={classes.root}
    InputProps={{
      disableUnderline: true,
      inputProps: {
        className: classes.input,
      },
    }}
    variant="standard"
    {...rest}
  />
));

const CustomButton = withStyles(() => ({
  root: {
    minWidth: '180px',
    fontFamily: 'Rajdhani, sans-serif',
    '& > *:first-child': {
      flex: '1',
    },
  },
}))(InteractiveButton);

const useStyles = makeStyles({
  root: {
    fontFamily: 'Rajdhani, sans-serif',
    width: '100%',
    '& > *:first-child': {
      minHeight: '50vh',
    },
  },
  typography: {
    fontFamily: 'Rajdhani, sans-serif',
  },
});

const FormLogin = ({ onSubmit, loading, loginError }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik
      onSubmit={(values, actions) => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
      initialValues={{
        logon: '',
        password: '',
      }}
    >
      {() => (
        <Form
          className={classes.root}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h3">
                {t('login.welcome')}
              </Typography>

              <Typography
                variant="subtitle2"
                align="center"
              >
                {t('login.log_in')}
              </Typography>
            </Grid>

            <Grid
              item
              container
              justifyContent="center"
              direction="column"
            >
              <Box mb={1}>
                <Field
                  autoComplete="username"
                  autoFocus
                  component={CustomTextField}
                  name="logon"
                  placeholder={t('login.login')}
                  type="text"
                  required
                />
              </Box>

              <Box mb={1}>
                <Field
                  component={CustomTextField}
                  name="password"
                  placeholder={t('login.password')}
                  type="password"
                  autoComplete="current-password"
                  required
                />
              </Box>

              {
                loginError && (
                  <Typography
                    color="error"
                    variant="subtitle2"
                  >
                    {t('login.fail_auth')}
                  </Typography>
                )
              }
            </Grid>

            <Grid
              alignItems="center"
              container
              direction="column"
              item
              justifyContent="center"
            >
              <CustomButton
                color="secondary"
                progressColor="#000000"
                disabled={loading}
                loading={loading}
                type="submit"
              >
                {t('login.to_login')}
              </CustomButton>

            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

FormLogin.propTypes = {
  loading: PropTypes.bool.isRequired,
  loginError: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default React.memo(FormLogin);
