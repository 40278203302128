const computeUserAdditionnalAttrs = (user) => {
  const additionnalAttrs = {};
  if ([1020, 1022].includes(user.mode_vue)) {
    additionnalAttrs.forbiddenRoutes = ['/global', '/report'];
    additionnalAttrs.isContributor = true;
  } else {
    additionnalAttrs.companies = ['FILIALE1', 'FILIALE2'];
    additionnalAttrs.hasReport = true;
  }

  return additionnalAttrs;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  computeUserAdditionnalAttrs,
};
