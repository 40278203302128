import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'generic/utils/dateUtils';
import _ from 'lodash';

import {
  AccountCircle,
  Visibility,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Box,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';

import TooltipButton from 'generic/components/ui/TooltipButton';
import ExpandableTagBox from 'generic/components/ui/ExpandableTagBox';
import NewsletterPreviewAndSendContainer from 'generic/containers/NewsletterPreviewAndSendContainer';
import { newsletterHistoric } from 'generic/core/qes/proptypes';
import { useTheme } from '@mui/styles';

const createRecipientsForNl = (value) => (
  <ExpandableTagBox
    document={{ recipients: value.split(';') }}
    tags={[{ fieldId: 0, fieldName: 'recipients', icon: AccountCircle }]}
    disableRefine
  />
);

const createActionsForNL = ({ t, handleOpen }) => (deliveryId) => (
  <TooltipButton
    onClick={() => handleOpen(deliveryId)}
    tag="fab"
    color="primary"
    size="extraSmall"
    title={t('newsletters.show_sent')}
  >
    <Visibility />
  </TooltipButton>
);

const NewsletterHisto = ({ historic }) => {
  const { t } = useTranslation();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [currentDelivery, setCurrentDelivery] = useState(null);
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let responsiveTableType = 'standard';
  if (smallerThanLarge) {
    responsiveTableType = 'verticalAlways';
    if (smallerThanSmall) {
      responsiveTableType = 'simple';
    }
  }

  const handleOpen = (delivery) => {
    setIsPreviewOpen(true);
    setCurrentDelivery(_.find(historic, { newsletter_histo: delivery }));
  };

  const handleClose = () => {
    setIsPreviewOpen(false);
    setCurrentDelivery(null);
  };

  const docsWipedRenderer = (val) => (val === true ? '✓' : false);

  const columns = [
    {
      name: 'date_time_envoi',
      label: t('newsletters.sending_date'),
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        customBodyRender: (value) => format(new Date(value), 'PPPp'),
      },
    },
    {
      name: 'destinataires',
      label: t('newsletters.recipients_other'),
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: createRecipientsForNl,
      },
    },
    {
      label: t('newsletters.wiped'),
      name: 'avec_vidange',
      options: {
        sort: true,
        filter: true,
        filterType: 'checkbox',
        filterOptions: {
          names: ['✓', '✗'],
          logic(docsWiped, currentFilters) {
            const show = (
              (
                currentFilters.indexOf('✓') >= 0 && (docsWiped === true || docsWiped === '✓')
              ) || (
                currentFilters.indexOf('✗') >= 0 && (docsWiped === false || docsWiped === '✗')
              )
            );
            return !show;
          },
        },
        customBodyRender: docsWipedRenderer,
      },
    },
    {
      name: 'newsletter_histo',
      label: t('newsletters.actions'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: createActionsForNL({ t, handleOpen }),
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    sortOrder: {
      name: 'date_time_envoi',
      direction: 'desc',
    },
    responsive: responsiveTableType,
    setTableProps: () => ({
      size: 'small',
    }),
  };

  return (
    <Box
      flexGrow="1"
      m={smallerThanLarge ? 0 : 2}
      id="datatable"
    >
      <MUIDataTable
        title={t('newsletters.historic_list')}
        data={historic}
        columns={columns}
        options={options}
      />

      {currentDelivery && (
        <Dialog
          scroll="paper"
          fullWidth
          maxWidth="lg"
          open={isPreviewOpen}
          PaperProps={{
            sx: {
              height: '100%',
              margin: smallerThanLarge ? 1 : 4,
              width: smallerThanLarge ? '100%' : 'calc(100% - 64px)',
            },
          }}
        >
          <DialogTitle>
            { format(new Date(currentDelivery.date_time_envoi), 'PPPp')}
          </DialogTitle>

          <DialogContent
            dividers
            sx={{ display: 'flex', flexDirection: 'column', padding: '0' }}
          >
            <NewsletterPreviewAndSendContainer variant="newsletter-histo" id={currentDelivery.newsletter_histo} />
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleClose}
            >
              {t('dialog.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

NewsletterHisto.propTypes = { historic: newsletterHistoric.isRequired };

export default NewsletterHisto;
