import { get, post } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const banSite = ({ ...rest }) => post({ path: '/anrapi/articles/ban_site', ...rest }).then(handleComplete);

const createFolder = ({ ...rest }) => post({ path: '/anrapi/folders/create', ...rest }).then(handleComplete);

const deleteArticles = ({ ...rest }) => post({ path: '/anrapi/articles/delete', ...rest }).then(handleComplete);

const deleteFolder = ({ ...rest }) => post({ path: '/anrapi/folders/delete', ...rest }).then(handleComplete);

const demandNewSource = ({ ...rest }) => post({ path: '/anrapi/sources/ask_for_source', ...rest }).then(handleComplete);

const send = ({ ...rest }) => post({ path: '/anrapi/articles/email_links', ...rest }).then(handleComplete);

const getBannedSites = ({ ...rest }) => get({ path: '/anrapi/sources/get_banned_sites', ...rest }).then(handleComplete);

const getFolder = ({ ...rest }) => get({ path: '/anrapi/folders/get_folder', ...rest }).then(handleComplete);

const getFolders = ({ ...rest }) => get(
  { path: '/anrapi/users/get_folders_tree_nested', ...rest },
).then(handleComplete);

const getGlimpse = ({ ...rest }) => get({ path: '/anrapi/users/get_glimpse', ...rest }).then(handleComplete);

const getNews = ({ ...rest }) => get({ path: '/anrapi/folders/get_news', ...rest }).then(handleComplete);

const getQuotes = ({ ...rest }) => get({ path: '/anrapi/folders/get_quotes', ...rest }).then(handleComplete);

const getSources = ({ ...rest }) => get({ path: '/anrapi/sources/get_feeds_and_sites', ...rest }).then(handleComplete);

const getSnippets = ({ ...rest }) => get({ path: '/anrapi/articles/get_snippets', ...rest }).then(handleComplete);

const getUser = ({ ...rest }) => get({ path: '/anrapi/users/get_user', ...rest }).then(handleComplete);

const setFavoriteStatus = ({ ...rest }) => post(
  { path: '/anrapi/folders/set_favorite_status', ...rest },
).then(handleComplete);

const testSettings = ({ ...rest }) => post({ path: '/anrapi/folders/test_settings', ...rest }).then(handleComplete);

const unbanSite = ({ ...rest }) => post({ path: '/anrapi/articles/unban_site', ...rest }).then(handleComplete);

const updateFolder = ({ ...rest }) => post({ path: '/anrapi/folders/update', ...rest }).then(handleComplete);

const validate = ({ ...rest }) => post({ path: '/anrapi/articles/validate', ...rest }).then(handleComplete);

export {
  banSite,
  createFolder,
  deleteArticles,
  deleteFolder,
  demandNewSource,
  getBannedSites,
  getFolder,
  getFolders,
  getGlimpse,
  getNews,
  getQuotes,
  getSources,
  getSnippets,
  getUser,
  setFavoriteStatus,
  send,
  testSettings,
  unbanSite,
  updateFolder,
  validate,
};
