import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Box } from '@mui/material';
import {
  cleanupUsers,
  fetchUsers,
  openCloseUsers,
  openDialogEditUser,
} from 'generic/core/users/actions';
import Users from 'generic/components/pages/Users';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import CenteredMessage from 'generic/components/ui/CenteredMessage';
import QES_CONSTANTS from 'generic/core/qes/constants';
import UserFormContainer from 'generic/containers/UserFormContainer';

const { OPENED_USER_REQUEST } = QES_CONSTANTS;

const UsersContainer = () => {
  const loading = useSelector((state) => state.users.loading);
  const users = useSelector((state) => state.users.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [statusFilter, setStatusFilter] = useState(OPENED_USER_REQUEST);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleClickOpenClose = (userId, open) => {
    setSelectedRows([]);
    dispatch(openCloseUsers([userId], open, statusFilter));
  };

  const handleClickOpenCloseSelected = (rowsSelected, data, open) => {
    const ids = [];
    for (let i = 0; i < rowsSelected.data.length; i++) {
      if (data[rowsSelected.data[i].dataIndex]) {
        ids.push(data[rowsSelected.data[i].dataIndex][0]);
      }
    }
    setSelectedRows([]);
    dispatch(openCloseUsers(ids, open, statusFilter));
  };

  const handleClickEdit = (user) => {
    dispatch(openDialogEditUser(user));
  };

  useEffect(() => {
    dispatch(fetchUsers({ etat: statusFilter }));

    // Cleanup des utilisateurs lorsque le composant est "unmount" (donc quand on
    // change de page)
    return () => dispatch(cleanupUsers());
  }, [statusFilter, dispatch]);

  if (_.isEmpty(users)) {
    if (loading) {
      return <GlobalLoading />;
    }
    return (
      <Box width="100%">
        <CenteredMessage>
          {t('users.no_users')}
        </CenteredMessage>
      </Box>
    );
  }

  return (
    <Fragment>
      <Users
        users={users}
        loading={loading}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        handleClickEdit={handleClickEdit}
        handleClickOpenClose={handleClickOpenClose}
        handleClickOpenCloseSelected={handleClickOpenCloseSelected}
      />
      <UserFormContainer />
    </Fragment>
  );
};

export default UsersContainer;
