import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  Folder,
  Dns,
  FolderSpecial,
  ChevronRight,
  ExpandMore,
  Edit,
  Star,
  StarBorder,
  CreateNewFolder,
  Clear,
  Settings,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'generic/components/ui/DropdownMenu';
import { folderPropType } from 'generic/core/anr/proptypes';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    paddingRight: '0',
  },
  noPadding: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  title: {
    lineHeight: '1.25em',
  },
}));

const FolderItem = ({
  activeItemId,
  canCreateFolders,
  currentItemRef,
  handleEditNewFolder,
  handleOpenDeleteConfirmDialog,
  handleToggleFavoriteStatus,
  item,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isChildrenVisible, setIsChildrenVisible] = useState(true);
  let linkCondition;
  if (!item.is_root) {
    linkCondition = item.has_news || (!item.has_news && !item.has_quotes) ? 'news' : 'quotes';
  }
  const canEditItem = canCreateFolders && (item.is_root || item.is_his);

  // Définition du "DropdownMenu" associé à chaque élément de l'arbre
  const ItemDropdown = React.memo(() => (
    <DropdownMenu
      btnChildren={<Settings fontSize="small" />}
      btnProps={{
        sx: { padding: '2px' },
      }}
    >
      <Tooltip
        title={t(item.is_favorite ? 'anr.remove_favorite' : 'anr.add_favorite')}
        placement="right"
      >
        <MenuItem
          onClick={() => handleToggleFavoriteStatus(item.id, !item.is_favorite)}
        >
          <ListItemIcon>
            {item.is_favorite ? (
              <Star
                fontSize="small"
                className="yellowStar"
              />
            ) : (
              <StarBorder
                fontSize="small"
              />
            )}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={t('anr.favorite')}
          />
        </MenuItem>
      </Tooltip>
      {canEditItem && (
        item.is_root ? (
          <Tooltip
            title={t('anr.create_in_branch')}
            placement="right"
          >
            <MenuItem
              component={Link}
              to="/anr/folders/0/new"
              onClick={(event) => handleEditNewFolder(
                event,
                { title: `${item.title} / `, add_to_favorites: item.is_favorite },
              )}
            >
              <ListItemIcon>
                <CreateNewFolder
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={t('anr.new')}
              />
            </MenuItem>
          </Tooltip>
        ) : (
          <div>
            <MenuItem
              component={Link}
              to={`/anr/folders/${item.id}/settings`}
            >
              <ListItemIcon>
                <Edit
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={t('form.edit')}
              />
            </MenuItem>
            {item.type !== 'mails' && (
              <MenuItem
                component={Link}
                to={`/anr/folders/${item.id}/copy`}
                onClick={(event) => handleEditNewFolder(event, null)}
              >
                <ListItemIcon>
                  <Dns
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={t('anr.duplicate')}
                />
              </MenuItem>
            )}
            <MenuItem
              onClick={() => handleOpenDeleteConfirmDialog(item)}
            >
              <ListItemIcon>
                <Clear
                  fontSize="small"
                  color="error"
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={t('form.delete')}
              />
            </MenuItem>
          </div>
        )
      )}
    </DropdownMenu>
  ));

  return (
    item.is_root ? (
      <ListItem
        id={item.id}
        className={classes.root}
      >
        <Box
          mb={1}
          mr={2}
          display="flex"
          alignItems="center"
          onClick={() => setIsChildrenVisible(!isChildrenVisible)}
          className="cursorPointer"
        >
          <ListItemIcon>
            {isChildrenVisible ? <ExpandMore /> : <ChevronRight />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={item.short_title}
          />
          <ItemDropdown />
        </Box>
        {item.children && (
          <Collapse in={isChildrenVisible}>
            <List dense className={classes.noPadding} key={item.id}>
              {_.map(item.children, (child) => (
                <FolderItem
                  activeItemId={activeItemId}
                  canCreateFolders={canCreateFolders}
                  currentItemRef={currentItemRef}
                  handleEditNewFolder={handleEditNewFolder}
                  handleOpenDeleteConfirmDialog={handleOpenDeleteConfirmDialog}
                  handleToggleFavoriteStatus={handleToggleFavoriteStatus}
                  item={child}
                  key={child.id}
                />
              ))}
            </List>
          </Collapse>
        )}
      </ListItem>
    ) : (
      <ListItemButton
        id={item.id}
        component={Link}
        to={`/anr/folders/${item.id}/${linkCondition}`}
        selected={item.id === activeItemId}
        ref={item.id === activeItemId ? currentItemRef : _.noop}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <div>
            {item.is_favorite ? (
              <FolderSpecial sx={{ verticalAlign: 'bottom', mr: 0.5, color: 'text.neutral' }} />
            ) : (
              <Folder sx={{ verticalAlign: 'bottom', mr: 0.5, color: 'text.neutral' }} />
            )}
            <span className={classes.title}>{item.short_title}</span>
          </div>
          <ItemDropdown />
        </Box>
      </ListItemButton>
    )
  );
};

FolderItem.propTypes = {
  activeItemId: PropTypes.string,
  canCreateFolders: PropTypes.bool,
  currentItemRef: PropTypes.shape(),
  handleEditNewFolder: PropTypes.func,
  handleOpenDeleteConfirmDialog: PropTypes.func,
  handleToggleFavoriteStatus: PropTypes.func,
  item: folderPropType.isRequired,
};

FolderItem.defaultProps = {
  activeItemId: null,
  canCreateFolders: false,
  currentItemRef: {},
  handleEditNewFolder: null,
  handleOpenDeleteConfirmDialog: null,
  handleToggleFavoriteStatus: null,
};

export default FolderItem;
