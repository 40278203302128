import _ from 'lodash';
import { CUSTOM_PATH } from 'generic/core/constants';

let constants = {
  URL: process.env.REACT_APP_ANR_URL,
  USER_FIELDS: 'langs/roles/always_open_in_new_window/auto_validation/compact_list_display/deduplicate/folders_categories/folders_not_categories/glimpse/quotes/max_profiles/nb_folders/nb_links/partners/username/ban_reasons',
  FOLDER_FIELDS: 'title/categories/in_glimpse/focus/langs/not_categories/partners/query/expert_mode/quotes/quotes_expert_mode/quotes_dm/quotes_tw/quotes_yt/quotes_in/quotes_link_filter/quotes_min_followers/is_favorite/type',
  FOLDERS_FIELDS: 'title/has_archives/has_news/has_quotes/type/query/is_his/user_id',
  NEWS_FIELDS: 'title/snippet/url/ext_opening/site_id/site_host/date/date_collected/description/short_description/text/author/images/validation/folders/attachment_name/sendings/categories_ids/group_count/crc32/lang',
  SOURCES_FIELDS: 'articles_count/host/lang/url/type/categories/partners',
  BANNED_SITES_FIELDS: 'host/name',
  QUOTES_FIELDS: 'url/quote_url/quote_type/quote_id/atfrom/atfromurl/quote_from/followers_count/retweet_count/authors/text/description/validation/site_host/date/sendings/ext_opening/is_followed/archives_keywords',
  FOLDER_SETTINGS_VIEWS: ['settings', 'copy'],
  SETTINGS_VIEWS: ['settings', 'copy', 'new'],
  FOLDER_RESULTS_VIEWS: ['news', 'quotes', 'archives'],
  RESULTS_VIEWS: ['news', 'quotes', 'archives', 'glimpse', 'lastArchives'],
  QUOTES_TYPES_MAPPING: {
    TWITTER: 1,
    FACEBOOK: 2,
    YOUTUBE: 3,
    DAILYMOTION: 4,
    LINKEDIN: 5,
  },

  /* ---------- PARAMETRES ERGONOMIQUES A OVERRIDER ---------- */
  // Option pour modifier l'endroit ou apparaît l'iframe de visualisation d'article
  // de la veille web :
  // 'bottom' > en bas par dessus la page
  // 'right' > à droite dans la page
  ARTICLE_VIEW_ANCHOR: 'right',
};

if (CUSTOM_PATH) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: customConstants } = require(`custom/${CUSTOM_PATH}/core/anr/constants`);
    constants = _.merge(constants, customConstants);
  } catch (err) {
    console.info('Pas de fichier custom de constantes AnR');
  }
}

const ANR_CONSTANTS = constants;
export default ANR_CONSTANTS;
