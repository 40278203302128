import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  FormControlLabel,
  Link,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BlockOutlined, CheckOutlined, Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import QES_CONSTANTS from 'generic/core/qes/constants';
import TooltipButton from 'generic/components/ui/TooltipButton';
import {
  getLogonFromStorage,
  getTokenFromStorage,
} from 'generic/utils/utils';
import { useTheme } from '@mui/material/styles';
import { utilisateurPropType } from 'generic/core/qes/proptypes';

const { OPENED_USER_REQUEST, CLOSED_USER_REQUEST, API_ENTRY_POINT } = QES_CONSTANTS;
const closedState = _.split(CLOSED_USER_REQUEST, ',');

const customToolbarSelect = (rowsSelected, data, statusFilter, t, handleClickOpenCloseSelected) => (
  statusFilter === CLOSED_USER_REQUEST ? (
    <Button
      color="primary"
      variant="text"
      sx={{
        marginRight: 2,
      }}
      onClick={() => handleClickOpenCloseSelected(rowsSelected, data, true)}
      startIcon={<CheckOutlined />}
    >
      {t('users.open')}
    </Button>
  ) : (
    <Button
      color="primary"
      variant="text"
      sx={{
        marginRight: 2,
      }}
      onClick={() => handleClickOpenCloseSelected(rowsSelected, data, false)}
      startIcon={<BlockOutlined />}
    >
      {t('users.close')}
    </Button>
  )
);

const nameBodyRendered = (value, tableMeta) => {
  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();

  const { rowData } = tableMeta;

  return (
    <Box display="flex" alignItems="center">
      <Avatar
        alt=""
        src={`${API_ENTRY_POINT}/file?type=0&file=${rowData[1]}&logon=${logon}&key=${key}`}
        sx={{ width: 30, height: 30, marginRight: '10px' }}
      />
      <Link
        component={RouterLink}
        underline="none"
        to={`user/${rowData[0]}`}
      >
        {value}
      </Link>
    </Box>
  );
};

const customBodyRenderActions = (tableMeta, users, t, handleClickOpenClose, handleClickEdit) => {
  const user = _.find(users, { utilisateur: tableMeta.rowData[0] });
  const etat = user.etat_utilisateur;
  return (
    <Box>
      {(closedState.includes(`${etat}`)) ? (
        <TooltipButton
          tag="fab"
          color="primary"
          title={t('users.open')}
          onClick={() => handleClickOpenClose(tableMeta.rowData[0], true)}
          size="extraSmall"
        >
          <CheckOutlined />
        </TooltipButton>
      ) : (
        <TooltipButton
          tag="fab"
          color="primary"
          title={t('users.close')}
          onClick={() => handleClickOpenClose(tableMeta.rowData[0], false)}
          size="extraSmall"
        >
          <BlockOutlined />
        </TooltipButton>
      )}
      <TooltipButton
        tag="fab"
        color="primary"
        title={t('users.edit')}
        onClick={() => handleClickEdit(user)}
        size="extraSmall"
        sx={{ ml: 0.5 }}
      >
        <Edit />
      </TooltipButton>
    </Box>
  );
};

const Users = ({
  users,
  loading,
  setStatusFilter,
  statusFilter,
  setSelectedRows,
  selectedRows,
  handleClickEdit,
  handleClickOpenClose,
  handleClickOpenCloseSelected,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let responsiveTableType = 'standard';
  if (smallerThanLarge) {
    responsiveTableType = 'verticalAlways';
    if (smallerThanSmall) {
      responsiveTableType = 'simple';
    }
  }

  const handleToggleButtonChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'image',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: t('users.user'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: nameBodyRendered,
      },
    },
    {
      name: 'logon',
      label: t('users.logon'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: t('users.email'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'profile',
      label: t('users.profile'),
      options: {
        sort: true,
      },
    },
    {
      name: 'actions',
      label: t('actions.actions'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          customBodyRenderActions(tableMeta, users, t, handleClickOpenClose, handleClickEdit)
        ),
      },
    },
  ];

  const data = users.map(
    (user) => [
      user.utilisateur,
      user.image,
      `${user.prenom} ${user.nom}`,
      user.logon,
      user.email,
      user.modeVueLibelle,
    ],
  );

  const textLabels = t('datatable.textLabels', { returnObjects: true });
  const options = {
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    filterType: 'checkbox',
    fixedHeader: true,
    textLabels: {
      ...textLabels,
      body: {
        ...textLabels.body,
        noMatch: loading ? t('datatable.loading') : textLabels.body.noMatch,
      },
    },
    downloadOptions: { filename: 'users_list.csv' },
    onChangePage: () => {
      const table = document.querySelector('#datatable .MuiTable-root');
      table.scrollIntoView(true);
    },
    sortOrder: {
      name: 'date_of_creation',
      direction: 'desc',
    },
    responsive: responsiveTableType,
    setTableProps: () => ({ size: 'small' }),
    rowsSelected: selectedRows,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setSelectedRows(rowsSelected);
    },
    customToolbarSelect: (rowsSelected) => (
      customToolbarSelect(rowsSelected, data, statusFilter, t, handleClickOpenCloseSelected)
    ),
  };
  return (
    <Box
      width="100%"
      m={smallerThanLarge ? 0 : 2}
      id="datatable"
    >
      <MUIDataTable
        title={(
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" mr={2} lineHeight="38px">{t('users.list')}</Typography>
            <FormControlLabel
              control={(
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  onChange={(event) => handleToggleButtonChange(event)}
                  value={statusFilter}
                  size="small"
                >
                  <ToggleButton value={OPENED_USER_REQUEST}>
                    {t('users.opened')}
                  </ToggleButton>
                  <ToggleButton value={CLOSED_USER_REQUEST}>
                    {t('users.closed')}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Stack>
        )}
        data={data}
        columns={columns}
        options={options}
      />
    </Box>
  );
};

Users.propTypes = {
  users: PropTypes.arrayOf(utilisateurPropType),
  loading: PropTypes.bool,
  setStatusFilter: PropTypes.func.isRequired,
  statusFilter: PropTypes.string,
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  handleClickEdit: PropTypes.func.isRequired,
  handleClickOpenClose: PropTypes.func.isRequired,
  handleClickOpenCloseSelected: PropTypes.func.isRequired,
};

Users.defaultProps = {
  users: [],
  selectedRows: [],
  loading: false,
  statusFilter: OPENED_USER_REQUEST,
};

export default Users;
