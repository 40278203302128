import { get, patch, post } from 'generic/api/apiGenerator';
import handleComplete from 'generic/utils/apiUtils';

const doAutocompleteEmail = ({ ...rest }) => get({ path: '/utilisateur/email', ...rest }).then(handleComplete);

const doAutocompleteUser = ({ ...rest }) => get({ path: '/utilisateur', ...rest }).then(handleComplete);

const fetchUsers = ({ ...rest }) => get({ path: '/utilisateur/retrieve-all', ...rest }).then(handleComplete);

const openCloseUsers = ({ ...rest }) => post(
  { path: '/utilisateur/open-close', ...rest },
).then(handleComplete);

const createUser = ({ ...rest }) => post({ path: '/utilisateur', ...rest }).then(handleComplete);

const patchUser = ({ ...rest }) => patch({ path: '/utilisateur', ...rest }).then(handleComplete);

export {
  createUser,
  doAutocompleteEmail,
  doAutocompleteUser,
  fetchUsers,
  openCloseUsers,
  patchUser,
};
