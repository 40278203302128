import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import {
  AppBar,
  Box,
  ClickAwayListener,
  Divider,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import ToggleMobileSearchIconContainer from 'generic/containers/ToggleMobileSearchIconContainer';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import AccountMenu from 'generic/components/ui/AccountMenu';
import DropdownMenu from 'generic/components/ui/DropdownMenu';
import { overrideRessource } from 'generic/utils/utils';
import HeaderSearchWrapper from 'generic/components/ui/HeaderSearchWrapper';

const logo = overrideRessource('img/header-logo.svg', true);

const useStyles = makeStyles((theme) => ({
  logo: theme.custom.logo,
}));

const LinksMenu = ({ mobile }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Fragment>
      <MenuItem
        component={NavLink}
        to="/anr/folders"
        selected={location.pathname.startsWith('/anr/folders')}
      >
        {t('header.web_tracking')}
      </MenuItem>
      <MenuItem
        component={NavLink}
        to="/carts"
        selected={location.pathname.startsWith('/carts')}
      >
        {t('header.carts')}
      </MenuItem>
      <MenuItem
        component={NavLink}
        to="/newsletters"
        selected={location.pathname.startsWith('/newsletters')}
      >
        {t('header.newsletters')}
      </MenuItem>
      <MenuItem
        component={NavLink}
        to="/alerts"
        selected={location.pathname.startsWith('/alerts')}
      >
        {t('header.alerts')}
      </MenuItem>
      {mobile && (
        <Fragment>
          <Divider />
          <MenuItem
            component={NavLink}
            to={`/dashboard${location?.search || ''}`}
            selected={location.pathname === '/dashboard'}
          >
            {t('header.dashboard')}
          </MenuItem>
          <MenuItem
            component={NavLink}
            to={`/results${location?.search || ''}`}
            selected={location.pathname === '/results'}
          >
            {t('header.results')}
          </MenuItem>
        </Fragment>
      )}
    </Fragment>
  );
};

LinksMenu.propTypes = {
  mobile: PropTypes.bool,
};

LinksMenu.defaultProps = {
  mobile: false,
};

const Header = ({
  user,
}) => {
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setIsSearchInputVisible(!smallerThanLarge);
  }, [smallerThanLarge]);

  const forceHideMenusForMobile = smallerThanLarge && isSearchInputVisible;
  const forceShowMenusForMobile = smallerThanLarge && !isSearchInputVisible;
  const toolbarPadding = smallerThanLarge ? '10px' : '30px';

  return (
    <ClickAwayListener
      onClickAway={() => (forceHideMenusForMobile ? setIsSearchInputVisible(false) : _.noop)}
      mouseEvent="onMouseDown"
    >
      <Box width="100%">
        <AppBar elevation={0} position="static">
          <Toolbar
            className={clsx('displayFlex', 'justifyContentSpaceBetween')}
            sx={{ pl: toolbarPadding, pr: toolbarPadding }}
          >
            <Fragment>
              <Box
                display="flex"
                alignItems="center"
              >
                <img
                  src={logo}
                  className={classes.logo}
                  alt="Qwam Logo"
                  style={{ display: forceHideMenusForMobile ? 'none' : 'block' }}
                />
                <DropdownMenu
                  btnChildren={<Menu />}
                  btnProps={{
                    sx: {
                      display: forceShowMenusForMobile ? 'flex' : 'none',
                      padding: '8px',
                      color: '#ffffff',
                    },
                  }}
                >
                  <LinksMenu mobile />
                </DropdownMenu>
                {!smallerThanLarge && <LinksMenu />}
              </Box>
              <HeaderSearchWrapper showSearchInput={isSearchInputVisible} />
              <Box display="flex" alignItems="center">
                <ToggleMobileSearchIconContainer
                  handleClick={() => setIsSearchInputVisible(true)}
                  isVisible={forceShowMenusForMobile}
                />
                <Box
                  sx={{
                    display: forceHideMenusForMobile ? 'none' : 'inline-block',
                  }}
                >
                  { user && <AccountMenu user={user} /> }
                </Box>
              </Box>
            </Fragment>
          </Toolbar>
        </AppBar>
      </Box>
    </ClickAwayListener>
  );
};

Header.propTypes = {
  user: utilisateurPropType,
};

Header.defaultProps = {
  user: undefined,
};

export default Header;
