import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsNetworkGraph from 'highcharts/modules/networkgraph';

HighchartsNoData(Highcharts);
HighchartsNetworkGraph(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    chart: { type: 'networkgraph' },
    credits: { enabled: false },
    plotOptions: {
      networkgraph: {
        keys: ['from', 'to'],
        layoutAlgorithm: {
          enableSimulation: false,
          maxIterations: 100,
          friction: -0.9,
          integration: 'euler',
          maxSpeed: 1,
        },
      },
    },
    title: { text: 'Titre Network Graph' },
    series: [{
      dataLabels: {
        enabled: true,
        linkFormat: '',
      },
    }],
  },
};

const NetworkGraph = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} allowChartUpdate={false} immutable={false} />
  );
};

NetworkGraph.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default NetworkGraph;
