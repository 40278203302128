import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Logout,
  NotInterested,
  People,
  RssFeed,
} from '@mui/icons-material';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import { civiliteToGender } from 'generic/utils/qesUtils';
import UserAvatar from 'generic/components/ui/UserAvatar';

const TooltipContent = React.memo(({ user }) => {
  const gender = civiliteToGender(user.civilite);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography fontWeight="bold">
        {t('account_menu.signed_in_as', { context: gender, logon: user.logon })}
      </Typography>
      {`${user.prenom} ${user.nom}`}
      <br />
      <i>{user.email}</i>
    </React.Fragment>
  );
});

const AccountMenu = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  const { t } = useTranslation();
  const menuWithAnchorStyle = {
    overflow: 'visible',
    boxShadow: 5,
    mt: 1.5,
    minWidth: '15%',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: smallerThanMedium ? 10 : 15,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  };

  return (
    <Fragment>
      <Tooltip title={<TooltipContent user={user} />}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, p: 0 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <UserAvatar user={user} />
        </IconButton>
      </Tooltip>

      <Menu
        PaperProps={{
          sx: menuWithAnchorStyle,
        }}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <UserAvatar user={user} />
          {t('account_menu.profil')}
        </MenuItem>

        <Divider />

        <MenuItem
          component={Link}
          to="/anr/sources"
          selected={location.pathname === '/anr/sources'}
        >
          <ListItemIcon>
            <RssFeed fontSize="small" />
          </ListItemIcon>
          {t('account_menu.sources')}
        </MenuItem>

        <Divider />

        <MenuItem
          component={Link}
          to="/anr/banned"
          selected={location.pathname === '/anr/banned'}
        >
          <ListItemIcon>
            <NotInterested fontSize="small" />
          </ListItemIcon>
          {t('anr.banned_sites')}
        </MenuItem>

        {user.admin && <Divider />}
        {user.admin && (
          <MenuItem
            component={Link}
            to="/users"
            selected={location.pathname === '/users'}
          >
            <ListItemIcon>
              <People fontSize="small" />
            </ListItemIcon>
            {t('users.list')}
          </MenuItem>
        )}

        <Divider />
        <MenuItem
          component={Link}
          to="/logout"
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('account_menu.logout')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

TooltipContent.propTypes = {
  user: utilisateurPropType.isRequired,
};

AccountMenu.propTypes = {
  user: utilisateurPropType.isRequired,
};

export default AccountMenu;
