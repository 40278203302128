import {
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import {
  types,
  fetchCommentError,
  deleteCommentError,
  createCommentError,
  deleteCommentSuccess,
  createCommentSuccess,
  updateCommentAlertSuccess,
  updateCommentAlertError,
} from 'generic/core/comment/actions';
import {
  fetchComments as fetchCommentsApi,
  deleteComment as deleteCommentApi,
  createComment as createCommentApi,
  updateCommentAlert as updateCommentAlertApi,
} from 'generic/api/comment';
import { snackActions } from 'generic/utils/snackbar';
import { updateDocumentAlert, updateDocumentComments } from 'generic/core/search/actions';

function* workFetchComments({ params }) {
  try {
    const comments = yield call(fetchCommentsApi, { uriParams: { ...params } });
    yield put(updateDocumentComments(params.article, comments));
  } catch (error) {
    yield put(fetchCommentError(error));
    console.error(error);
    snackActions.error(i18next.t('results.comments.error_fetching_comments'));
  }
}

function* watchFetchComments() {
  yield takeLatest(types.FETCH_COMMENT, workFetchComments);
}

function* workDeleteComment({ params }) {
  try {
    const comments = yield call(deleteCommentApi, { uriParams: { ...params } });
    const userId = yield select((state) => state.auth.user.utilisateur);
    yield put(updateDocumentComments(params.article, comments, userId));
    yield put(deleteCommentSuccess());
    snackActions.info(i18next.t('results.comments.deleted'));
  } catch (error) {
    yield put(deleteCommentError(error));
    console.error(error);
    snackActions.error(i18next.t('results.comments.error_deleting_comment'));
  }
}

function* watchDeleteComment() {
  yield takeLatest(types.DELETE_COMMENT, workDeleteComment);
}

function* workCreateComment({ params }) {
  try {
    const comments = yield call(createCommentApi, { uriParams: { ...params } });
    const userId = yield select((state) => state.auth.user.utilisateur);
    yield put(updateDocumentComments(params.article, comments, userId));
    yield put(createCommentSuccess());
    snackActions.info(i18next.t('results.comments.created'));
  } catch (error) {
    yield put(createCommentError(error));
    console.error(error);
    snackActions.error(i18next.t('results.comments.error_submiting_comment'));
  }
}

function* watchCreateComment() {
  yield takeLatest(types.CREATE_COMMENT, workCreateComment);
}

function* workUpdateCommentAlert({ params }) {
  try {
    const response = yield call(updateCommentAlertApi, { uriParams: { ...params } });
    yield put(updateDocumentAlert(params.article, response.alert));
    yield put(updateCommentAlertSuccess());
    snackActions.info(i18next.t('results.comments.alert_updated'));
  } catch (error) {
    yield put(updateCommentAlertError(error));
    console.error(error);
    snackActions.error(i18next.t('results.comments.error_updating_comment_alert'));
  }
}

function* watchUpdateCommentAlert() {
  yield takeLatest(types.UPDATE_COMMENT_ALERT, workUpdateCommentAlert);
}

export default {
  watchFetchComments,
  watchDeleteComment,
  watchCreateComment,
  watchUpdateCommentAlert,
};
