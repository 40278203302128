import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import {
  TextField,
} from 'formik-mui';
import { InputAdornment } from '@mui/material';
import { Clear } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';

const FormAnRFilters = ({
  handleChange,
  handleClear,
  handleSubmit,
  initialValues,
  showFilterInput,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      enableReinitialize
      validateOnMount
    >
      {(form) => (
        <Form>
          {showFilterInput && (
            <Field
              sx={{ margin: '0', ml: 1 }}
              component={TextField}
              onChange={(event) => {
                form.handleChange(event);
                handleChange(event);
              }}
              placeholder={t('form.search')}
              name="subquery"
              color="primary"
              InputProps={{
                sx: { pr: 0.5 },
                endAdornment: (
                  <InputAdornment
                    position="end"
                  >
                    <TooltipButton
                      title={t('form.clear')}
                      tag="iconButton"
                      onClick={() => handleClear('subquery')}
                      disabled={!form.values.subquery}
                    >
                      <Clear />
                    </TooltipButton>
                  </InputAdornment>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                sx: { py: 0.5 },
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

FormAnRFilters.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape().isRequired,
  showFilterInput: PropTypes.bool.isRequired,
};

export default FormAnRFilters;
