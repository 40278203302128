export const types = {
  CHANGE_ACTIVE_BASE: '@CONFIG/CHANGE_ACTIVE_BASE',
  FETCH_FORM_CONFIG: '@CONFIG/FETCH_FORM_CONFIG',
  FETCH_FORM_CONFIG_SUCCESS: '@CONFIG/FETCH_FORM_CONFIG_SUCCESS',
  FETCH_FORM_CONFIG_ERROR: '@CONFIG/FETCH_FORM_CONFIG_ERROR',
  SET_ACTIVE_BASE: '@CONFIG/SET_ACTIVE_BASE',
  SET_CONFIG: '@CONFIG/SET_CONFIG',
  SET_FORM_FIELDS: '@CONFIG/SET_FORM_FIELDS',
};

export const setConfig = (config) => ({
  type: types.SET_CONFIG,
  config,
});

export const changeActiveBase = (baseId) => ({
  type: types.CHANGE_ACTIVE_BASE,
  baseId,
});

export const fetchFormConfig = (values, options) => ({
  type: types.FETCH_FORM_CONFIG,
  values,
  options,
});

export const fetchFormConfigSuccess = (results) => ({
  type: types.FETCH_FORM_CONFIG_SUCCESS,
  results,
});

export const fetchFormConfigError = (response) => ({
  type: types.FETCH_FORM_CONFIG_ERROR,
  response,
});

export const setActiveBase = (base) => ({
  type: types.SET_ACTIVE_BASE,
  base,
});

export const setFormFields = (fields) => ({
  type: types.SET_FORM_FIELDS,
  fields,
});
