import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { restoreStateFromStorage } from 'generic/core/auth/actions';
import { fetchFormConfig } from 'generic/core/config/actions';
import { clearSelection } from 'generic/core/selection/actions';
import Layout from 'generic/components/pages/Layout';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { cleanupResults } from 'generic/core/search/actions';
import { CONSTANTS } from 'generic/core/constants';

const { MAIN_PAGE } = CONSTANTS;

const ProtectedRoutes = ({
  component: Component,
  exact,
  path,
  location,
  keepFormConfig,
  title,
}) => {
  const logged = useSelector((state) => state.auth.logged);
  const loading = useSelector((state) => state.auth.loading);
  const userForbiddenRoutes = useSelector((state) => state.auth.user?.forbiddenRoutes);
  const dispatch = useDispatch();
  useEffect(() => {
    if (logged && !keepFormConfig) {
      // Ici on tente de réinitialiser le formulaire si on a
      // pas la prop keepFormConfig à true
      dispatch(fetchFormConfig({}, { fetchOnlyIfStrategy: true }));
      // On vide également la sélection ET les résultats * poof *
      dispatch(clearSelection());
      dispatch(cleanupResults());
    }
  }, [path, dispatch, logged, keepFormConfig]);

  useEffect(() => {
    if (loading) {
      // Si jamais on arrive ici c'est que l'authentification est en
      // "attente", et qu'il faut tenter de voir si l'utilisateur est
      // connecté via le localStorage
      dispatch(restoreStateFromStorage());
    }
  }, [loading, dispatch]);

  if (loading) {
    // Tant qu'on ne sait pas si l'utilisateur est réellement connecté
    // ou non, on affiche un preloader global
    return (
      <GlobalLoading />
    );
  }
  if (!logged) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: location },
        }}
      />
    );
  }

  if (!_.isEmpty(userForbiddenRoutes) && userForbiddenRoutes.includes(path)) {
    return (
      <Redirect
        to={{
          pathname: MAIN_PAGE,
          state: { referrer: location },
        }}
      />
    );
  }
  document.title = title;
  return (
    <Layout
      exact={exact}
      path={path}
      component={Component}
    />
  );
};

ProtectedRoutes.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  exact: PropTypes.bool,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  keepFormConfig: PropTypes.bool,
  title: PropTypes.string,
};

ProtectedRoutes.defaultProps = {
  exact: true,
  location: { state: { referrer: {} } },
  keepFormConfig: false,
  title: 'Infomedia',
};

export default ProtectedRoutes;
