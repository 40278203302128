import {
  take,
  put,
  call,
  fork,
  cancel,
  takeEvery,
  takeLeading,
  all,
} from 'redux-saga/effects';
import i18next from 'i18next';

import {
  fetchError,
  loginSuccess,
  loginError,
  restoreStateFromStorageError,
} from 'generic/core/auth/actions';

import {
  login,
  loginTest,
  logout,
} from 'generic/api/auth';

import * as types from 'generic/core/auth/actionTypes';
import { snackActions } from 'generic/utils/snackbar';
import { setConfig } from 'generic/core/config/actions';
import { cleanupAnRUser } from 'generic/core/anr/actions';

const clearAuth = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('config');
};

const storeAuth = ({ user, token, config }) => {
  if (user && token && config) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('config', JSON.stringify(config));
  }
};

function* authenticate({ logon, password }) {
  try {
    const { key: token, utilisateur: user, config } = yield call(login, { logon, password });
    yield call(storeAuth, { token, user, config });
    yield all([
      put(setConfig(config)),
      put(loginSuccess({ user, token })),
    ]);
  } catch (error) {
    yield put(loginError(error));
  }
}

function* unauthenticate() {
  // On se fout de savoir si la déconnexion s'est bien passé ou non.
  // Souvent, le token déjà périmé et demander une invalidation de session est inutile.
  try {
    yield call(logout);
  } catch (error) {
    console.error(error);
  }
}

function* checkAuthValidity(object) {
  const { response } = object;
  // Lorsqu'une action est dispatchée et si son nom match /.*_ERROR$/ il est possible que
  // ce soit le token qui ne soit plus valide.
  // Si l'erreur fait suite à un `fetch` on peut alors voir si on a une erreur 401 et demander
  // une reconnexion si besoin.
  const isPromiseResponse = response instanceof Response;
  if (isPromiseResponse && !response.ok && response.status === 401) {
    yield put({ type: types.LOGOUT });
    snackActions.error(i18next.t('login.error_unauthorized'));
  }
}

function* restoreStateFromStorage() {
  let user;

  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    console.error('can\'t retrieve keys from store', e);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('config');
  }

  if (user) {
    const response = yield call(loginTest, { logon: user.logon });
    yield call(checkAuthValidity, { response });
  }

  user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const config = JSON.parse(localStorage.getItem('config'));
  if (user && token && config) {
    yield all([
      put(setConfig(config)),
      put(loginSuccess({ user, token })),
    ]);
  } else {
    yield put(restoreStateFromStorageError('can\'t retrieve user from storage'));
  }
}

function* workLogout() {
  // on invalide le localstorage
  yield call(clearAuth);
  // on vire également les infos sur le user Ask'n'Read dans Redux
  yield put(cleanupAnRUser());

  yield call(unauthenticate);
}

function* watchLogout() {
  yield takeLeading(types.LOGOUT, workLogout);
}

function* watchFetchErrors() {
  yield takeEvery(types.FETCH_ERROR, checkAuthValidity);
}

function* watchAuthRestauration() {
  yield takeEvery(types.RESTORE_STATE_FROM_STORAGE, restoreStateFromStorage);
}

function* watchAuth() {
  while (true) {
    const { type, logon, password } = yield take([types.LOGIN, types.LOGIN_SUCCESS]);
    let taskAuthenticate;
    if (type === types.LOGIN) {
      taskAuthenticate = yield fork(authenticate, { logon, password });
    }

    yield take([types.LOGOUT, types.LOGIN_ERROR]);
    // si un tache d'authentification est en cours, on l'annule
    if (taskAuthenticate) yield cancel(taskAuthenticate);
  }
}

function* handleAjaxFailures({ response }) {
  yield put(fetchError(response));
}

function* watchAjaxFailures() {
  yield takeLeading((action) => /.*_ERROR$/.test(action.type), handleAjaxFailures);
}

export default {
  watchAjaxFailures,
  watchAuthRestauration,
  watchAuth,
  watchFetchErrors,
  watchLogout,
};
