import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonGroup,
  Menu,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EventRepeat, RestartAlt } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';

const CriteriaButtons = ({
  handleReset,
  handleSearchPeriod,
  showSearchPeriodButton,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenPeriodsMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClosePeriodsMenu = () => setAnchorEl(null);
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box p={1} pb={0.5}>
        <ButtonGroup
          sx={{ height: '32px' }}
          disableElevation
        >
          <TooltipButton
            key="reset"
            sx={{ borderColor: 'primary', borderRadius: showSearchPeriodButton ? '15px 0 0 15px' : '15px' }}
            title={t('results.criteria.reset')}
            onClick={handleReset}
          >
            <RestartAlt />
          </TooltipButton>
          {showSearchPeriodButton && (
            <TooltipButton
              key="period"
              sx={{ borderColor: 'primary', borderRadius: '0 15px 15px 0' }}
              title={t('results.criteria.period')}
              onClick={handleOpenPeriodsMenu}
            >
              <EventRepeat />
            </TooltipButton>
          )}
        </ButtonGroup>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClosePeriodsMenu}
        onClick={handleClosePeriodsMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            ml: 4,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 40,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleSearchPeriod('today')}>
          {t('commons.periods.today')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('yesterday')}>
          {t('commons.periods.yesterday')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('this_week')}>
          {t('commons.periods.this_week')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_week')}>
          {t('commons.periods.last_week')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_7_days')}>
          {t('commons.periods.last_7_days')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('this_month')}>
          {t('commons.periods.this_month')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_month')}>
          {t('commons.periods.last_month')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_30_days')}>
          {t('commons.periods.last_30_days')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('this_year')}>
          {t('commons.periods.this_year')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_year')}>
          {t('commons.periods.last_year')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_12_months')}>
          {t('commons.periods.last_12_months')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

CriteriaButtons.propTypes = {
  handleReset: PropTypes.func.isRequired,
  handleSearchPeriod: PropTypes.func.isRequired,
  showSearchPeriodButton: PropTypes.bool.isRequired,
};

export default CriteriaButtons;
