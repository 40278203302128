import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FastField, Form, Formik } from 'formik';
import { TextField, RadioGroup } from 'formik-mui';
import _ from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Stack,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Skeleton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TooltipButton from 'generic/components/ui/TooltipButton';
import AutocompleteAjax from 'generic/components/forms/AutocompleteAjax';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { sendToFriendDataPropType } from 'generic/core/qes/proptypes';

const { TYPE_PJ_WITHOUT } = QES_CONSTANTS;

const SendToFriendDialog = ({
  bccFieldVisible,
  building,
  ccFieldVisible,
  doAutocomplete,
  handleClose,
  handleDisplayBccField,
  handleDisplayCcField,
  handleSubmit,
  loading,
  scope,
  sendToFriendData,
}) => {
  const { t } = useTranslation();

  const handleGetOptionLabel = (option) => {
    const emailStr = (option.email && option.email !== option.nom) ? ` (${option.email})` : '';
    return _.isObject(option) ? `${option.prenom} ${option.nom}${emailStr}` : option;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        to: [],
        cc: [],
        bcc: [],
        subject: sendToFriendData.sujet || '',
        pj: sendToFriendData.pieceJointe || '',
        typepj: TYPE_PJ_WITHOUT,
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {(form) => (
        <Form>
          <Dialog
            scroll="paper"
            fullWidth
            maxWidth="lg"
            PaperProps={{
              sx: { minHeight: '60%' },
            }}
            open
          >
            <DialogTitle>
              {t('send_to_friend.send_to')}
            </DialogTitle>
            <DialogContent
              dividers
              sx={{ display: 'flex', flexDirection: 'column', minHeight: '70%' }}
            >
              <Box
                mb={2}
              >
                <Box display="flex" alignItems="center">
                  <FastField
                    component={AutocompleteAjax}
                    name="to"
                    multiple
                    freeSolo
                    highlight={false}
                    doAutocompleteOverride={doAutocomplete}
                    getOptionLabel={handleGetOptionLabel}
                    fullWidth
                    textFieldProps={{
                      label: t('send_to_friend.to'),
                    }}
                  />
                  {(!ccFieldVisible || !bccFieldVisible) && (
                    <Stack
                      direction="row"
                      spacing={1}
                      ml={1}
                    >
                      {!ccFieldVisible && (
                        <TooltipButton
                          color="primary"
                          onClick={handleDisplayCcField}
                          tag="iconButton"
                          title={t('send_to_friend.display_cc_field')}
                        >
                          <Typography>
                            {t('send_to_friend.cc')}
                          </Typography>
                        </TooltipButton>
                      )}
                      {!bccFieldVisible && (
                        <TooltipButton
                          color="primary"
                          onClick={handleDisplayBccField}
                          tag="iconButton"
                          title={t('send_to_friend.display_bcc_field')}
                        >
                          <Typography>
                            {t('send_to_friend.bcc')}
                          </Typography>
                        </TooltipButton>
                      )}
                    </Stack>
                  )}
                </Box>
                {ccFieldVisible && (
                  <FastField
                    component={AutocompleteAjax}
                    name="cc"
                    multiple
                    freeSolo
                    highlight={false}
                    doAutocompleteOverride={doAutocomplete}
                    getOptionLabel={handleGetOptionLabel}
                    fullWidth
                    textFieldProps={{
                      label: t('send_to_friend.cc'),
                    }}
                  />
                )}
                {bccFieldVisible && (
                  <FastField
                    component={AutocompleteAjax}
                    name="bcc"
                    multiple
                    freeSolo
                    highlight={false}
                    doAutocompleteOverride={doAutocomplete}
                    getOptionLabel={handleGetOptionLabel}
                    fullWidth
                    textFieldProps={{
                      label: t('send_to_friend.bcc'),
                    }}
                  />
                )}
                {building && (
                  <Skeleton variant="rounded" width="100%" height={45} sx={{ mt: 0.5, mb: 0.5 }} />
                )}
                {!building && (
                  <FastField
                    component={TextField}
                    label={t('send_to_friend.subject')}
                    name="subject"
                  />
                )}
                <FastField
                  component={TextField}
                  multiline
                  minRows={3}
                  label={t('send_to_friend.comment')}
                  name="comment"
                />
              </Box>
              {scope !== 'anr' && (
                <Box
                  mb={3}
                >
                  {building && (
                    <Skeleton variant="rectangular" height={150} sx={{ mt: 2, maxWidth: '600px' }} />
                  )}
                  {!building && !sendToFriendData.seuilAtteint && (
                    <FormControl component="fieldset">
                      <FormLabel id="radio-group-label">{t('send_to_friend.attachment')}</FormLabel>
                      <FastField
                        component={RadioGroup}
                        name="typepj"
                        aria-labelledby="radio-group-label"
                        sx={{ ml: 2 }}
                      >
                        {_.map(sendToFriendData.pjs, (option) => (
                          <FormControlLabel
                            key={option.code}
                            value={option.code}
                            control={<Radio />}
                            label={option.libelle}
                          />
                        ))}
                      </FastField>
                    </FormControl>
                  )}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                variant="text"
              >
                {t('dialog.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                onClick={form.submitForm}
                disabled={
                  loading
                  || building
                  || !form.isValid
                  || (form.values.to.length === 0 && form.values.cc.length === 0 && form.values.bcc.length === 0)
                }
                loading={loading}
              >
                {t('form.send')}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

SendToFriendDialog.propTypes = {
  sendToFriendData: sendToFriendDataPropType,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doAutocomplete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  building: PropTypes.bool,
  ccFieldVisible: PropTypes.bool,
  handleDisplayCcField: PropTypes.func.isRequired,
  bccFieldVisible: PropTypes.bool,
  handleDisplayBccField: PropTypes.func.isRequired,
  scope: PropTypes.oneOf(['results', 'anr', 'cart']).isRequired,
};

SendToFriendDialog.defaultProps = {
  sendToFriendData: {},
  loading: false,
  building: false,
  ccFieldVisible: false,
  bccFieldVisible: false,
};

export default SendToFriendDialog;
