import { types } from 'generic/core/alerts/actions';

const alertsReducer = (
  state = {
    alerts: [],
    form: {},
    loading: true,
    editAlert: {},
  },
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_ALERT: {
      return {
        ...state,
        editAlert: {},
      };
    }
    case types.CLEANUP_ALERTS: {
      return {
        ...state,
        alerts: [],
      };
    }
    case types.FETCH_ALERTS: {
      return {
        ...state,
        alerts: [],
        loading: true,
      };
    }
    case types.FETCH_ALERTS_SUCCESS: {
      return {
        ...state,
        alerts: action.alerts,
        loading: false,
      };
    }
    case types.FETCH_ALERTS_ERROR: {
      return {
        ...state,
        alerts: [],
        loading: false,
      };
    }
    case types.FETCH_ALERT: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.FETCH_ALERT_SUCCESS: {
      return {
        ...state,
        editAlert: action.alert,
        loading: false,
      };
    }
    case types.FETCH_ALERT_ERROR: {
      return {
        ...state,
        editAlert: {},
        loading: false,
      };
    }
    case types.CREATE_ALERT: {
      return {
        ...state,
        createAlertLoading: true,
      };
    }
    case types.CREATE_ALERT_SUCCESS: {
      return {
        ...state,
        results: action.results,
        createAlertLoading: false,
      };
    }
    case types.CREATE_ALERT_ERROR: {
      return {
        ...state,
        responseData: action.response,
        createAlertLoading: false,
      };
    }
    case types.SAVE_ALERT: {
      return {
        ...state,
        saveAlertLoading: true,
      };
    }
    case types.SAVE_ALERT_SUCCESS: {
      return {
        ...state,
        saveALertLoading: false,
      };
    }
    case types.SAVE_ALERT_ERROR: {
      return {
        ...state,
        responseData: action.response,
        saveALertLoading: false,
      };
    }
    case types.FETCH_ALERT_FORM: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.FETCH_ALERT_FORM_SUCCESS: {
      return {
        ...state,
        loading: false,
        form: action.results,
      };
    }
    case types.FETCH_ALERT_FORM_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default alertsReducer;
