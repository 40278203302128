const fr = {
  fr: {
    translation: {
      safran: {
        dashboard: {
          involved_function: 'Fonction concernée',
          progression: 'Progression',
          answer: 'Répondre',
          company: 'Filiale',
          companies: 'Filiales',
          cancel_filter: 'Annuler le filtre',
          control_topics: 'Thèmes de contrôle',
          topics_and_scores: 'Thèmes / Scores',
          questions_count: 'Nombre de questions',
          distribution_count: 'Distribution',
          applicability: 'Applicabilité',
          avg_scores_per_topic: 'Moyenne des scores par thème',
        },
        report: {
          actions: {
            generate: 'Produire le rapport',
            test: 'Rapport de test',
          },
          all_ok_info: 'Toutes les questions ont été complétées, vous pouvez dès à présent générer un rapport',
          warning_info: `
            <p>Certaines questions n'ont pas encore été complétées.</p>
            <p>Vous ne pourrez créer le rapport final que lorsque l'ensemble des questions auront été complétées.</p>
          `,
          warning_list_files: 'Voir la liste des questions à compléter',
          error_getting_unanswered_filelist: 'Erreur lors de la récupération des questions sans réponses',
        },
        applicable: 'Applicable',
        not_applicable: 'Non applicable',
      },
      header: {
        home: 'Accueil',
        my_space: 'Mon espace',
        report: 'Rapport',
        global: 'Vue globale',
      },
      route: {
        app_name: 'ICA Safran',
        report: 'Générer le rapport...',
      },
      ged: {
        document: {
          status: {
            1: 'À répondre',
            50: 'À valider',
            70: 'À compléter',
            100: 'Complétées',
          },
          status_single: {
            1: 'À répondre',
            50: 'À valider',
            70: 'À compléter',
            100: 'Complétée',
          },
        },
      },
    },
  },
};

export default fr;
