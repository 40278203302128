import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Grid,
} from '@mui/material';

import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import THEME_CONFIG from 'generic/core/theme';
import { fetchWidgets } from 'generic/core/dashboard/actions';

import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';

import WidgetContainer from 'generic/containers/WidgetContainer';
import Polar from 'generic/components/dashboard-items/Polar';
import SolidGauge from 'generic/components/dashboard-items/SolidGauge';
import { refineAddFacetValue } from 'generic/core/search/actions';
import Treemap from 'generic/components/dashboard-items/Treemap';
import Pie from 'generic/components/dashboard-items/Pie';
import Spline from 'generic/components/dashboard-items/Spline';

const { stateDocumentColor } = THEME_CONFIG.HIGHCHARTS;

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const DashboardChartsContainer = () => {
  const strategy = useSelector((state) => state.search.results.strategie);
  const userIsContributor = useSelector((state) => state.auth.user.isContributor);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      const DATE_FACETS = [
        'DATE_COLLECT',
        'DATE_COLLECT_100',
        'DATE_COLLECT_50',
        'DATE_CONTRACTUELLE',
        'DATE_DE_DERNIERE_LIVRAISON',
        'DATE_DE_PREMIERE_APPROBATION',
        'DATE_MODIF',
      ];

      dispatch(fetchWidgets(strategy, {
        societe: {
          facets: 'Societe',
          aggregates: ['agg_Societe'],
          type: 'pie',
        },
        dates: {
          facets: DATE_FACETS.map((d) => `${d}#date_histogram|1d _key:asc`).join(';'),
          aggregates: DATE_FACETS.map((d) => `agg_${d}`),
          seriesNames: DATE_FACETS,
          type: 'spline',
        },
        applicability: {
          facets: 'Applicabilite',
          aggregates: ['agg_Applicabilite'],
          type: 'pie',
        },
        controlTheme: {
          facets: 'Theme_controle.verbatim',
          aggregates: ['agg_Theme_controle.verbatim'],
          type: 'treemap',
        },
        documentGaugeStatus: {
          facets: 'i_ETAT _key:asc',
          aggregates: ['agg_i_ETAT'],
          type: 'solidgauge',
        },
        scoreByTheme: {
          facets: 'Theme_controle.verbatim,Scoring#avg',
          aggregates: ['agg_Theme_controle.verbatim'],
          additionalQuery: 'Applicabilite:true',
          facetmax: 50,
          facetmax2: 10,
          type: 'polar',
        },
      }));
    }
  }, [dispatch, strategy]);

  return (
    <Grid
      container
      spacing={1}
      className="desktopOnlyOverflow"
      p={1}
    >
      {!userIsContributor && (
        <Grid item xs={12} md={3}>
          <WidgetContainer widgetId="societe">
            {({ series: societeSerie, height }) => (
              <DashboardWidget
                height={height}
                component={Pie}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      pie: {
                        data: _.get(societeSerie, '[0].data', []),
                        point: {
                          events: {
                            click: (event) => {
                              dispatch(refineAddFacetValue(
                                { champ: 1032000587, strategie: event.point.options.strategy },
                              ));
                            },
                          },
                        },
                      },
                    },
                    title: { text: t('safran.dashboard.companies') },
                  },
                }}
              />
            )}
          </WidgetContainer>
        </Grid>
      )}

      <Grid item xs={12} md={userIsContributor ? 8 : 6}>
        <WidgetContainer widgetId="dates">
          {({ series: datesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Spline}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: { text: 'Dates' },
                  series: datesSerie,
                  yAxis: { title: { text: t('safran.dashboard.distribution_count') } },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={userIsContributor ? 4 : 3}>
        <WidgetContainer widgetId="applicability">
          {({ series: applicabilitySerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: _.get(applicabilitySerie, '[0].data', []).map(
                        ({ name, ...rest }) => ({
                          name: /true/i.test(name) ? t('safran.applicable') : t('safran.not_applicable'),
                          ...rest,
                        }),
                      ),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetValue(
                              { champ: 1032000585, strategie: event.point.options.strategy },
                            ));
                          },
                        },
                      },
                    },
                  },
                  title: { text: t('safran.dashboard.applicability') },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={userIsContributor ? 6 : 4}>
        <WidgetContainer widgetId="controlTheme">
          {({ series: controlThemeSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Treemap}
              highchartsOptions={{
                options: {
                  exporting: {
                    enabled: false,
                  },
                  chart: {
                    height,
                  },
                  plotOptions: {
                    treemap: {
                      data: controlThemeSerie[0].data,
                      colorByPoint: true,
                      layoutStartingDirection: 'horizontal',
                      layoutAlgorithm: 'squarified',
                      point: {
                        events: {
                          click: (event) => {
                            console.log(event.point.name);
                            dispatch(
                              refineAddFacetValue({
                                champ: 1032000584,
                                strategie: `"${_.trim(event.point.name, ' "')}"`,
                              }),
                            );
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: '' },
                  ],
                  title: { text: t('safran.dashboard.control_topics') },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={userIsContributor ? 6 : 4}>
        <WidgetContainer widgetId="documentGaugeStatus">
          {({ series: documentGaugeStatusSerie, axisX, height }) => (
            <DashboardWidget
              height={height}
              component={SolidGauge}
              highchartsOptions={{
                options: {
                  plotOptions: {
                    solidgauge: {
                      events: {
                        click: (event) => {
                          dispatch(
                            refineAddFacetValue({ champ: 110, strategie: event.point.name }),
                          );
                        },
                      },
                    },
                  },
                  exporting: {
                    enabled: false,
                  },
                  chart: {
                    height,
                  },
                  pane: {
                    background: axisX.categories.map((aggregate, index) => ({
                      outerRadius: aggregate.outerRadius,
                      innerRadius: aggregate.innerRadius,
                      borderWidth: 0,
                      backgroundColor: `${stateDocumentColor[documentGaugeStatusSerie[0].data[index].name]}4D`,
                    })),
                  },
                  tooltip: {
                    enabled: false,
                  },
                  legend: {
                    align: 'center',
                    // eslint-disable-next-line func-names, object-shorthand
                    labelFormatter: function () {
                      // eslint-disable-next-line react/no-this-in-sfc
                      return `${t(`ged.document.status.${this.name}`)} - ${this.data[0].y}%`;
                    },
                  },
                  series: documentGaugeStatusSerie[0].data.map((aggregate) => ({
                    name: aggregate.name,
                    data: aggregate.data.map((aggdata) => ({
                      radius: aggdata.radius,
                      innerRadius: aggdata.innerRadius,
                      name: aggdata.name,
                      y: aggdata.y,
                      color: stateDocumentColor[aggdata.name],
                    })),
                    color: stateDocumentColor[aggregate.name],
                    showInLegend: true,
                  })),
                  title: { text: t('safran.dashboard.progression') },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      {!userIsContributor && (
        <Grid item xs={12} md={4}>
          <WidgetContainer widgetId="scoreByTheme">
            {({ series: scoreByThemeSerie, axisX, height }) => (
              <DashboardWidget
                height={height}
                component={Polar}
                highchartsOptions={{
                  options: {
                    exporting: {
                      enabled: false,
                    },
                    xAxis: axisX,
                    yAxis: {
                      min: 0,
                      max: 4,
                    },
                    chart: {
                      height,
                    },
                    series: scoreByThemeSerie,
                    title: { text: t('safran.dashboard.avg_scores_per_topic') },
                  },
                }}
              />
            )}
          </WidgetContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(DashboardChartsContainer);
