import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const FormGEDSideEffect = ({ setFieldsExtraProps }) => {
  const {
    values,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    const notApplicable = values.Applicabilite === 'false';
    if (!notApplicable && values.Justification) {
      setFieldValue('Justification', '');
    }
    if (notApplicable && values.Reponse) {
      setFieldValue('Reponse', '');
    }
    if (notApplicable && values.Scoring !== 0) {
      setFieldValue('Scoring', 0);
    }
    setFieldsExtraProps({
      Justification: {
        disabled: !notApplicable,
        forceMandatory: notApplicable,
      },
      Reponse: {
        disabled: notApplicable,
      },
      Scoring: {
        disabled: notApplicable,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.Applicabilite, setFieldValue, setFieldsExtraProps]);

  return null;
};

FormGEDSideEffect.propTypes = {
  setFieldsExtraProps: PropTypes.func.isRequired,
};

export default FormGEDSideEffect;
