import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import {
  Check,
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import TooltipButton from 'generic/components/ui/TooltipButton';

const useStyles = makeStyles((theme) => ({
  centeredItem: ({ progressColor }) => ({
    position: 'absolute',
    color: progressColor || theme.palette.primary,
    top: '50%',
    left: '50%',
  }),
  btnProgress: {
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  checkIcon: {
    fontSize: '38px',
    marginTop: '-20px',
    marginLeft: '-20px',
  },
}));

const InteractiveButton = ({
  children,
  loading,
  validated,
  title,
  progressColor,
  ...rest
}) => {
  const classes = useStyles({ progressColor });

  return (
    <Box position="absolute">
      <Box position="relative">
        {title ? (
          <TooltipButton
            title={title}
            {...rest}
          >
            {children}
          </TooltipButton>
        ) : (
          <Button
            {...rest}
          >
            {children}
          </Button>
        )}
        {loading && (
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={24}
            className={clsx(classes.centeredItem, classes.btnProgress)}
          />
        )}
        {validated && (
          <Check
            className={clsx(classes.centeredItem, classes.checkIcon)}
          />
        )}
      </Box>
    </Box>
  );
};

InteractiveButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  validated: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  progressColor: PropTypes.string,
};
InteractiveButton.defaultProps = {
  disabled: false,
  loading: false,
  validated: false,
  title: '',
  progressColor: '',
};

export default InteractiveButton;
