import PropTypes from 'prop-types';

const qesdocumentPropType = PropTypes.shape({
  BASE_ID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  ID_QES: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  idext: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  DATE_COLLECT: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  ETAT: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  Controle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  Numero_controle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  Pieces_a_fournir: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  Question: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  Theme_controle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  Applicabilite: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
});

const documentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  idext: PropTypes.string.isRequired,
  qesdocument: qesdocumentPropType.isRequired,
  champ_identifiant: PropTypes.string.isRequired,
  champ_etat: PropTypes.string.isRequired,
  champ_titre: PropTypes.string.isRequired,
  base: PropTypes.number.isRequired,

  documentitem: PropTypes.number,
  format: PropTypes.number,
  mouvement: PropTypes.number,
  panier: PropTypes.number,
  etat: PropTypes.number,
  titre: PropTypes.string,
  url: PropTypes.string,
  datePublication: PropTypes.string,
  image: PropTypes.string,
  fichier: PropTypes.string,
  urlFichier: PropTypes.string,
  source: PropTypes.string,
  urlSource: PropTypes.string,
  logo: PropTypes.string,
  logoDescription: PropTypes.string,
  description: PropTypes.string,
  texte: PropTypes.string,
  html: PropTypes.string,
  tonalite: PropTypes.number,
  ranking: PropTypes.number,
  liaisons: PropTypes.arrayOf(PropTypes.string),
  commentaires: PropTypes.arrayOf(PropTypes.string),
  commentAlert: PropTypes.bool,
  hasComment: PropTypes.bool,
});

export {
  documentPropType,
  qesdocumentPropType,
};
