import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  CircularProgress,
  TextField,
} from '@mui/material';
import { Autocomplete } from 'formik-mui';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { doAutocomplete } from 'generic/api/search';
import { useSelector } from 'react-redux';

const handleRenderOption = (props, option, inputValue) => {
  const matches = match(option, inputValue, { insideWords: true });
  const parts = parse(option, matches);

  const highlightStyle = {
    fontWeight: 'bold',
  };

  return (
    <li {...props}>
      <div>
        {parts.map((part, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} style={part.highlight ? highlightStyle : {}}>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

const AutocompleteAjax = ({
  facet,
  minChars,
  textFieldProps,
  doAutocompleteOverride,
  highlight,
  isDisabled,
  ...rest
}) => {
  const activeBaseId = useSelector((state) => state.config.activeBase.base);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(open && options.length === 0);
  const { field, form } = rest;

  // useEffect au changement de "value" (valeur dans l'input) :
  // permet de lancer la requête ajax pour récupérer les propositions
  useEffect(() => {
    let active = true;
    if (!value || value.length < minChars) {
      setOpen(false);
      return undefined;
    }

    setLoading(true);
    (async () => {
      let optionsValues = [];
      try {
        if (doAutocompleteOverride) {
          optionsValues = await doAutocompleteOverride({
            uriParams: {
              filtre: value,
            },
          });
        } else {
          const values = await doAutocomplete({
            uriParams: {
              base: activeBaseId,
              query: '*',
              facetmax: 100,
              facet,
              facetQuery: value,
            },
          });
          optionsValues = _.map(values, 'key');
        }
      } catch (error) {
        console.error(error);
        console.error('error lors de la récupération des valeurs autocomplete');
      }

      setLoading(false);
      if (active) {
        setOptions(optionsValues);
      }
    })();

    return () => {
      active = false;
    };
  }, [minChars, value, field.name, doAutocompleteOverride, activeBaseId, facet]);

  // useEffect pour virer les options lorsque le "dropdown" des
  // résultats est fermé
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const additionnalProps = {};
  if (highlight) {
    additionnalProps.renderOption = (props, option) => (
      // handleRenderOption sur "value" permet de mettre en valeur
      // (en gras par exemple) le texte de l'input (après le
      // dernier opérateur) dans les résultats du dropdown
      handleRenderOption(props, option, value)
    );
  }

  return (
    <Autocomplete
      {...rest}
      disabled={isDisabled}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onBlur={() => form.setFieldTouched(field.name)}
      options={options}
      value={field.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          disabled={isDisabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          onKeyUp={(event) => {
            // Si la valeur n'a pas changé (on a appuyé sur les flèches par
            // exemple, on ne fait rien)
            if (field.value === event.target.value) {
              return;
            }
            // Sinon on "debounce" de 300 ms pour ne pas prendre en compte
            // instantanément le keyDown
            _.debounce(() => {
              const inputValue = !_.isEmpty(event.target.value) ? event.target.value : '';
              setValue(inputValue);
            }, 300)();
          }}
        />
      )}
      {...additionnalProps}
    />
  );
};

AutocompleteAjax.propTypes = {
  facet: PropTypes.string,
  minChars: PropTypes.number,
  textFieldProps: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  doAutocompleteOverride: PropTypes.func,
  highlight: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

AutocompleteAjax.defaultProps = {
  facet: null,
  minChars: 2,
  doAutocompleteOverride: null,
  highlight: true,
  isDisabled: false,
};

export default AutocompleteAjax;
