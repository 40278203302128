import * as types from 'generic/core/auth/actionTypes';
import { overrideRessource } from 'generic/utils/utils';

const { computeUserAdditionnalAttrs } = overrideRessource('utils/customUtils', true);

const authenticationReducer = (
  state = { loading: true, logged: false, loginError: false },
  action,
) => {
  switch (action.type) {
    case types.RESTORE_STATE_FROM_STORAGE:
      return {
        ...state,
        logged: false,
        loading: true,
      };

    case types.RESTORE_STATE_FROM_STORAGE_FAILURE:
      return {
        ...state,
        logged: false,
        loading: false,
      };

    case types.LOGIN:
      return {
        ...state,
        logged: false,
        loading: true,
        loginError: false,
      };

    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        user: {
          ...action.user,
          ...computeUserAdditionnalAttrs(action.user),
        },
        token: action.token,
        logged: true,
        loading: false,
        loginError: false,
      };
    }
    case types.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        loginError: true,
      };

    case types.LOGOUT:
      return {
        ...state,
        loading: false,
        logged: false,
        token: undefined,
        user: undefined,
      };

    default:
      return state;
  }
};

export default authenticationReducer;
