import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {
  Edit,
  EditOff,
  Cancel,
  RemoveCircle,
  AddCircle,
  Search,
} from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TooltipButton from 'generic/components/ui/TooltipButton';

const CRITERE_NOT = '3';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  criterion: {
    position: 'relative',
    fontSize: '0.8125rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: '16px',
    maxWidth: '500px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '250px',
    },
  },
  warning: {
    backgroundColor: 'rgba(237, 108, 2, 0.8)',
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: '12px',
    paddingRight: '12px',
    whiteSpace: 'nowrap',
  },
  input: {
    marginRight: theme.spacing(1),
    height: '32px',
  },
}));

const Criterion = ({
  criterion,
  handleExclude,
  handleInclude,
  handleRemove,
  handleRemoveDisabled,
  handleEdit,
}) => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState(criterion.value);
  const { t } = useTranslation();

  const doSearch = (event) => {
    if (event.type === 'click' || (event.type === 'keypress' && event.key === 'Enter')) {
      handleEdit(criterion, inputValue);
    }
  };

  const initInputSearch = () => {
    setInputValue(criterion.value);
    setEditable(!editable);
  };

  const criterionLabel = `${(criterion.operateur === CRITERE_NOT) ? '(NOT) ' : ''} ${criterion.value}`;
  return (
    <Box className={classes.root}>
      {editable ? (
        <FormControl sx={{ m: 0 }}>
          <OutlinedInput
            className={classes.input}
            size="small"
            type="text"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            onKeyPress={doSearch}
            startAdornment={(
              <InputAdornment position="start">
                <TooltipButton
                  tag="iconButton"
                  onClick={initInputSearch}
                  size="small"
                  edge="start"
                  title={t('results.criteria.noedit')}
                >
                  <EditOff fontSize="small" />
                </TooltipButton>
              </InputAdornment>
            )}
            endAdornment={(
              <InputAdornment position="end">
                <TooltipButton
                  tag="iconButton"
                  onClick={doSearch}
                  edge="end"
                  title={t('results.criteria.search')}
                >
                  <Search />
                </TooltipButton>
              </InputAdornment>
            )}
          />
        </FormControl>
      ) : (
        <Box
          className={
            clsx(classes.criterion, {
              [classes.warning]: (criterion.operateur === CRITERE_NOT),
            })
          }
        >
          <Tooltip title={criterionLabel}>
            <span className={classes.label}>
              {criterionLabel}
            </span>
          </Tooltip>
          {criterion.istext && (
            <TooltipButton
              tag="iconButton"
              onClick={() => setEditable(!editable)}
              size="small"
              edge="start"
              title={t('results.criteria.edit')}
            >
              <Edit fontSize="small" />
            </TooltipButton>
          )}
          {(criterion.operateur === CRITERE_NOT) ? (
            <TooltipButton
              tag="iconButton"
              onClick={() => handleInclude(criterion)}
              size="small"
              edge="start"
              title={t('results.criteria.add')}
            >
              <AddCircle fontSize="small" />
            </TooltipButton>
          ) : (
            <TooltipButton
              tag="iconButton"
              onClick={() => handleExclude(criterion)}
              size="small"
              edge="start"
              title={t('results.criteria.remove')}
            >
              <RemoveCircle fontSize="small" />
            </TooltipButton>
          )}
          <TooltipButton
            tag="iconButton"
            onClick={() => handleRemove(criterion)}
            disabled={handleRemoveDisabled}
            size="small"
            edge="start"
            title={t('results.criteria.delete')}
          >
            <Cancel fontSize="small" />
          </TooltipButton>
        </Box>
      )}
    </Box>
  );
};

Criterion.propTypes = {
  handleExclude: PropTypes.func.isRequired,
  handleInclude: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleRemoveDisabled: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  criterion: PropTypes.shape({
    champ: PropTypes.number,
    istext: PropTypes.bool,
    libelle: PropTypes.string,
    libelleNombre: PropTypes.string,
    mouvementChamp: PropTypes.number,
    operateur: PropTypes.string,
    parentheseFermante: PropTypes.string,
    parentheseOuvrante: PropTypes.string,
    url: PropTypes.string,
    value: PropTypes.string,
  }),
};

Criterion.defaultProps = {
  criterion: {},
};

export default Criterion;
