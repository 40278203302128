import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Wysiwyg } from '@mui/icons-material';

import { qesdocumentPropType } from 'generic/core/qes/proptypes';
import { overrideRessource } from 'generic/utils/utils';
import TooltipButton from 'generic/components/ui/TooltipButton';

const { default: useDocumentFields } = overrideRessource('core/hooks/document', true);

const DocumentForWidgetList = ({
  qesdocument,
  handleDocumentClick,
  openFastGed,
}) => {
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  // OBLIGÉ DE FAIRE UN OBJECT INTERMÉDIAIRE DÉGUEUX POUR TENTER D'AVOIR UN DOCUMENT
  // TYPE QES COMME DANS LES AUTRES ENDPOINTS
  const {
    title, titleTruncate, text, files, applicabilite, numeroControle,
  } = useDocumentFields({
    base: +qesdocument.BASE_ID,
    qesdocument: { ...qesdocument },
  });

  let buttonColor = 'action';
  if (applicabilite === 'false') {
    buttonColor = 'error';
  } else if (text || !_.isEmpty(files)) {
    buttonColor = 'success';
  }

  return (
    <Box display="block" width="100%">
      <Box>
        <TooltipButton
          title={t('safran.dashboard.answer')}
          tag="fab"
          size="extraSmall"
          onClick={openFastGed}
          color={buttonColor}
          sx={{ mr: 0.5 }}
        >
          <Wysiwyg />
        </TooltipButton>

        <Typography
          component="span"
          fontSize="1rem"
          fontWeight="400"
          color="text.dark"
        >
          <Box
            component="span"
            sx={{ cursor: 'pointer' }}
            onClick={handleDocumentClick}
          >
            {`${numeroControle} - ${smallerThanLarge ? titleTruncate : title}`}
          </Box>
        </Typography>
      </Box>

      <Typography
        mt={0}
        mb={0.5}
        fontWeight="300"
        fontSize=".75rem"
        sx={{
          overflow: 'hidden',
          maxHeight: '6em',
          position: 'relative',
          '&:before': {
            content: '""',
            width: '100%',
            position: 'absolute',
            top: '0',
            bottom: '0',
            background: 'linear-gradient(transparent 20px, #f9f9f9)',
          },
        }}
      >
        {text}
      </Typography>

      {_.map(
        files,
        (file) => (
          <Link
            href={file.url}
            key={file.url}
            rel="noopener"
            target="_blank"
            underline="none"
            color="secondary"
          >
            <Typography
              variant="body2"
              mb={0.5}
              noWrap
            >
              {file.name}
            </Typography>
          </Link>
        ),
      )}
    </Box>
  );
};

DocumentForWidgetList.propTypes = {
  qesdocument: qesdocumentPropType,
  handleDocumentClick: PropTypes.func,
  openFastGed: PropTypes.func.isRequired,
};

DocumentForWidgetList.defaultProps = {
  qesdocument: {},
  handleDocumentClick: null,
};

export default DocumentForWidgetList;
