import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, Paper } from '@mui/material';

import GEDModalContainer from 'generic/containers/GEDModalContainer';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import ReportActions from 'custom/safran/components/ui/report/ReportActions';
import DisplayWarning from 'custom/safran/components/ui/report/DisplayWarning';
import { qesdocumentPropType } from 'custom/safran/core/qes/documentProptypes';

const Report = ({
  loading,
  hasMissingFiles,
  unansweredFiles,
  handleGenerate,
  handleTest,
  handleDocumentClick,
  openFastGed,
  refreshFunction,
}) => {
  const { t } = useTranslation();

  if (loading) return <GlobalLoading />;

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box flex="1 0 auto" maxHeight="90%">
        {
          hasMissingFiles
            ? (
              <DisplayWarning
                missingFiles={unansweredFiles}
                handleDocumentClick={handleDocumentClick}
                openFastGed={openFastGed}
              />
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                flex="1 0 100%"
                height="100%"
              >
                <Paper
                  sx={{
                    color: 'success.contrastText',
                    backgroundColor: 'success.light',
                    mt: 2,
                    p: 3,
                  }}
                >
                  <Trans>
                    {t('safran.report.all_ok_info')}
                  </Trans>
                </Paper>
              </Box>
            )
        }
      </Box>

      <Box flex="0 1 auto">
        <ReportActions
          hasMissingFiles={hasMissingFiles}
          handleGenerate={handleGenerate}
          handleTest={handleTest}
        />
      </Box>

      {hasMissingFiles && <GEDModalContainer afterSave={refreshFunction} />}
    </Container>
  );
};

Report.propTypes = {
  handleGenerate: PropTypes.func.isRequired,
  handleTest: PropTypes.func.isRequired,
  hasMissingFiles: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  refreshFunction: PropTypes.func.isRequired,
  unansweredFiles: PropTypes.arrayOf(qesdocumentPropType).isRequired,
  handleDocumentClick: PropTypes.func.isRequired,
  openFastGed: PropTypes.func.isRequired,
};

export default Report;
