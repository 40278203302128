import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Fab, Stack, Tooltip, Zoom,
} from '@mui/material';
import {
  AddShoppingCart,
  CloudDownloadOutlined,
  DeleteOutlined,
  Edit,
  FormatQuote,
  ForwardToInbox,
  GTranslate,
  MailOutlined,
  NotInterested,
  OpenInNew,
  Share,
} from '@mui/icons-material';
import { articlePropType } from 'generic/core/anr/proptypes';
import { CONSTANTS } from 'generic/core/constants';

const { LANG_MAPPING } = CONSTANTS;

const ArticleActions = React.memo(({
  article,
  elevation,
  handleCollect,
  handleGetSnippets,
  handleOpenAddToCartDialog,
  handleOpenAddToNewsletterDialog,
  handleOpenBanSiteDialog,
  handleOpenDeleteArticleDialog,
  handleOpenInGoogleTranslate,
  handleOpenInNewTab,
  handleOpenShareDialog,
  handleOpenSendToFriend,
  handleOpenGed,
  visible,
}) => {
  const { t } = useTranslation();
  const zoomProps = {
    in: visible,
    timeout: 150,
    unmountOnExit: true,
  };
  const fabProps = {
    size: 'extraSmall',
    color: 'secondary',
  };
  if (!elevation) {
    fabProps.sx = {
      boxShadow: 'none',
      '&:active': { boxShadow: 'none' },
    };
  }

  let coeff = 0;
  const msDelay = 35;
  return (
    <Stack
      spacing={0.5}
      direction="row"
    >
      {!article.validation && (
        <Tooltip
          title={t('anr.actions.collect')}
        >
          <Zoom
            {...zoomProps}
            style={{
              transitionDelay: `${coeff++ * msDelay}ms`,
            }}
          >
            <Fab
              {...fabProps}
              onClick={handleCollect}
            >
              <CloudDownloadOutlined />
            </Fab>
          </Zoom>
        </Tooltip>
      )}
      <Tooltip
        title={t('actions.add_to_cart')}
      >
        <Zoom
          {...zoomProps}
          style={{
            transitionDelay: `${coeff++ * msDelay}ms`,
          }}
        >
          <Fab
            {...fabProps}
            onClick={handleOpenAddToCartDialog}
          >
            <AddShoppingCart />
          </Fab>
        </Zoom>
      </Tooltip>
      <Tooltip
        title={t('actions.add_to_newsletter')}
      >
        <Zoom
          {...zoomProps}
          style={{
            transitionDelay: `${coeff++ * msDelay}ms`,
          }}
        >
          <Fab
            {...fabProps}
            onClick={handleOpenAddToNewsletterDialog}
          >
            <ForwardToInbox />
          </Fab>
        </Zoom>
      </Tooltip>
      <Tooltip
        title={t('actions.send_to_friend')}
      >
        <Zoom
          {...zoomProps}
          style={{
            transitionDelay: `${coeff++ * msDelay}ms`,
          }}
        >
          <Fab
            {...fabProps}
            onClick={handleOpenSendToFriend}
          >
            <MailOutlined />
          </Fab>
        </Zoom>
      </Tooltip>
      {article.validation && (
        <Tooltip
          title={t('actions.edit')}
        >
          <Zoom
            {...zoomProps}
            style={{
              transitionDelay: `${coeff++ * msDelay}ms`,
            }}
          >
            <Fab
              {...fabProps}
              onClick={handleOpenGed}
            >
              <Edit />
            </Fab>
          </Zoom>
        </Tooltip>
      )}
      {!article.is_quote && !article.is_mail && (
        <Fragment>
          <Tooltip
            title={t('anr.actions.show_snippets')}
          >
            <Zoom
              {...zoomProps}
              style={{
                transitionDelay: `${coeff++ * msDelay}ms`,
              }}
            >
              <Fab
                {...fabProps}
                onClick={handleGetSnippets}
              >
                <FormatQuote />
              </Fab>
            </Zoom>
          </Tooltip>
          <Tooltip
            title={t('anr.actions.ban')}
          >
            <Zoom
              {...zoomProps}
              style={{
                transitionDelay: `${coeff++ * msDelay}ms`,
              }}
            >
              <Fab
                {...fabProps}
                onClick={handleOpenBanSiteDialog}
              >
                <NotInterested />
              </Fab>
            </Zoom>
          </Tooltip>
          {article.lang !== LANG_MAPPING[_.get(window, 'QWAM.localeId', 1)] && (
            <Tooltip
              title={t('anr.actions.translate')}
            >
              <Zoom
                {...zoomProps}
                style={{
                  transitionDelay: `${coeff++ * msDelay}ms`,
                }}
              >
                <Fab
                  {...fabProps}
                  onClick={handleOpenInGoogleTranslate}
                >
                  <GTranslate />
                </Fab>
              </Zoom>
            </Tooltip>
          )}
        </Fragment>
      )}
      <Tooltip
        title={t('anr.actions.open_in_new_tab')}
      >
        <Zoom
          {...zoomProps}
          style={{
            transitionDelay: `${coeff++ * msDelay}ms`,
          }}
        >
          <Fab
            {...fabProps}
            onClick={handleOpenInNewTab}
          >
            <OpenInNew />
          </Fab>
        </Zoom>
      </Tooltip>
      <Tooltip
        title={t('anr.actions.share')}
      >
        <Zoom
          {...zoomProps}
          style={{
            transitionDelay: `${coeff++ * msDelay}ms`,
          }}
        >
          <Fab
            {...fabProps}
            onClick={handleOpenShareDialog}
          >
            <Share />
          </Fab>
        </Zoom>
      </Tooltip>
      <Tooltip
        title={t(article.is_quote ? 'anr.actions.delete_quote' : 'anr.actions.delete_article')}
      >
        <Zoom
          {...zoomProps}
          style={{
            transitionDelay: `${coeff++ * msDelay}ms`,
          }}
        >
          <Fab
            {...fabProps}
            onClick={handleOpenDeleteArticleDialog}
          >
            <DeleteOutlined />
          </Fab>
        </Zoom>
      </Tooltip>
    </Stack>
  );
});

ArticleActions.propTypes = {
  article: articlePropType.isRequired,
  elevation: PropTypes.bool,
  handleCollect: PropTypes.func.isRequired,
  handleGetSnippets: PropTypes.func.isRequired,
  handleOpenAddToCartDialog: PropTypes.func.isRequired,
  handleOpenAddToNewsletterDialog: PropTypes.func.isRequired,
  handleOpenBanSiteDialog: PropTypes.func.isRequired,
  handleOpenDeleteArticleDialog: PropTypes.func.isRequired,
  handleOpenInGoogleTranslate: PropTypes.func.isRequired,
  handleOpenInNewTab: PropTypes.func.isRequired,
  handleOpenShareDialog: PropTypes.func.isRequired,
  handleOpenSendToFriend: PropTypes.func.isRequired,
  handleOpenGed: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ArticleActions.defaultProps = {
  elevation: true,
  visible: false,
};

export default ArticleActions;
