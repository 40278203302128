const en = {
  en: {
    translation: {
      safran: {
        dashboard: {
          involved_function: 'Involved function',
          progression: 'Progression',
          answer: 'Answer',
          company: 'Company',
          companies: 'Companies',
          cancel_filter: 'Cancel filter',
          control_topics: 'Control topics',
          topics_and_scores: 'Topics / Scores',
          questions_count: 'Questions count',
          distribution_count: 'Distribution over time',
          applicability: 'Applicability',
          avg_scores_per_topic: 'Avg score per topics',
        },
        report: {
          actions: {
            generate: 'Generate report',
            test: 'Test report',
          },
          all_ok_info: 'All questions have been completed, you can now generate a report',
          warning_info: `
            <p>Some questions have not yet been completed.</p>
            <p>You will not be able to create the final report until all questions have been completed.</p>
          `,
          warning_list_files: 'See the list of questions to complete',
          error_getting_unanswered_filelist: 'Error while fetching unanswered questions',
        },
        applicable: 'Applicable',
        not_applicable: 'Not applicable',
      },
      header: {
        home: 'Home',
        my_space: 'My space',
        report: 'Report',
        global: 'Global View',
      },
      route: {
        app_name: 'ICA Safran',
        report: 'Generate report...',
      },
      ged: {
        document: {
          status: {
            1: 'To answer',
            50: 'To validate',
            70: 'To complete',
            100: 'Completed',
          },
          status_single: {
            1: 'To answer',
            50: 'To validate',
            70: 'To complete',
            100: 'Completed',
          },
        },
      },
    },
  },
};

export default en;
