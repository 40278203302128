import _ from 'lodash';
import { CUSTOM_PATH } from 'generic/core/constants';
import countries from 'generic/locales/countries-fr.json';

let fr = {
  fr: {
    translation: {
      commons: {
        months: {
          january: 'Janvier',
          february: 'Février',
          march: 'Mars',
          april: 'Avril',
          may: 'Mai',
          june: 'Juin',
          july: 'Juillet',
          august: 'Août',
          september: 'Septembre',
          october: 'Octobre',
          november: 'Novembre',
          december: 'Décembre',
        },
        short_months: {
          jan: 'Jan.',
          feb: 'Fev.',
          mar: 'Mar.',
          apr: 'Avr.',
          may: 'Mai',
          jun: 'Juin',
          jul: 'Juil.',
          aug: 'Aou.',
          sep: 'Sep.',
          oct: 'Oct.',
          nov: 'Nov.',
          dec: 'Déc.',
        },
        weekdays: {
          sunday: 'Dimanche',
          monday: 'Lundi',
          tuesday: 'Mardi',
          wednesday: 'Mercredi',
          thursday: 'Jeudi',
          friday: 'Vendredi',
          saturday: 'Samedi',
        },
        periods: {
          today: 'Aujourd\'hui',
          yesterday: 'Hier',
          this_week: 'Cette semaine',
          last_week: 'La semaine dernière',
          last_7_days: 'Les 7 derniers jours',
          this_month: 'Ce mois-ci',
          last_month: 'Le mois dernier',
          last_30_days: 'Les 30 derniers jours',
          this_year: 'Cette année',
          last_year: 'L\'année dernière',
          last_12_months: 'Les 12 derniers mois',
        },
      },
      login: {
        welcome: 'Bienvenue',
        log_in: 'Connectez-vous',
        to_login: 'Se connecter',
        login: 'Identifiant',
        password: 'Mot de passe',
        fail_auth: 'Votre identifiant ou votre mot de passe est incorrect, merci de réessayer',
        error_unauthorized: 'Accès refusé ou votre session a expiré',
      },
      header: {
        web_tracking: 'Veille Web',
        advanced_search: 'Recherche avancée',
        number_criteria_one: ' ({{count}} critère)',
        number_criteria_other: ' ({{count}} critères)',
        qes: 'QES',
        carts: 'Paniers',
        newsletters: 'Newsletters',
        alerts: 'Alertes',
        dashboard: 'Dataviz',
        results: 'Résultats',
      },
      route: {
        results: 'Résultats',
        article: 'Article',
        dashboard: 'Dataviz',
        anr: 'Veille Web',
        carts: 'Paniers',
        newsletters: 'Newsletters',
        alerts: 'Alertes',
        users: 'Utilisateurs',
        home: 'Accueil',
        app_name: 'Infomedia Suite',
      },
      form: {
        edit: 'Modifier',
        edit_fast: 'Modification rapide',
        search: 'Rechercher',
        delete: 'Supprimer',
        save: 'Enregistrer',
        clear: 'Vider',
        cancel: 'Annuler',
        reset: 'Réinitialiser',
        title: 'Titre',
        test: 'Tester',
        send: 'Envoyer',
        upload: 'Télécharger',
        unspecified: 'Non précisé',
        upload_placeholder: 'Choisir le(s) fichier(s) à télécharger',
        omni_placeholder: 'Rechercher dans la {{activeBaseLabel}}',
        default_base_label: 'Base de connaissance',
        add_new_field: 'Ajouter un autre champ',
        errors: {
          error_from_test: 'Erreur lors du test des paramètres',
          mandatory: 'Champ obligatoire',
          must_be_integer: 'La valeur doit être un nombre entier',
          must_be_more_than: 'La valeur doit être supérieure à',
          must_be_less_than: 'La valeur doit être inférieure à',
          must_select_at_least_one_lang: 'Sélectionnez au moins une langue',
          invalid_date: 'Format de date invalide',
          invalid_hour: 'Format d\'heure invalide',
          no_from_after_to: 'La date de début doit être antérieure à la date de fin',
          no_past_dates: 'Les dates dans le passé ne sont pas autorisées',
          no_future_dates: 'Les dates dans le futur ne sont pas autorisées',
          min_date_message: 'Merci de saisir une date supérieure à',
          max_date_message: 'Merci de saisir une date inférieure à',
          not_valid_email: 'Merci de saisir un mail valide',
        },
        datepicker: {
          from: '(du)',
          to: '(au)',
          placeholder: 'jj/mm/aaaa',
        },
        checkbox: {
          and: 'Et',
          all: 'Tout sélectionner / désélectionner',
        },
        create_new_value: 'Créer une nouvelle valeur',
      },
      config: {
        base: 'Base',
        error_fetching_config: 'Erreur lors de la récupération de la configuration du formulaire',
        error_loading_base: 'Erreur lors du chargement de la base',
      },
      dashboard: {
        countries,
        error_fetching_dashboard: 'Erreur lors de la récupération du dashboard',
        error_fetching_dashboard_widget: 'Récupération des datas de "{{widgetId}}" impossible',
        feeling_values: {
          '-4': 'extrêmement négatif',
          '-3': 'très négatif',
          '-2': 'négatif',
          '-1': 'mal vu',
          0: 'neutre',
          1: 'bien vu',
          2: 'positif',
          3: 'très positif',
          4: 'extrêmement positif',
        },
        root: 'Racine',
        widget: {
          publication_date: 'Date de publication',
        },
        context_button_title: 'Export',
        download_csv: 'Télécharger au format CSV',
        download_jpeg: 'Télécharger au format JPEG',
        download_pdf: 'Télécharger au format PDF',
        download_png: 'Télécharger au format PNG',
        download_svg: 'Télécharger au format SVG',
        download_xls: 'Télécharger au format XLS',
        exit_full_screen: 'Quitter le mode plein écran',
        print_chart: 'Imprimer le graphe',
        view_full_screen: 'Afficher en plein écran',
        view_data: 'Afficher les données',
      },
      dialog: {
        yes: 'Oui',
        cancel: 'Annuler',
        close: 'Fermer',
      },
      ui: {
        back: 'Retour',
        back_to_results: 'Retour aux résultats',
        show_in_results: 'Voir dans les résultats',
        show_in_dashboard: 'Voir dans la dataviz',
      },
      results: {
        criteria: {
          edit: 'Editer le critère',
          noedit: 'Fermer l\'édition du critère',
          add: 'Avec ce critère',
          remove: 'Sauf ce critère',
          delete: 'Supprimer ce critère',
          search: 'Rechercher',
          reset: 'Réinitialiser',
          period: 'Chercher sur une période',
        },
        pagination: {
          on: 'sur',
          display_rows: 'Afficher :',
        },
        checkbox: {
          all: 'Tout sélectionner',
        },
        facets: {
          refine: 'Raffiner',
          show_facets: 'Afficher les facettes',
          show_more: 'voir plus',
          show_less: 'voir moins',
        },
        no_results: 'Aucun résultat',
        no_search_results: 'La recherche ne renvoie aucun résultat',
        no_search_results_create_alert: `La recherche ne renvoie aucun résultat, vous pouvez créer
          une alerte pour recevoir la nouveauté en cliquant sur le bouton`,
        please_make_a_search: 'Veuillez renseigner au moins un critère de recherche',
        article_image: 'Illustration de l\'article',
        img_not_found: 'Image non trouvée',
        error_fetching_results: 'Erreur lors de la récupération des résultats',
        total: 'total',
        sort: 'Tri :',
        document_one: 'Document',
        document_other: 'Documents',
        comments: {
          comment: 'Commenter',
          hide_comments: 'Masquer les commentaires',
          show_comments_one: 'Voir le commentaire',
          show_comments_other: 'Voir les {{count}} commentaires',
          write_comment: 'Ecrire un commentaire',
          delete: 'Supprimer ce commentaire',
          deleted: 'Le commentaire a été supprimé',
          created: 'Le commentaire a été créé',
          be_alerted: 'Soyez alerté si un nouveau commentaire est ajouté',
          post: 'post',
          alert_updated: 'Alerte modifiée',
          be_alerted_disabled: `Les alertes sur les commentaires ne sont possibles
                               qu'après avoir commenté vous-même la ressource.`,
          error_deleting_comment: 'Erreur lors de la suppression du commentaire',
          error_fetching_comments: 'Erreur lors de la récupération des commentaires',
          error_submiting_comment: 'Erreur lors de la soumission du commentaire',
          error_updating_comment_alert: 'Erreur lors de la mise à jour de l\'alerte du commentaire',
        },
        tags: {
          more: 'Voir plus de tags',
          less: 'Voir moins de tags',
        },
        morelikethis: {
          see_also_label: 'Voir aussi :',
          show_see_also: 'Voir aussi',
          hide_see_also: 'Masquer le "voir aussi"',
          error_fetching_morelikethis: 'Erreur lors de la récupération d\'autres résultats',
        },
        close_window: 'Fermer la fenêtre',
      },
      document: {
        title_undefined: 'titre absent',
      },
      selection: {
        your_selection: 'Votre sélection',
        show: 'Voir la sélection',
        remove: 'Retirer de la sélection',
        clear: 'Vider la sélection',
        no_selection: 'La sélection est vide',
      },
      carts: {
        choose: 'Sélectionnez un panier',
        list: 'Liste des paniers',
        back_to_list: 'Retour à la liste des paniers',
        no_carts: 'Aucun panier',
        error_fetching_carts: 'Erreur lors de la récupération des paniers',
        error_fetching_cart: 'Erreur lors de la récupération du panier',
        name: 'Nom',
        create: 'Créer',
        create_and_add: 'Créer et ajouter',
        create_new: 'Créer un nouveau panier',
        create_new_and_add: 'Créer un nouveau panier avec les documents',
        add: 'Ajouter',
        add_here: 'Ajouter les documents dans ce panier',
        name_of_new: 'Nom du nouveau panier',
        pick_from_list: 'Choisir un panier dans la liste',
        date_of_creation: 'Date de création',
        visibility: 'Visibilité',
        visibility_values: {
          1000: 'Privé',
          1: 'Collaboratif',
          2: 'Société',
          3: 'Public',
          4: 'Profile',
        },
        image: 'Image',
        owner: 'Propriétaire',
        contributors: 'Contributeurs',
        number_of_documents: 'Nombre de documents',
        delete: 'Suppression',
        delete_carts_one: 'Supprimer {{count}} panier',
        delete_carts_other: 'Supprimer {{count}} paniers',
        carts_deleted_one: 'Panier supprimé',
        carts_deleted_other: '{{count}} paniers supprimés',
        error_deleting_carts_one: 'Erreur lors de la suppression du panier',
        error_deleting_carts_other: 'Erreur lors de la suppression des {{count}} paniers',
        no_document: 'Aucun document',
        delete_cart_documents_one: 'Supprimer {{count}} document ?',
        delete_cart_documents_other: 'Supprimer {{count}} documents ?',
        cart_documents_deleted_one: 'Document supprimé',
        cart_documents_deleted_other: '{{count}} documents supprimés',
        error_deleting_cart_documents_one: 'Erreur lors de la suppression du document',
        error_deleting_cart_documents_other: 'Erreur lors de la suppression des {{count}} documents',
        cart_updated: 'Panier modifié',
        error_updating_cart: 'Erreur lors de la modification du panier',
        docs_added_to_cart_one: '{{count}} document ajouté au panier',
        docs_added_to_cart_other: '{{count}} documents ajoutés au panier',
        error_adding_to_cart: "Erreur à l'ajout dans le panier",
        send_cart_documents_one: 'Envoyer le panier avec le document coché',
        send_cart_documents_other: 'Envoyer le panier avec les documents cochés',
      },
      newsletters: {
        choose: 'Sélectionnez une newsletter',
        list: 'Liste des newsletters',
        back_to_list: 'Retour à la liste des newsletters',
        no_newsletters: 'Aucune newsletter',
        error_fetching_newsletters: 'Erreur lors de la récupération des newsletters',
        error_fetching_themes: 'Erreur lors de la récupération des thèmes',
        error_fetching_newsletter: 'Erreur lors de la récupération de la newsletter',
        name: 'Nom',
        create: 'Créer',
        create_and_add: 'Créer et ajouter',
        create_new: 'Créer une nouvelle newsletter',
        create_new_and_add: 'Créer un nouvelle newsletter avec les documents',
        add: 'Ajouter',
        add_here: 'Ajouter les documents dans cette newsletter',
        name_of_new: 'Nom de la nouvelle newsletter',
        pick_from_list: 'Choisir une newsletter dans la liste',
        date_of_creation: 'Date de création',
        visibility: 'Visibilité',
        visibility_values: {
          1000: 'Privé',
          1: 'Collaboratif',
          2: 'Société',
          3: 'Public',
          4: 'Profile',
        },
        image: 'Image',
        owner: 'Propriétaire',
        contributors: 'Contributeurs',
        theme: 'Thème',
        themes: 'Thèmes',
        manual_mode: 'Mode manuel',
        topics: 'Rubriques: ',
        topic: 'Rubrique',
        order: 'Ordre',
        doc_edit: 'Éditer le document',
        sending: 'Envoi',
        manual_sending: 'Envoi manuel',
        at: 'à',
        recipients_one: 'Destinataire',
        recipients_other: 'Destinataires',
        number_of_documents: 'Nombre de documents',
        delete: 'Suppression',
        delete_newsletters_one: 'Supprimer {{count}} newsletter ?',
        delete_newsletters_other: 'Supprimer {{count}} newsletters ?',
        newsletters_deleted_one: 'Newsletter supprimée',
        newsletters_deleted_other: '{{count}} newsletters supprimées',
        error_deleting_newsletters_one: 'Erreur lors de la suppression de la newsletter',
        error_deleting_newsletters_other: 'Erreur lors de la suppression des {{count}} newsletters',
        no_document: 'Aucun document',
        delete_newsletter_documents_one: 'Supprimer {{count}} document ?',
        delete_newsletter_documents_other: 'Supprimer {{count}} documents ?',
        newsletter_documents_deleted_one: 'Document supprimé',
        newsletter_documents_deleted_other: '{{count}} documents supprimés',
        error_deleting_newsletter_documents_one: 'Erreur lors de la suppression du document',
        error_deleting_newsletter_documents_other: 'Erreur lors de la suppression des {{count}} documents',
        newsletter_updated: 'Newsletter modifiée',
        error_updating_newsletter: 'Erreur lors de la modification de la newsletter',
        docs_added_to_newsletter_one: '{{count}} document ajouté à la newsletter',
        docs_added_to_newsletter_other: '{{count}} documents ajoutés à la newsletter',
        error_adding_to_newsletter: "Erreur à l'ajout dans la newsletter",
        scheduled_sending: {
          title: 'Envoi programmé',
          hours: 'heures',
          days: 'Jours\u00A0:',
          morning: 'Matin\u00A0:',
          afternoon: 'Après-midi\u00A0:',
        },
        send_and_wipe_confirmation_title: 'Envoi de la newsletter',
        send_and_wipe_confirmation: `
          Vous vous apprêtez à envoyer cette newsletter, tous ses documents seront détachés après envoi.
          Êtes-vous sûr de vouloir faire l'envoi ?
        `,
        send_and_wipe_documents: 'Envoyer et vider',
        send_success: 'Envoi effectué',
        send_error: 'Erreur lors de l\'envoi',
        send_test: 'Envoi de test',
        send_newsletter_documents_one: 'Envoyer le panier avec le document coché',
        send_newsletter_documents_other: 'Envoyer le panier avec les documents cochés',
        sent: 'Envoyé',
        sending_date: 'Date d\'envoi',
        wiped: 'Vidée',
        historic_list: 'Historique des envois',
        actions: 'Actions',
        show_sent: 'Afficher le mail envoyé',
        resend: 'Ré-envoyer',
        resend_confirmation: `
          Vous vous apprêter à ré-envoyer cette newsletter.
          Êtes-vous sûr de vouloir refaire l'envoi ?
        `,
        edit: {
          details: 'Détail',
          documents: 'Documents',
          visualize: 'Visualiser',
          historic: 'Historique',
          topics: 'Rubriques',
          create_topic: 'Créer une rubrique',
          manage_topics: 'Gestion des rubriques',
          reorganizeNaturalOrder: 'Grouper par rubrique et trier les articles',
        },
      },
      ged: {
        error_uploading_file: 'Erreur au téléchargement du fichier',
        error_fetching_document: 'Erreur lors de la récupération du document',
        uploading_file_success: 'Le document "{{file}}" a bien été téléchargé',
        save_document_error: 'Erreur lors de la sauvegarde du document',
        save_document_success: 'Document enregistré',
        delete_documents_one: 'Supprimer le document ?',
        delete_documents_other: 'Supprimer les {{count}} documents ?',
        delete_documents_success_one: 'Le document a bien été supprimé',
        delete_documents_success_other: 'Les {{count}} documents ont bien été supprimés',
        delete_documents_error: 'Une erreur est survenue',
        update_status_to_one: 'Modifier l\'état du document à "{{status}}" ?',
        update_status_to_other: 'Modifier l\'état des {{count}} documents à "{{status}}" ?',
        update_status_documents_success_one: 'L\'état a bien été modifié pour le document',
        update_status_documents_success_other: 'L\'état a bien été modifié pour les {{count}} documents',
        update_all_status_to: 'Passer tout à "{{status}}" ?',
        prev_doc: 'Document précédent',
        next_doc: 'Document suivant',
        warning_concurrent_access: `Vous ne pouvez pas mettre à jour ce document car
          l'utilisateur "{{user}}" l'a déjà ouvert pour le modifier.`,
        document: {
          status: {
            1: 'Nouveau',
            2: 'Doublon',
            3: 'Rejeté',
            4: 'Collecté',
            5: 'Erreur',
            50: 'Validé',
            100: 'Publié',
            200: 'Super utilisateur',
            203: 'Supprimé',
            204: 'Lié',
            205: 'Fusionné',
            206: 'Commenté',
            207: 'Dupliqué',
            220: 'Classé',
            230: 'Dédoublonné',
            240: 'Favori',
            1000: 'Non précisé',
            1001: 'À compléter',
          },
        },
      },
      actions: {
        actions: 'Actions',
        add_to_cart: 'Ajouter au panier',
        add_to_newsletter: 'Ajouter à la newsletter',
        create_alert: 'Créer une alerte',
        create: 'Créer',
        send_to_friend: 'Envoyer par mail',
        edit: 'Modifier',
        edition: 'Édition',
        comment: 'Commenter',
        open_in_new_tab: 'Ouvrir dans un nouvel onglet',
        view_attachment: 'Voir la pièce jointe',
        delete: 'Supprimer',
        send: 'Envoyer',
        update_status: 'Modifier l\'état',
        export: 'Exporter',
      },
      alerts: {
        list: 'Liste des alertes',
        no_alerts: 'Aucune alerte',
        error_fetching_alerts: 'Erreur lors de la récupération des alertes',
        details: 'Détails',
        criteria: 'Critères',
        name: 'Nom',
        type: 'Type',
        date_of_creation: 'Date de création',
        type_values: {
          1: 'Alerte',
          6: 'Requête',
        },
        date_validity: 'Date de validité',
        periodicity: 'Périodicité',
        mode_values: {
          1: 'Quotidienne',
          2: 'Hebdomadaire',
          3: 'Mensuelle',
          15: 'planifiée',
          20: 'Toutes les 4 heures',
          30: 'Toutes les 2 heures',
          40: 'Toutes les heures',
          50: 'Toutes les 30 minutes',
          60: 'Toutes les 15 minutes',
          70: 'Toutes les 5 minutes',
          80: 'Toutes les minutes',
        },
        email: 'Email',
        format_values: {
          1: 'Sommaire, documents en ligne',
          2: 'Documents',
          1000: 'Notification par email, documents en ligne',
        },
        format_reception: 'Format de réception',
        frequence: 'Fréquence',
        date_of_execution: 'Exécution',
        number_of_documents: 'Nombre de documents',
        date_of_visualisation: 'Visualisation',
        date_of_next_execution: 'Prochaine exécution',
        delete: 'Suppression',
        delete_alerts_one: 'Supprimer {{count}} alerte',
        delete_alerts_other: 'Supprimer {{count}} alertes',
        alerts_deleted_one: 'Alerte supprimée',
        alerts_deleted_other: '{{count}} alertes supprimées',
        error_deleting_alerts_one: 'Erreur lors de la suppression de l\'alerte',
        error_deleting_alerts_other: 'Erreur lors de la suppression des {{count}} alertes',
        active: 'actives',
        inactive: 'inactives',
        activate: 'Activer',
        deactivate: 'Désactiver',
        search: 'Rechercher avec les critères de l\'alerte',
        alerts_activate_one: 'L\'alerte a été activée',
        alerts_activate_other: 'Les {{count}} alertes ont été activées',
        alerts_deactivate_one: 'L\'alerte a été désactivée',
        alerts_deactivate_other: 'Les {{count}} alertes ont été désactivées',
        alert_updated: 'L\'alerte a été modifiée',
        error_updating_alert: 'Erreur lors de la modification de l\'alerte',
        alert_created: 'L\'alerte "{{alert}}" a été créée',
        error_creating_alert: 'Erreur lors de la création de l\'alerte',
        alert_type_alert: 'Alerte',
        alert_type_request: 'Requête sauvegardée',
        error_fetching_form: 'Erreur lors de la récupération des critères de recherche',
      },
      send_to_friend: {
        send_to: 'Envoyer à',
        recipients: 'Destinataires',
        to: 'À',
        cc: 'Cc',
        bcc: 'Cci',
        subject: 'Objet',
        error_building_dialog: 'Erreur lors de la préparation de l\'envoi',
        error_sending: 'Erreur à l\'envoi des documents',
        docs_sent_one: 'Document envoyé',
        docs_sent_other: '{{count}} documents envoyés',
        display_cc_field: 'Ajouter des destinataires en Cc',
        display_bcc_field: 'Ajouter des destinataires en Cci',
        comment: 'Commentaire',
        attachment: 'Pièce jointe',
      },
      export: {
        select_format: 'Selectionner le format',
        selection: 'Sélection',
        massive: 'Massif',
        number_to_export_sel: 'Nombre de documents à exporter',
        number_to_export: 'Nombre de documents à exporter (max. {{max}})',
        number_exported_docs_one: 'Exporter un document',
        number_exported_docs_other: 'Export {{count}} documents',
        select_fields: 'Selectionner les champs à exporter',
        error_exporting: 'Erreur à l\'export des documents',
        docs_exported_one: 'Document exporté',
        docs_exported_other: '{{count}} documents exportés',
        massive_export_conf: 'L\'export que vous avez demandé va vous être envoyé par email dans quelques minutes',
      },
      users: {
        civilities: {
          mrs: 'Madame',
          miss: 'Mademoiselle',
          mr: 'Monsieur',
          unknown: 'Non précisé',
        },
        civility: 'Civilité',
        firstname: 'Prénom',
        lastname: 'Nom',
        list: 'Liste des utilisateurs',
        user: 'Utilisateur',
        logon: 'Identifiant',
        email: 'Mail',
        profile: 'Profil',
        opened: 'ouverts',
        closed: 'fermés',
        open: 'Ouvrir',
        close: 'Fermer',
        edit: 'Modifier',
        edit_title: 'Modification de l\'utilisateur :',
        create_title: 'Création d\'un nouvel utilisateur',
        users_opened_one: 'L\'utilisateur a été ouvert',
        users_opened_other: 'Les {{count}} utilsateurs ont été ouverts',
        users_closed_one: 'L\'utilisateur a été fermé',
        users_closed_other: 'Les {{count}} utilisateurs ont été fermés',
        no_users: 'Aucun utilisateur',
        error_fetching_users: 'Erreur lors de la récupération des utilisateurs',
        error_updating_user: 'Erreur lors de la modification de l\'utilisateur',
        user_saved: 'Utilisateur enregistré',
        user_saved_error: 'Erreur lors de l\'enregistrement de l\'utilisateur',
      },
      anr: {
        opened_in_new_tab: 'Cet article a été ouvert dans un nouvel onglet',
        show_folders: 'Afficher les dossiers',
        news: 'actualités',
        news_one: 'actualité',
        news_other: 'actualités',
        quotes: 'citations',
        quotes_one: 'citation',
        quotes_other: 'citations',
        copy: 'Copie',
        new: 'Nouveau',
        new_folder: 'Nouveau dossier',
        delete_folder: 'Suppression de dossier',
        delete_folder_sure: 'Etes-vous sûr de vouloir supprimer le dossier',
        folder_deleted: 'Dossier supprimé',
        create_in_branch: 'Créér un dossier dans cette branche',
        duplicate: 'Dupliquer',
        too_much_folders: 'Vous ne pouvez pas créér de dossier supplémentaire',
        glimpse: 'Œil sur l\'actualité',
        favorite: 'Favori',
        favorites: 'Favoris',
        add_favorite: 'Ajouter aux favoris',
        remove_favorite: 'Retirer des favoris',
        no_query: 'Ce dossier n\'a pas de requête',
        go_to_last_validated: 'Aller à la dernière info validée',
        expert_mode: 'Mode expert',
        query: 'Requête',
        text_query: 'Requête full-text',
        query_at_least: 'Les pages doivent contenir AU MOINS UN des mots suivants',
        query_every: 'Les pages doivent contenir TOUS les mots suivants',
        query_banned: 'Les pages ne doivent contenir AUCUN des mots suivants',
        quotes_query: 'Requête sur les réseaux sociaux',
        partner: 'Bouquet',
        partners: 'Bouquets',
        category: 'Rubrique thématique',
        categories: 'Rubriques thématiques',
        not_categories: 'Rubriques refusées',
        query_twitter: 'Requête Twitter',
        query_twitter_help: `Format des requêtes Twitter :<br/>
          ET : <i>mot1 mot2</i><br/>
          OU : <i>mot1 OR mot2</i><br/>
          Combinaison ET et OU : <i>mot1 mot2 OR mot3</i><br/>
          Expression exacte : <i>"mot1 mot2 mot3"</i><br/>
          Exclusion : <i>-mot</i><br/>
          Hashtag : <i>#mot</i><br/>
          Envoyé par : <i>from:pseudo</i><br/>
          Envoyé à : <i>to:pseudo</i><br/>
          Référence à : <i>@pseudo</i>`,
        query_linkedin: 'Requête LinkedIn',
        query_linkedin_help: `Format des requêtes LinkedIn :<br/>
          ET : <i>mot1 AND mot2</i><br/>
          OU : <i>mot1 OR mot2</i><br/>
          Expression exacte : <i>"mot1 mot2"</i><br/>
          Exclusion : <i>-mot</i>`,
        query_youtube: 'Requête YouTube',
        query_youtube_help: `Format des requêtes YouTube :<br/>
          ET : <i>mot1 mot2</i><br/>
          OU : <i>mot1 | mot2</i><br/>
          Expression exacte : <i>"mot1 mot2"</i><br/>
          Exclusion : <i>-mot</i>`,
        query_dailymotion: 'Requête Dailymotion',
        query_dailymotion_help: `Format des requêtes Dailymotion :<br/>
          ET : <i>"mot1 mot2"</i><br/>
          OU : <i>mot1 mot2</i>`,
        query_twitter_min_followers: 'Nombre de followers minimum',
        filter_links_only: 'Filtrer les citations avec lien uniquement',
        show_in_glimpse: "Mettre ce dossier dans l'Œil sur l'actualité",
        focus_in_glimpse: "Mettre en avant les infos dans l'Œil sur l'actualité",
        lang: 'langue',
        langs: 'langues',
        no_articles_from_test: 'Aucun article ne correspond aux paramètres',
        articles_from_test: 'articles correspondent aux paramètres',
        other_options: 'Autres options',
        error_fetching_folders: 'Erreur lors de la récupération des dossiers',
        error_fetching_folder: 'Erreur lors de la récupération du dossier',
        error_user_fetch: "Erreur lors de la récupération de l'utilisateur Ask'n'Read",
        error_fetching_news: 'Erreur lors de la récupération des actualités',
        error_fetching_quotes: 'Erreur lors de la récupération des citations',
        error_fetching_sources: 'Could not fetch sources',
        error_saving_folder: "Erreur lors de l'enregistrement du dossier",
        error_deleting_folder: 'Erreur lors de la suppression du dossier',
        error_bookmarking: "Erreur lors de l'enregistrement du favori",
        warning_unreachable_validated_article: "L'info validée a été supprimée ou n'est plus dans la liste",
        folder_created: 'Dossier créé',
        folder_updated: 'Dossier mis à jour',
        cant_open_article_message: `L'article ne peut être ouvert dans cette fenêtre,
          vous pouvez l'ouvrir dans un nouvel onglet en cliquant sur le bouton`,
        cant_open_dark_web_article_message: `Cet article provient du <strong>dark web</strong> et ne peut être ouvert
          dans cette fenêtre. Si vous êtes sur un navigateur <strong>Tor</strong> ou équivalent, vous pouvez l'ouvrir
          dans un nouvel onglet en cliquant sur le bouton`,
        or_copy_link_message: 'Ou alors, vous pouvez copier le lien en cliquant sur',
        cant_open_article_tip: `Astuce : vous pouvez ouvrir directement l'article dans
          un nouvel onglet depuis une liste d'infos en faisant un <strong>Ctrl + clic</strong> sur son titre.`,
        open_quote_link: 'Voir cette citation sur le réseau social',
        no_url_for_quote: "Aucune actualité n'a été partagée dans cette citation",
        cant_produce_snippets: `Désolé, les paramètres de la recherche ne permettent pas
        d'expliquer en détail la remontée de l'info…`,
        error_from_get_snippets: 'Erreur lors de la récupération des snippets',
        retweeted_by: 'Retweeté par',
        sources: 'Sources',
        site: 'Site',
        url: 'Adresse',
        type: 'Type',
        articles_count: 'Infos',
        address: 'Adresse du site ou de la page à surveiller',
        comment: 'Commentaire',
        folders_for_source: `Vous pouvez préciser le ou les dossier(s) dans le(s)quel(s)
          cette source doit remonter des infos`,
        partners_for_source: `Vous pouvez également préciser le ou les bouquet(s) dans
          le(s)quel(s) cette source doit être intégrée`,
        categories_for_source: `Vous pouvez également préciser la ou les rubrique(s)
          thématique(s) dans la(es)quelle(s) cette source doit être intégrée`,
        demand_source: "Demande d'ajout de source",
        demand_new_source_ok: "Demande d'ajout de source envoyée",
        demand_new_source_error: "Erreur lors de la demande d'ajout de source",
        unban: 'Débannir',
        unban_site: "Débannissement d'un site",
        unban_site_sure: 'Êtes-vous sûr de vouloir débannir',
        unbanned_site: 'Le site a été débanni',
        unbanning_site_error: 'Erreur lors de la demande de débannissement',
        name: 'Nom',
        action: 'Action',
        banned_sites: 'Sites bannis',
        ban_site: 'Bannissement de site',
        ban_site_sure: 'Êtes-vous sûr de vouloir bannir la source',
        ban_reason_label: 'Pour améliorer le service, vous pouvez indiquer la raison du bannissement',
        banned_site: 'Le site a été banni, le bannissement sera effectif dans quelques instants',
        banning_site_error: 'Erreur lors de la demande de bannissement',
        item_one: 'info',
        item_other: 'infos',
        delete_articles_error: "Erreur lors de la suppression d'articles",
        delete_articles_ok_one: 'Info supprimée',
        delete_articles_ok_other: 'Infos supprimées',
        actions: {
          ban: 'Bannir',
          collect_done: 'Collecte réalisée avec succès',
          collect: 'Collecter',
          delete: 'Supprimer',
          delete_article: "Supprimer l'article",
          delete_quote: 'Supprimer la citation',
          delete_sure: 'Êtes-vous sûr de vouloir supprimer',
          error_collecting: 'Erreur lors de la collecte',
          link_copied: 'Lien copié',
          link_copy: 'Copier le lien',
          open_in_new_tab: 'Ouvrir dans une nouvelle fenêtre',
          share_facebook: 'Partager sur Facebook',
          share_linkedin: 'Partager sur LinkedIn',
          share_of: 'Partage de',
          share_twitter: 'Partager sur Twitter',
          share_whatsapp: 'Partager sur WhatsApp',
          share: 'Partager',
          show_snippets: 'Voir ce qui a matché',
          translate: 'Ouvrir la version traduite',
        },
      },
      account_menu: {
        signed_in_as: 'Connecté en tant que {{ logon }}',
        signed_in_as_female: 'Connectée en tant que {{ logon }}',
        logout: 'Se déconnecter',
        profil: 'Mon profil',
        settings: 'Préférences',
        sources: 'Mes sources',
      },
      datatable: {
        loading: 'Chargement de la liste',
        textLabels: {
          body: {
            noMatch: 'Désolé, aucune entrée ne correspond',
            toolTip: 'Trier',
          },
          pagination: {
            next: 'Page suivante',
            previous: 'Page précédente',
            rowsPerPage: 'Lignes par page :',
            displayRows: 'de',
            jumpToPage: 'Aller à la page',
          },
          toolbar: {
            search: 'Rechercher',
            downloadCsv: 'Télécharger le CSV',
            print: 'Imprimer',
            viewColumns: 'Voir les colonnes',
            filterTable: 'Filtrer la table',
          },
          filter: {
            all: 'Tous',
            title: 'FILTRES',
            reset: 'RESET',
          },
          viewColumns: {
            title: 'Voir les colonnes',
            titleAria: 'Montrer/Cacher les colonnes',
          },
          selectedRows: {
            text: 'ligne(s) sélectionnée(s)',
            delete: 'Supprimer',
            deleteAria: 'Supprimer les lignes sélectionnées',
          },
        },
      },
    },
  },
};

if (CUSTOM_PATH) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: enCustom } = require(`custom/${CUSTOM_PATH}/locales/fr`);
    fr = _.merge(fr, enCustom);
  } catch (err) {
    console.info('Pas de fichier custom fr');
  }
}

const finalFr = fr;
export default finalFr;
