import {
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';
import {
  types,
  buildSendToFriendSuccess,
  buildSendToFriendError,
  submitSendToFriendSuccess,
  submitSendToFriendError,
} from 'generic/core/sendToFriend/actions';
import { buildSendToFriend, submitSendToFriend } from 'generic/api/send';
import { snackActions } from 'generic/utils/snackbar';
import { closeDialogSendToFriend } from 'generic/core/actions/actions';
import { clearSelection, clearSingleItem } from 'generic/core/selection/actions';
import { workAnRBuildSendToFriend, workAnRSendToFriend } from 'generic/core/anr/sagas';

function* workBuildSendToFriend({ scope, options }) {
  if (scope === 'anr') {
    yield call(workAnRBuildSendToFriend);
  } else {
    try {
      const singleItem = yield select((state) => state.selection.singleItem);

      let documentitemIds;
      if (scope === 'cart' && options.cartId !== 1000) {
        documentitemIds = options.idDocs;
      } else if (singleItem) {
        documentitemIds = [singleItem.documentitem];
      } else {
        const checkedItems = yield select((state) => state.selection.checkedItems);
        documentitemIds = _.map(
          checkedItems,
          (item) => item.documentitem,
        );
      }
      let sendToFriend;
      if (scope === 'cart' && options.cartId !== 1000) {
        sendToFriend = yield call(buildSendToFriend, {
          uriParams: {
            panier: options.cartId,
            documentitem: documentitemIds,
          },
        });
      } else {
        const { mouvement } = yield select((state) => state.search.results);
        sendToFriend = yield call(buildSendToFriend, {
          uriParams: {
            mouvement,
            documentitem: documentitemIds,
          },
        });
      }
      yield put(buildSendToFriendSuccess(sendToFriend));
    } catch (error) {
      yield put(buildSendToFriendError(error));
      console.error(error);
      snackActions.error(i18next.t('send_to_friend.error_building_dialog'));
    }
  }
}

function* watchBuildSendToFriend() {
  yield takeLatest(types.SEND_TO_FRIEND_BUILD, workBuildSendToFriend);
}

function* workSubmitSendToFriend({ params }) {
  if (params.scope === 'anr') {
    yield call(workAnRSendToFriend, params);
  } else {
    try {
      const singleItem = yield select((state) => state.selection.singleItem);

      let documentitemIds;
      if (params.scope === 'cart' && params.cartId !== 1000) {
        documentitemIds = params.idDocs;
      } else if (singleItem) {
        documentitemIds = [singleItem.documentitem];
      } else {
        const checkedItems = yield select((state) => state.selection.checkedItems);
        documentitemIds = _.map(
          checkedItems,
          (item) => item.documentitem,
        );
      }

      const apiParams = {
        subject: params.subject,
        to: params.to,
        cc: params.cc,
        bcc: params.bcc,
        documentitem: documentitemIds,
        pj: params.pj,
        typepj: params.typepj,
        comments: params.comment,
      };

      if (params.scope === 'cart' && params.cartId !== 1000) {
        apiParams.panier = params.cartId;
      } else {
        const { mouvement } = yield select((state) => state.search.results);
        apiParams.mouvement = mouvement;
      }
      yield call(submitSendToFriend, { uriParams: apiParams });

      if (_.isEmpty(singleItem)) {
        yield put(clearSelection());
      } else {
        yield put(clearSingleItem());
      }
      snackActions.info(i18next.t('send_to_friend.docs_sent', { count: documentitemIds.length }));
      yield put(closeDialogSendToFriend());
      yield put(submitSendToFriendSuccess());
    } catch (error) {
      yield put(submitSendToFriendError(error));
      console.error(error);
      snackActions.error(i18next.t('send_to_friend.error_sending'));
    }
  }
}

function* watchSubmitSendToFriend() {
  yield takeLatest(types.SEND_TO_FRIEND_SUBMIT, workSubmitSendToFriend);
}

export default {
  watchBuildSendToFriend,
  watchSubmitSendToFriend,
};
