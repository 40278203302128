export const types = {
  FETCH_UNANSWERED_FILES: '@SAFRAN/REPORT/FETCH_UNANSWERED_FILES',
  FETCH_UNANSWERED_FILES_SUCCESS: '@SAFRAN/REPORT/FETCH_UNANSWERED_FILES_SUCCESS',
  FETCH_UNANSWERED_FILES_ERROR: '@SAFRAN/REPORT/FETCH_UNANSWERED_FILES_ERROR',
  GENERATE_REPORT: '@SAFRAN/REPORT/GENERATE_REPORT',
};

export const fetchUnansweredFiles = () => ({
  type: types.FETCH_UNANSWERED_FILES,
});
export const fetchUnansweredFilesSuccess = (unansweredFiles) => ({
  type: types.FETCH_UNANSWERED_FILES_SUCCESS,
  unansweredFiles,
});
export const fetchUnansweredFilesError = () => ({
  type: types.FETCH_UNANSWERED_FILES_ERROR,
});

export const generateReport = (isTest) => ({
  type: types.GENERATE_REPORT,
  isTest,
});
