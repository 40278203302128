import {
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import i18next from 'i18next';

import {
  types,
  fetchAlerts,
  fetchAlertsSuccess,
  fetchAlertsError,
  deleteAlertsSuccess,
  deleteAlertsError,
  fetchAlertSuccess,
  fetchAlertError,
  saveAlertSuccess,
  saveAlertError,
  activateDeactivateAlertsSuccess,
  activateDeactivateAlertsError,
  createAlertSuccess,
  createAlertError,
  fetchAlertFormSuccess,
  fetchAlertFormError,
} from 'generic/core/alerts/actions';
import {
  fetchAlerts as fetchAlertsApi,
  fetchAlert as fetchAlertApi,
  deleteAlerts,
  updateAlert,
  activateDeactivateAlerts,
  createAlert,
} from 'generic/api/alert';
import {
  getForm,
} from 'generic/api/config';
import { snackActions } from 'generic/utils/snackbar';
import { closeDialogCreateAlert } from 'generic/core/actions/actions';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { ALERT } = QES_CONSTANTS;

function* workFetchAlerts({ status }) {
  try {
    const typesVeille = [ALERT.TYPES.ALERT, ALERT.TYPES.REQUEST].join(',');
    const alerts = yield call(fetchAlertsApi, { uriParams: { typesVeille, etatVeille: status } });
    yield put(fetchAlertsSuccess(alerts));
  } catch (error) {
    yield put(fetchAlertsError(error));
    console.error(error);
    snackActions.error(i18next.t('alerts.error_fetching_alerts'));
  }
}

function* watchFetchAlerts() {
  yield takeLatest(types.FETCH_ALERTS, workFetchAlerts);
}

function* workDeleteAlerts({ ids }) {
  try {
    yield call(deleteAlerts, ids);
    yield put(deleteAlertsSuccess());
    snackActions.info(i18next.t('alerts.alerts_deleted', { count: ids.length }));
    yield put(fetchAlerts());
  } catch (error) {
    yield put(deleteAlertsError(error));
    console.error(error);
    snackActions.error(i18next.t('alerts.error_deleting_alerts', { count: ids.length }));
  }
}

function* watchDeleteAlerts() {
  yield takeLatest(types.DELETE_ALERTS, workDeleteAlerts);
}

function* workFetchAlert(action) {
  try {
    const alert = yield call(fetchAlertApi, action.id);
    yield put(fetchAlertSuccess(alert));
  } catch (error) {
    yield put(fetchAlertError(error));
    console.error(error);
    snackActions.error(i18next.t('alerts.error_fetching_alert'));
  }
}

function* watchFetchAlert() {
  yield takeLatest(types.FETCH_ALERT, workFetchAlert);
}

function* workActivateDeactivateAlerts({ ids, activate, status }) {
  try {
    const etat = (activate) ? ALERT.STATUSES.DEFAULT_ACTIVE : ALERT.STATUSES.DEFAULT_INACTIVE;
    const message = (activate) ? 'alerts.alerts_activate' : 'alerts.alerts_deactivate';
    const params = {
      veilles: ids,
      etat_veille: etat,
    };

    const results = yield call(activateDeactivateAlerts, { bodyItems: { ...params } });
    yield put(activateDeactivateAlertsSuccess(results));
    snackActions.info(i18next.t(message, { count: ids.length }));
    yield put(fetchAlerts(status));
  } catch (error) {
    yield put(activateDeactivateAlertsError(error));
    console.error(error);
    snackActions.error(i18next.t('alerts.error_updating_alert'));
  }
}

function* watchActivateDeactivateAlerts() {
  yield takeLatest(types.ACTIVATE_DEACTIVATE_ALERTS, workActivateDeactivateAlerts);
}

function* workSaveAlert({ params }) {
  try {
    yield call(updateAlert, { bodyItems: { ...params } });
    yield put(saveAlertSuccess());
    snackActions.info(i18next.t('alerts.alert_updated'));
  } catch (error) {
    yield put(saveAlertError(error));
    console.error(error);
    snackActions.error(i18next.t('alerts.error_updating_alert'));
  }
}

function* watchSaveAlert() {
  yield takeLatest(types.SAVE_ALERT, workSaveAlert);
}

function* workCreateAlert({ params }) {
  try {
    const results = yield call(createAlert, { bodyItems: { ...params } });
    yield put(createAlertSuccess(results));
    snackActions.info(i18next.t('alerts.alert_created', { alert: params.libelle }));
    yield put(closeDialogCreateAlert());
  } catch (error) {
    yield put(createAlertError(error));
    console.error(error);
    snackActions.error(i18next.t('alerts.error_creating_alert'));
  }
}

function* watchCreateAlert() {
  yield takeLatest(types.CREATE_ALERT, workCreateAlert);
}

function* workAlertForm({ id }) {
  const finalParams = { uriParams: { veille: id } };
  try {
    const results = yield call(getForm, finalParams);
    yield put(fetchAlertFormSuccess(results));
  } catch (response) {
    yield put(fetchAlertFormError(response));
    console.error(response);
    snackActions.error(i18next.t('alerts.error_fetching_form'));
  }
}

function* watchAlertForm() {
  yield takeLatest(types.FETCH_ALERT_FORM, workAlertForm);
}

export default {
  watchFetchAlerts,
  watchDeleteAlerts,
  watchFetchAlert,
  watchActivateDeactivateAlerts,
  watchSaveAlert,
  watchCreateAlert,
  watchAlertForm,
};
