import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  fetchActiveItemResults,
  setFiltersParam,
} from 'generic/core/anr/actions';
import FormAnRFilters from 'generic/components/forms/FormAnRFilters';

const FiltersContainer = () => {
  const activeItem = useSelector((state) => state.anr.activeItem);
  const initialValues = useSelector((state) => state.anr.filtersParams);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setFiltersParam({ [event.target.name]: event.target.value }));
  };

  const handleSubmit = () => {
    dispatch(fetchActiveItemResults({ offset: 0 }, { forceClearSelection: true }));
  };

  const handleClear = (name) => {
    dispatch(setFiltersParam({ [name]: '' }));
    handleSubmit();
  };

  if (_.isEmpty(activeItem)) {
    return null;
  }
  const showFilterInput = _.includes(['glimpse', 'news', 'archives'], activeItem.view);
  return (
    <FormAnRFilters
      initialValues={initialValues}
      handleChange={handleChange}
      handleClear={handleClear}
      handleSubmit={handleSubmit}
      showFilterInput={showFilterInput}
    />
  );
};

export default FiltersContainer;
