import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsWordcloud from 'highcharts/modules/wordcloud';

const archimedeanSpiral = function archimedeanSpiral(t) {
  const newT = t * 0.35;

  return {
    x: newT * Math.cos(newT) * 1.2,
    y: newT * Math.sin(newT),
  };
};

HighchartsNoData(Highcharts);
HighchartsWordcloud(Highcharts);
Highcharts.seriesTypes.wordcloud.prototype.spirals.archimedeanSpiral = archimedeanSpiral;

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      wordcloud: {
        minFontSize: 10,
        rotation: {
          from: 0,
          to: 0,
        },
        spiral: 'archimedeanSpiral',
        allowExtendPlayingField: true,
        placementStrategy: 'center',
      },
    },
    responsive: {
      rules: [{
        condition: {
          minWidth: 700,
        },
        chartOptions: {
          plotOptions: {
            wordcloud: {
              spiral: 'rectangular',
            },
          },
        },
      }],
    },
    chart: { type: 'wordcloud' },
  },
};

const Wordcloud = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

Wordcloud.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

Wordcloud.defaultProps = {
};

export default Wordcloud;
