import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, List, ListItem, Paper, Typography,
} from '@mui/material';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import DocumentForWidgetList from 'custom/safran/components/pages/DocumentForWidgetList';
import { qesdocumentPropType } from 'custom/safran/core/qes/documentProptypes';

const DisplayWarning = ({ missingFiles, handleDocumentClick, openFastGed }) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 0 100%"
      height="100%"
    >
      <Paper
        sx={{
          backgroundColor: 'warning.light',
          mt: 2,
          px: 1,
        }}
      >
        <Trans>
          {t('safran.report.warning_info')}
        </Trans>
      </Paper>

      <Typography sx={{ mt: 4, borderBottom: '1px solid #c5c5c5' }} variant="h6">
        {t('safran.report.warning_list_files')}
      </Typography>
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {
            _.map(missingFiles, (file) => (
              <ListItem key={file.idext}>
                <DocumentForWidgetList
                  qesdocument={file}
                  handleDocumentClick={() => handleDocumentClick(file.idext, +file.BASE_ID)}
                  openFastGed={() => openFastGed(file.idext, +file.BASE_ID)}
                />
              </ListItem>
            ))
          }
        </List>
      </Box>
    </Box>
  );
};

DisplayWarning.propTypes = {
  missingFiles: PropTypes.arrayOf(qesdocumentPropType.isRequired).isRequired,
  handleDocumentClick: PropTypes.func.isRequired,
  openFastGed: PropTypes.func.isRequired,
};

export default DisplayWarning;
