import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReportActions = ({ hasMissingFiles, handleGenerate, handleTest }) => {
  const { t } = useTranslation();

  return (
    <Box align="right" sx={{ pt: 1 }}>
      <Button
        sx={{ mx: 1 }}
        disabled={hasMissingFiles}
        onClick={handleGenerate}
      >
        {t('safran.report.actions.generate')}
      </Button>

      {hasMissingFiles && (
        <Button
          sx={{ mx: 1 }}
          onClick={handleTest}
        >
          {t('safran.report.actions.test')}
        </Button>
      )}
    </Box>
  );
};

ReportActions.propTypes = {
  hasMissingFiles: PropTypes.bool.isRequired,
  handleGenerate: PropTypes.func.isRequired,
  handleTest: PropTypes.func.isRequired,
};

export default ReportActions;
