import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import _ from 'lodash';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { fastGedOpen } from 'generic/core/ged/actions';
import { cleanupResultsComplete, fetchResultsComplete, refreshResults } from 'generic/core/search/actions';
import ResultsComplete from 'generic/components/pages/ResultsComplete';
import GEDModalContainer from 'generic/containers/GEDModalContainer';

const ResultsCompleteContainer = () => {
  const bases = useSelector((state) => state.config.bases);
  const loadingComplete = useSelector((state) => state.search.loadingComplete);
  const resultsComplete = useSelector((state) => state.search.resultsComplete);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleClose = () => {
    dispatch(cleanupResultsComplete());
  };

  const { ETAT_DOCUMENT } = QES_CONSTANTS;

  if (_.isEmpty(_.get(resultsComplete, 'documents', [])) || loadingComplete) {
    return null;
  }

  const documentBaseId = resultsComplete.documents[0].base;
  const documentBase = _.find(bases, { base: documentBaseId });

  const showButtons = _.isEmpty(matchPath(location.pathname, { path: ['/carts', '/newsletters'] }));

  return (
    <Fragment>
      <ResultsComplete
        canAddToCart={documentBase.panier}
        canAddToNewsletter={documentBase.newsletter}
        canComment={documentBase.commentaire}
        canCreateAlerts={documentBase.veille}
        canEditInGED={documentBase.gedModification}
        canFastEditInGED={documentBase.avecGedRapide}
        canDeleteDocument={_.find(resultsComplete.actions, { etat: ETAT_DOCUMENT.SUPPRIMER }) !== undefined}
        canSendToFriend={documentBase.envoi}
        handleClose={handleClose}
        loadingComplete={loadingComplete}
        resultsComplete={resultsComplete}
        showButtons={showButtons}
        openFastGed={() => dispatch(fastGedOpen(resultsComplete.documents[0].idext, resultsComplete.documents[0].base))}
        titleFieldName={documentBase.champTitre}
        withMoreLikeThis={documentBase.avecMoreLikeThis}
      />

      <GEDModalContainer
        afterSave={() => {
          dispatch(fetchResultsComplete(resultsComplete.documents[0].idext));
          dispatch(refreshResults());
        }}
      />
    </Fragment>
  );
};

export default ResultsCompleteContainer;
