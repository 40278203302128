import {
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';

import {
  types,
  fetchResultsSuccess,
  fetchResultsError,
  fetchResults,
  fetchResultsCompleteSuccess,
  fetchResultsCompleteError,
} from 'generic/core/search/actions';
import {
  fetchFormConfig,
} from 'generic/core/config/actions';
import {
  clearSelection,
} from 'generic/core/selection/actions';
import { setDashboardLoading } from 'generic/core/dashboard/actions';
import {
  doSearch, doSearchComplete,
} from 'generic/api/search';
import { snackActions } from 'generic/utils/snackbar';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { fetchMoreLikeThis } from 'generic/core/moreLikeThis/actions';
import { computeMLTParams } from 'generic/utils/qesUtils';

const {
  REFINING_STRATEGY,
  DATE_INTERVAL_COMPARATOR,
} = QES_CONSTANTS;

function* workSearch({ values }) {
  try {
    const results = yield call(doSearch, values);
    let triActif = '';
    const tris = _.reduce(results?.tris, (acc, value) => {
      acc.push({ code: value.codeAsc, libelle: `${value.libelle} ${value.libelleAsc}`, actif: value.actifAsc });
      acc.push({ code: value.codeDesc, libelle: `${value.libelle} ${value.libelleDesc}`, actif: value.actifDesc });
      if (value.actifAsc) {
        triActif = value.codeAsc;
      } else if (value.actifDesc) {
        triActif = value.codeDesc;
      }
      return acc;
    }, []);
    results.tris = tris;
    results.triActif = triActif;
    yield put(fetchResultsSuccess(results));
    if (values.refreshForm) {
      yield put(fetchFormConfig({ uriParams: { mouvement: results.mouvement } }));
    }
  } catch (response) {
    yield put(fetchResultsError(response));
    console.error(response);
    snackActions.error(i18next.t('results.error_fetching_results'));
  } finally {
    if (values.clearSelection) {
      yield put(clearSelection());
    }
  }
}

function* workFetchResultsComplete({ id, baseId }) {
  try {
    const uriParams = {
      premier: 0,
      dernier: 0,
      idqes: id,
      format: 1,
    };
    let activeBase = yield select((state) => state.config.activeBase);
    if (baseId) {
      uriParams.base = baseId;
      const bases = yield select((state) => state.config.bases);
      activeBase = _.find(bases, { base: baseId });
    } else {
      const { mouvement } = yield select((state) => state.search.results);
      uriParams.mouvement = mouvement;
    }

    const results = yield call(doSearchComplete, { uriParams });
    yield put(fetchResultsCompleteSuccess(results));
    if (activeBase.avecMoreLikeThis) {
      const paramsMlt = computeMLTParams(activeBase, id);
      yield put(fetchMoreLikeThis(paramsMlt, true));
    }
  } catch (response) {
    yield put(fetchResultsCompleteError(response));
    console.error(response);
    snackActions.error(i18next.t('results.error_fetching_results'));
  }
}

function* workRefreshResults() {
  const {
    mouvement, premier, dernier, tranche,
  } = yield select((state) => state.search.results);

  yield put(fetchResults({
    uriParams: {
      mouvement,
      premier,
      dernier,
      tranche,
    },
    refreshForm: false,
    clearSelection: true,
    clearResults: true,
  }));
}

function* refineSearch(updatedFilters) {
  const { mouvement, tranche } = yield select((state) => state.search.results);
  const pathname = yield select((state) => state.router.location.pathname);
  const uriParams = {
    premier: 1,
    dernier: tranche,
    ...updatedFilters,
  };
  if (!mouvement) {
    const activeBase = yield select((state) => state.config.activeBase);
    uriParams.base = activeBase.base;
    uriParams.tranche = activeBase.tranche;
  } else {
    uriParams.mouvement = mouvement;
  }
  if (pathname === '/dashboard') {
    yield put(setDashboardLoading());
  }
  yield put(fetchResults({
    uriParams,
    refreshForm: true,
    clearSelection: true,
    clearResults: true,
  }));
}

function* sortSearch({ sortValue }) {
  const { mouvement, tranche } = yield select((state) => state.search.results);
  yield put(fetchResults({
    uriParams: {
      mouvement,
      premier: 1,
      dernier: tranche,
      tri: sortValue,
    },
    refreshForm: false,
    clearSelection: true,
    clearResults: true,
  }));
}

function* workRefineReplaceCriterion({ values }) {
  const { criterion, replacementValue } = values;
  const args = {
    [`RF_${criterion.champ}`]: '',
    [`F_${criterion.champ}`]: replacementValue,
  };
  yield call(refineSearch, args);
}

function* workRefineExcludeCriterion({ values }) {
  const { criterion } = values;
  const args = {
    [`NMC_${criterion.mouvementChamp}`]: 1,
  };
  yield call(refineSearch, args);
}

function* workRefineIncludeCriterion({ values }) {
  const { criterion } = values;
  const args = {
    [`AMC_${criterion.mouvementChamp}`]: 1,
  };
  yield call(refineSearch, args);
}

function* workRefineRemoveCriterion({ values }) {
  const { criterion } = values;
  const args = {
    [`DMC_${criterion.mouvementChamp}`]: 1,
  };
  yield call(refineSearch, args);
}

function* workRefineAddFacetValue({ values }) {
  const {
    facetValue: {
      champ, strategie, formatFacet, comparator, begin, end,
    },
  } = values;

  let args = {};
  let comparatorFinal = DATE_INTERVAL_COMPARATOR.equal;
  if (comparator) {
    comparatorFinal = comparator;
  }

  if (formatFacet === 'date') {
    const [, y, m, d] = begin.match(/(\d{4})(\d{2})(\d{2})/);
    if (end) {
      const [, y2, m2, d2] = end.match(/(\d{4})(\d{2})(\d{2})/);
      args = {
        [`R_${champ}`]: REFINING_STRATEGY.as_is,
        [`RF_${champ}`]: '',
        [`F_${champ}`]: comparatorFinal,
        [`FD_${champ}`]: d,
        [`FM_${champ}`]: m,
        [`FY_${champ}`]: y,
        [`FD2_${champ}`]: d2,
        [`FM2_${champ}`]: m2,
        [`FY2_${champ}`]: y2,
      };
    } else {
      args = {
        [`R_${champ}`]: REFINING_STRATEGY.as_is,
        [`RF_${champ}`]: '',
        [`F_${champ}`]: comparatorFinal,
        [`FD_${champ}`]: d,
        [`FM_${champ}`]: m,
        [`FY_${champ}`]: y,
      };
    }
  } else {
    args = {
      [`R_${champ}`]: REFINING_STRATEGY.as_is,
      [`F_${champ}`]: strategie,
    };
  }

  yield call(refineSearch, args);
}

function* workChangeSort(value) {
  yield call(sortSearch, value);
}

function* watchRefreshResults() {
  yield takeLatest(types.REFRESH_RESULTS, workRefreshResults);
}
function* watchSearch() {
  yield takeLatest(types.FETCH_RESULTS, workSearch);
}
function* watchFetchResultsComplete() {
  yield takeLatest(types.FETCH_RESULTS_COMPLETE, workFetchResultsComplete);
}
function* watchRefineReplaceCriterion() {
  yield takeLatest(types.REFINE_REPLACE_CRITERION, workRefineReplaceCriterion);
}
function* watchRefineExcludeCriterion() {
  yield takeLatest(types.REFINE_EXCLUDE_CRITERION, workRefineExcludeCriterion);
}
function* watchRefineIncludeCriterion() {
  yield takeLatest(types.REFINE_INCLUDE_CRITERION, workRefineIncludeCriterion);
}
function* watchRefineRemoveCriterion() {
  yield takeLatest(types.REFINE_REMOVE_CRITERION, workRefineRemoveCriterion);
}
function* watchRefineAddFacetValue() {
  yield takeLatest(types.REFINE_ADD_FACET_VALUE, workRefineAddFacetValue);
}
function* watchChangeSort() {
  yield takeLatest(types.CHANGE_SORT, workChangeSort);
}

export default {
  watchChangeSort,
  watchFetchResultsComplete,
  watchRefineAddFacetValue,
  watchRefineExcludeCriterion,
  watchRefineIncludeCriterion,
  watchRefineRemoveCriterion,
  watchRefineReplaceCriterion,
  watchRefreshResults,
  watchSearch,
};
