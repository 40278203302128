import _ from 'lodash';
import loadable from '@loadable/component';
import { CUSTOM_PATH } from 'generic/core/constants';

export const getUserFromStorage = () => {
  const item = localStorage.getItem('user');
  return item ? JSON.parse(item) : {};
};

export const getLogonFromStorage = () => _.get(getUserFromStorage(), 'logon');

export const getTokenFromStorage = () => localStorage.getItem('token');

export const cleanStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('config');
};

// Permet de prendre un arbre avec nested children et
// de le mettre à plat dans un tableau à une dimension
export const treeFlattener = (tree) => {
  const recurse = (nodes) => (
    _.map(
      nodes,
      (node) => ([
        _.omit(node, 'children'),
        recurse(node.children),
      ]),
    )
  );

  return _.flattenDeep(recurse(tree, []));
};

export const stripHtmlTags = (dangerouslyHTML) => {
  const div = document.createElement('div');
  div.innerHTML = dangerouslyHTML;
  const text = div.textContent || div.innerText || '';

  return text;
};

export const splitAndCleanEmptyValues = function splitValuesFct(semiColonString) {
  return semiColonString.split(/\s*;+\s*/).filter((s) => s !== '');
};

export const testBeforeUnload = (event) => {
  // eslint-disable-next-line no-param-reassign
  (event || window.event).returnValue = null;
  return null;
};

export const overrideRessource = (path, unlessComponent) => {
  // Premier cas : on override un js, une image, etc.
  if (unlessComponent) {
    let staticElement;
    let hasCustom = false;
    // Si un CUSTOM_PATH existe
    if (CUSTOM_PATH) {
      /* eslint-disable */
      try {
        // On tente d'importer le fichier custom
        staticElement = require(`custom/${CUSTOM_PATH}/${path}`);
        hasCustom = true;
      } catch (err) {
        hasCustom = false;
      }
    }
    // Si aucun fichier custom n'existe, on renvoie le générique
    if (!hasCustom) {
      staticElement = require(`generic/${path}`);
    }
    /* eslint-enable */
    return staticElement;
  }
  // Second cas : on override un composant
  // Si un CUSTOM_PATH est vide
  if (!CUSTOM_PATH) {
    // On renvoie le générique directement
    return loadable(() => import(`generic/${path}`));
  }
  // Sinon, on tente d'import le custom, et si il n'est pas trouvé,
  // on renvoie le générique
  return loadable(
    () => import(`custom/${CUSTOM_PATH}/${path}`).catch(
      () => {
        if (process.env.NODE_ENV === 'development') {
          console.warn(`FAILED TO LOAD CUSTOM PATH : custom/${CUSTOM_PATH}/${path}`);
        }
        return import(`generic/${path}`);
      },
    ),
  );
};
