export const types = {
  CLEANUP_ALERT: '@ALERTS/CLEANUP_ALERT',
  CLEANUP_ALERTS: '@ALERTS/CLEANUP_ALERTS',
  FETCH_ALERTS: '@ALERTS/FETCH_ALERTS',
  FETCH_ALERTS_SUCCESS: '@ALERTS/FETCH_ALERTS_SUCCESS',
  FETCH_ALERTS_ERROR: '@ALERTS/FETCH_ALERTS_ERROR',
  DELETE_ALERTS: '@ALERTS/DELETE_ALERTS',
  DELETE_ALERTS_SUCCESS: '@ALERTS/DELETE_ALERTS_SUCCESS',
  DELETE_ALERTS_ERROR: '@ALERTS/DELETE_ALERTS_ERROR',
  FETCH_ALERT: '@ALERTS/FETCH_ALERT',
  FETCH_ALERT_SUCCESS: '@ALERTS/FETCH_ALERT_SUCCESS',
  FETCH_ALERT_ERROR: '@ALERTS/FETCH_ALERT_ERROR',
  ACTIVATE_DEACTIVATE_ALERTS: '@ALERTS/ACTIVATE_DEACTIVATE_ALERTS',
  ACTIVATE_DEACTIVATE_ALERTS_SUCCESS: '@ALERTS/ACTIVATE_DEACTIVATE_ALERTS_SUCCESS',
  ACTIVATE_DEACTIVATE_ALERTS_ERROR: '@ALERTS/ACTIVATE_DEACTIVATE_ALERTS_ERROR',
  SAVE_ALERT: '@ALERTS/SAVE_ALERT',
  SAVE_ALERT_SUCCESS: '@ALERTS/SAVE_ALERT_SUCCESS',
  SAVE_ALERT_ERROR: '@ALERTS/SAVE_ALERT_ERROR',
  CREATE_ALERT: '@ALERTS/CREATE_ALERT',
  CREATE_ALERT_SUCCESS: '@ALERTS/CREATE_ALERT_SUCCESS',
  CREATE_ALERT_ERROR: '@ALERTS/CREATE_ALERT_ERROR',
  FETCH_ALERT_FORM: '@ALERTS/FETCH_ALERT_FORM',
  FETCH_ALERT_FORM_SUCCESS: '@ALERTS/FETCH_ALERT_FORM_SUCCESS',
  FETCH_ALERT_FORM_ERROR: '@ALERTS/FETCH_ALERT_FORM_ERROR',
};

export const cleanupAlert = () => ({
  type: types.CLEANUP_ALERT,
});

export const cleanupAlerts = () => ({
  type: types.CLEANUP_ALERTS,
});

export const fetchAlerts = (status) => ({
  type: types.FETCH_ALERTS,
  status,
});

export const fetchAlertsSuccess = (alerts) => ({
  type: types.FETCH_ALERTS_SUCCESS,
  alerts,
});

export const fetchAlertsError = (response) => ({
  type: types.FETCH_ALERTS_ERROR,
  response,
});

export const deleteAlerts = (ids) => ({
  type: types.DELETE_ALERTS,
  ids,
});

export const deleteAlertsSuccess = () => ({
  type: types.DELETE_ALERTS_SUCCESS,
});

export const deleteAlertsError = (response) => ({
  type: types.DELETE_ALERTS_ERROR,
  response,
});

export const fetchAlert = (id) => ({
  type: types.FETCH_ALERT,
  id,
});

export const fetchAlertSuccess = (alert) => ({
  type: types.FETCH_ALERT_SUCCESS,
  alert,
});

export const fetchAlertError = (response) => ({
  type: types.FETCH_ALERT_ERROR,
  response,
});

export const activateDeactivateAlerts = (ids, activate, status) => ({
  type: types.ACTIVATE_DEACTIVATE_ALERTS,
  ids,
  activate,
  status,
});

export const activateDeactivateAlertsSuccess = (results) => ({
  type: types.ACTIVATE_DEACTIVATE_ALERTS_SUCCESS,
  results,
});

export const activateDeactivateAlertsError = (response) => ({
  type: types.ACTIVATE_DEACTIVATE_ALERTS_ERROR,
  response,
});

export const saveAlert = (params) => ({
  type: types.SAVE_ALERT,
  params,
});

export const saveAlertSuccess = () => ({
  type: types.SAVE_ALERT_SUCCESS,
});

export const saveAlertError = (response) => ({
  type: types.SAVE_ALERT_ERROR,
  response,
});

export const createAlert = (params) => ({
  type: types.CREATE_ALERT,
  params,
});

export const createAlertSuccess = () => ({
  type: types.CREATE_ALERT_SUCCESS,
});

export const createAlertError = (response) => ({
  type: types.CREATE_ALERT_ERROR,
  response,
});

export const fetchAlertForm = (id) => ({
  type: types.FETCH_ALERT_FORM,
  id,
});

export const fetchAlertFormSuccess = (results) => ({
  type: types.FETCH_ALERT_FORM_SUCCESS,
  results,
});

export const fetchAlertFormError = (response) => ({
  type: types.FETCH_ALERT_FORM_ERROR,
  response,
});
