import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Typography,
  Box,
  Drawer,
  IconButton,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Close,
  Folder,
  PlayArrow,
} from '@mui/icons-material';
import ArticleViewActionsContainer from 'generic/containers/anr/ArticleViewActionsContainer';
import ArticleViewDisplay from 'generic/components/pages/anr/ArticleViewDisplay';
import { articlePropType } from 'generic/core/anr/proptypes';
import ArticleActionsContainer from 'generic/containers/anr/ArticleActionsContainer';

const ArticleView = ({
  anchor,
  article,
  handleClose,
  handleIsLoaded,
  loading,
}) => {
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  let finalAnchor = anchor;
  if (smallerThanLarge) {
    finalAnchor = 'bottom';
  }
  const paperSx = {
    overflow: 'hidden',
    height: finalAnchor === 'right' ? '100%' : 'calc(100% - 64px)',
    position: finalAnchor === 'right' ? 'relative' : 'fixed',
  };
  let drawerWidth = '0';
  if (!_.isEmpty(article)) {
    if (finalAnchor === 'right') {
      drawerWidth = '45%';
    } else {
      drawerWidth = '100%';
    }
  } else {
    paperSx.border = 'none';
  }

  let foldersPrefix;
  let foldersTitles;
  if (finalAnchor === 'bottom') {
    foldersTitles = _.map(article.folders, (folder) => folder.title);
    foldersPrefix = foldersTitles.join(' | ');
  }
  return (
    <Drawer
      variant={finalAnchor === 'right' ? 'permanent' : 'temporary'}
      anchor={finalAnchor}
      onClose={handleClose}
      open={!_.isEmpty(article)}
      sx={{ width: drawerWidth }}
      PaperProps={{ sx: paperSx }}
    >
      {!_.isEmpty(article) && (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
          overflow="auto"
        >
          <Box
            flexShrink="0"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={1}
          >
            <Box display="flex" minWidth="0" flexGrow="1">
              {foldersPrefix && (
                <Tooltip
                  title={(
                    _.map(
                      foldersTitles,
                      (title) => (
                        <Fragment key={title}>
                          {` - ${title}`}
                          <br />
                        </Fragment>
                      ),
                    )
                  )}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width="25%"
                  >
                    <Folder fontSize="small" sx={{ marginRight: '2px' }} />
                    <Typography
                      variant="h6"
                      noWrap
                    >
                      {foldersPrefix}
                    </Typography>
                  </Box>
                </Tooltip>
              )}
              <Box
                display="flex"
                alignItems="center"
                minWidth="0"
              >
                <PlayArrow fontSize="small" />
                <Typography
                  variant="h6"
                  noWrap
                  title={article.title}
                >
                  {article.title}
                </Typography>
              </Box>
            </Box>
            <Box
              flexShrink="0"
              display="flex"
              alignItems="center"
            >
              {!smallerThanLarge && (
                <Box mx={1}>
                  <ArticleActionsContainer
                    article={article}
                    visible
                    elevation={false}
                  />
                </Box>
              )}
              <ArticleViewActionsContainer />
              <IconButton
                size="small"
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          {smallerThanLarge && (
            <Box margin="auto" mb={2}>
              <ArticleActionsContainer
                article={article}
                visible
                elevation={false}
              />
            </Box>
          )}
          <ArticleViewDisplay
            article={article}
            handleIsLoaded={handleIsLoaded}
            loading={loading}
          />
        </Box>
      )}
    </Drawer>
  );
};

ArticleView.propTypes = {
  anchor: PropTypes.string,
  article: articlePropType,
  handleClose: PropTypes.func.isRequired,
  handleIsLoaded: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

ArticleView.defaultProps = {
  anchor: 'bottom',
  article: {},
};

export default ArticleView;
