import { types } from 'custom/safran/core/redux/actions';

const customReducer = (
  state = {
    report: {
      loading: false,
      unansweredFiles: [],
    },
  },
  action,
) => {
  switch (action.type) {
    case types.FETCH_UNANSWERED_FILES: {
      return {
        ...state,
        report: {
          ...state.report,
          loading: true,
        },
      };
    }
    case types.FETCH_UNANSWERED_FILES_SUCCESS: {
      return {
        ...state,
        report: {
          ...state.report,
          unansweredFiles: action.unansweredFiles,
          loading: false,
        },
      };
    }
    case types.FETCH_UNANSWERED_FILES_ERROR: {
      return {
        ...state,
        report: {
          ...state.report,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default customReducer;
