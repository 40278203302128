export const FETCH_ERROR = '@AUTH/FETCH_ERROR';

export const RESTORE_STATE_FROM_STORAGE = '@AUTH/RESTORE_STATE_FROM_STORAGE';
export const RESTORE_STATE_FROM_STORAGE_FAILURE = '@AUTH/RESTORE_STATE_FROM_STORAGE_FAILURE';

export const LOGIN = '@AUTH/LOGIN';
export const LOGIN_ERROR = '@AUTH/LOGIN_ERROR';
export const LOGIN_SUCCESS = '@AUTH/LOGIN_SUCCESS';

export const LOGOUT = '@AUTH/LOGOUT';
export const LOGOUT_ERROR = '@AUTH/LOGOUT_ERROR';
export const LOGOUT_SUCCESS = '@AUTH/LOGOUT_SUCCESS';
