import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnansweredFiles, generateReport } from 'custom/safran/core/redux/actions';
import Report from 'custom/safran/components/ui/report/Report';
import { fetchResultsComplete } from 'generic/core/search/actions';
import { fastGedOpen } from 'generic/core/ged/actions';

const ReportContainer = () => {
  const dispatch = useDispatch();
  const { unansweredFiles, loading } = useSelector((state) => state.safran.report);

  const handleGenerate = (isTest) => () => dispatch(generateReport(isTest));
  const handleDocumentClick = (idext, baseId) => dispatch(fetchResultsComplete(idext, baseId));
  const openFastGed = (idext, baseId) => dispatch(fastGedOpen(idext, baseId));

  const refreshFunction = useCallback(() => dispatch(fetchUnansweredFiles()), [dispatch]);
  useEffect(() => { refreshFunction(); }, [refreshFunction]);

  return (
    <Report
      handleGenerate={handleGenerate(false)}
      handleTest={handleGenerate(true)}
      hasMissingFiles={!_.isEmpty(unansweredFiles)}
      loading={loading}
      refreshFunction={refreshFunction}
      unansweredFiles={unansweredFiles}
      handleDocumentClick={handleDocumentClick}
      openFastGed={openFastGed}
    />
  );
};

export default ReportContainer;
