import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { activateDeactivateAlerts, cleanupAlerts, fetchAlerts } from 'generic/core/alerts/actions';
import Alerts from 'generic/components/pages/Alerts';
import { fetchResults } from 'generic/core/search/actions';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { ALERT } = QES_CONSTANTS;

const AlertsContainer = () => {
  const loading = useSelector((state) => state.alerts.loading);
  const alerts = useSelector((state) => state.alerts.alerts);
  const dispatch = useDispatch();
  const history = useHistory();

  const [statusFilter, setStatusFilter] = useState(ALERT.STATUSES.ACTIVE.join(','));
  const [selectedRows, setSelectedRows] = useState([]);

  const handleClickActivateDeactivate = (alertId, activate) => {
    setSelectedRows([]);
    dispatch(activateDeactivateAlerts([alertId], activate, statusFilter));
  };

  const handleClickActivateDeactivateSelected = (rowsSelected, data, activate) => {
    const ids = rowsSelected.data
      .filter((row) => data[row.dataIndex])
      .map((row) => data[row.dataIndex][0]);

    setSelectedRows([]);
    dispatch(activateDeactivateAlerts(ids, activate, statusFilter));
  };

  const handleClickSearch = (alertId) => {
    history.push('/results');
    dispatch(fetchResults({
      uriParams: {
        veille: alertId,
        premier: 1,
        dernier: 20,
      },
      refreshForm: true,
      clearSelection: true,
      clearResults: true,
    }));
  };

  useEffect(() => {
    dispatch(fetchAlerts(statusFilter));
  }, [statusFilter, dispatch]);

  useEffect(() => (
    // Cleanup des veilles lorsque le composant est "unmount" (donc quand on
    // change de page)
    () => dispatch(cleanupAlerts())
  ), [dispatch]);

  return (
    <Alerts
      alerts={alerts}
      loading={loading}
      setStatusFilter={setStatusFilter}
      statusFilter={statusFilter}
      setSelectedRows={setSelectedRows}
      selectedRows={selectedRows}
      handleClickActivateDeactivate={handleClickActivateDeactivate}
      handleClickActivateDeactivateSelected={handleClickActivateDeactivateSelected}
      handleClickSearch={handleClickSearch}
    />
  );
};

export default AlertsContainer;
