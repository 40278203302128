import {
  put,
  call,
  select,
  takeEvery,
  takeLatest,
  all,
} from 'redux-saga/effects';
import _ from 'lodash';
import i18next from 'i18next';
import { replace } from 'connected-react-router';
import queryString from 'query-string';

import {
  types,
  fetchFormConfigSuccess,
  fetchFormConfigError,
  setFormFields,
  setActiveBase,
} from 'generic/core/config/actions';
import {
  getForm,
} from 'generic/api/config';
import { snackActions } from 'generic/utils/snackbar';
import { cleanupResults } from 'generic/core/search/actions';
import { clearSelection } from 'generic/core/selection/actions';
import { getNormalizedFormValues, computeSearchFormInitialValues } from 'generic/utils/qesUtils';

function* form({ values, options }) {
  let finalParams = values;
  if (_.isEmpty(finalParams)) {
    const activeBaseId = yield select((state) => state.config.activeBase.base);
    finalParams = { uriParams: { base: activeBaseId } };
  }
  const {
    fetchOnlyIfStrategy,
  } = options || {};
  if (fetchOnlyIfStrategy) {
    // Si l'option fetchOnlyIfStrategy vaut true, on va tester le
    // contenu de la stratégie du formulaire :
    //   - la stratégie est vide : on bypasse la saga
    //   - la stratégie n'est pas vide : on fetch la config du
    // formulaire (permet de réinitialiser le formulaire quand
    // on sort de l'univers des résultats)
    const formStrategy = yield select((state) => state.config.form.strategie);
    if (_.isEmpty(formStrategy)) {
      return;
    }
  }
  try {
    const results = yield call(getForm, finalParams);
    // Si on est dans "l'univers des résultats" à la fin de la requête, on
    // met à jour l'URL en fonction de ce qui est setté dans le formulaire
    const pathname = yield select((state) => state.router.location.pathname);
    if (['/results', '/dashboard'].includes(pathname)) {
      yield put(replace({
        pathname,
        search: queryString.stringify(
          _.omitBy(
            {
              ...getNormalizedFormValues(computeSearchFormInitialValues(results.champs)),
              base: results.base,
            },
            (value) => ['', null, undefined, NaN].includes(value),
          ),
        ),
      }));
    }
    yield put(fetchFormConfigSuccess(results));
  } catch (response) {
    yield put(fetchFormConfigError(response));
    console.error(response);
    snackActions.error(i18next.t('config.error_fetching_config'));
  }
}

function* watchForm() {
  yield takeEvery(types.FETCH_FORM_CONFIG, form);
}

function* workSetActiveBase({ baseId }) {
  try {
    const bases = yield select((state) => state.config.bases);
    const defaultBaseId = yield select((state) => state.config.settings.baseInterne_368);
    const search = yield select((state) => state.router.location.search);
    const { base: baseIdFromUrl } = queryString.parse(search);
    const cachedBaseId = baseIdFromUrl || localStorage.getItem('baseId');
    const activeBaseId = baseId || +cachedBaseId;

    const base = _.defaultTo(
      _.find(bases, { base: activeBaseId }),
      _.find(bases, { base: defaultBaseId }),
    );
    // On va lancer toutes les actions suivantes dans un seul yield pour
    // éviter les rerender inutiles
    yield all([
      // On vide la sélection
      put(clearSelection()),
      // On vide les résultats
      put(cleanupResults()),
      // On change les champs du formulaire dans le reducer
      put(setFormFields(base.champs)),
      // Enfin, on change la base dans le reducer
      put(setActiveBase(base)),
    ]);
    // On change le baseId dans le localStorage
    localStorage.setItem('baseId', base.base);
  } catch (error) {
    console.error(error);
    snackActions.error(i18next.t('config.error_loading_base'));
  }
}

function* watchSetCurrentBase() {
  yield takeLatest([types.SET_CONFIG, types.CHANGE_ACTIVE_BASE], workSetActiveBase);
}

export default {
  watchForm,
  watchSetCurrentBase,
};
