import React from 'react';
import { Box } from '@mui/material';
import { CONSTANTS } from 'generic/core/constants';
import FormLogin from 'generic/components/forms/FormLogin';
import { overrideRessource } from 'generic/utils/utils';

const { PROJECT_NAME } = CONSTANTS;

const logo = overrideRessource('img/login-logo.png', true);

const Login = (props) => (
  <Box
    color="primary.contrastText"
    bgcolor="primary.main"
    height="100vh"
    display="flex"
    alignItems="stretch"
    justifyContent="center"
    flexGrow="1"
    sx={{
      flexDirection: { xs: 'column', md: 'row' },
    }}
  >
    <Box
      color="primary.light"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: { xs: 'auto', md: '50%' },
        borderRight: { xs: 'none', md: '2px solid' },
      }}
    >
      <Box
        component="img"
        src={logo}
        alt={PROJECT_NAME}
        sx={{
          marginBottom: { xs: '2em', md: '0' },
        }}
      />
    </Box>
    <Box
      padding="0 10%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: { xs: 'auto', md: '50%' },
      }}
    >
      <FormLogin {...props} />
      {/*
        TODO: add a react-router switch and create an RetreivePwd component.
        Use React.Lazy imports
      */}
    </Box>
  </Box>
);

export default Login;
