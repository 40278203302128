import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { ALERT } = QES_CONSTANTS;

const CreateAlertDialog = ({
  handleCreateAlert,
  handleClose,
  loading,
}) => {
  const { t } = useTranslation();
  const [nameNewAlert, setNameNewAlert] = useState();
  const [typeAlert, setTypeAlert] = useState(ALERT.TYPES.ALERT);

  return (
    <Dialog
      scroll="paper"
      fullWidth
      open
    >
      <DialogTitle>
        {t('actions.create_alert')}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <TextField
          label={t('alerts.name')}
          sx={{ width: '100%' }}
          onChange={(event) => setNameNewAlert(event.target.value)}
        />
        <FormControl
          variant="outlined"
          sx={{ marginTop: 2, marginBottom: 2, width: '100%' }}
        >
          <InputLabel id="test-select-label">
            {t('alerts.type')}
          </InputLabel>
          <Select
            label={t('alerts.type')}
            variant="outlined"
            value={typeAlert}
            labelId="test-select-label"
            onChange={(event) => setTypeAlert(parseInt(event.target.value, 10))}
            displayEmpty
          >
            <MenuItem value={ALERT.TYPES.ALERT}>{t('alerts.alert_type_alert')}</MenuItem>
            <MenuItem value={ALERT.TYPES.REQUEST}>{t('alerts.alert_type_request')}</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleClose}
        >
          {t('dialog.cancel')}
        </Button>
        <LoadingButton
          onClick={() => handleCreateAlert(nameNewAlert, typeAlert)}
          disabled={_.isEmpty(nameNewAlert)}
          loading={loading}
        >
          {t('actions.create')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

CreateAlertDialog.propTypes = {
  handleCreateAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

CreateAlertDialog.defaultProps = {
  loading: false,
};

export default CreateAlertDialog;
