import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import QES_CONSTANTS from 'generic/core/qes/constants';

import {
  Checkbox,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Box,
  useMediaQuery,
} from '@mui/material';

import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import { fetchResultsComplete, refineAddFacetValue } from 'generic/core/search/actions';
import { fetchWidgets } from 'generic/core/dashboard/actions';

import Bar from 'generic/components/dashboard-items/Bar';
import ColumnHisto from 'generic/components/dashboard-items/ColumnHisto';
import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import DocumentList from 'generic/components/dashboard-items/DocumentList';
import Gauge from 'generic/components/dashboard-items/SolidGauge';
import Heatmap from 'generic/components/dashboard-items/Heatmap';
import Map from 'generic/components/dashboard-items/Map';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import Pie from 'generic/components/dashboard-items/Pie';
import Sankey from 'generic/components/dashboard-items/Sankey';
import Scatter3d from 'generic/components/dashboard-items/Scatter3d';
import Spline from 'generic/components/dashboard-items/Spline';
import Sunburst from 'generic/components/dashboard-items/Sunburst';
import Treemap from 'generic/components/dashboard-items/Treemap';
import Wordcloud from 'generic/components/dashboard-items/Wordcloud';
import WidgetContainer from 'generic/containers/WidgetContainer';
import { getColorForFrequency, monoColors } from 'generic/utils/colorUtils';
import { fastGedOpen } from 'generic/core/ged/actions';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const WORDCLOUD_CONCEPTS = {
  'QES_Categorized.verbatim': { field: 30800115, label: 'Concepts' },
  'QES_Environment.verbatim': { field: 30800115, label: 'Concepts Environnement' },
  'QES_Energy.verbatim': { field: 30800115, label: 'Concepts Énergie' },
  'QES_Business.verbatim': { field: 30800115, label: 'Concepts Business' },
  'QES_Politics.verbatim': { field: 30800115, label: 'Concepts Politique' },
  'QES_Justice.verbatim': { field: 30800115, label: 'Concepts Justice' },
  'QES_Innovation.verbatim': { field: 30800115, label: 'Concepts Innovation' },
  'QES_Technology.verbatim': { field: 30800115, label: 'Concepts Technologie' },
  'QES_Culture.verbatim': { field: 30800115, label: 'Concepts Culture' },
  'QES_Education.verbatim': { field: 30800115, label: 'Concepts Éducation' },
  'QES_Security.verbatim': { field: 30800115, label: 'Concepts Sécurité' },
  'QES_Society.verbatim': { field: 30800115, label: 'Concepts Société' },
  'QES_Health.verbatim': { field: 30800115, label: 'Concepts Santé' },
  'QES_Sport.verbatim': { field: 30800115, label: 'Concepts Sport' },
};

const DashboardChartsContainer = () => {
  const strategy = useSelector((state) => state.search.results.strategie);
  const baseId = useSelector((state) => state.search.results.base);
  const [cooccurrenceField, setCooccurrenceField] = useState(['QES_Person.verbatim']);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const {
    DATE_INTERVAL_COMPARATOR,
  } = QES_CONSTANTS;

  const cooccurrenceFields = [
    { name: 'Personnes', value: 'QES_Person.verbatim', idField: 901 },
    { name: 'Sociétés', value: 'QES_Company.verbatim', idField: 1032000431 },
    { name: 'Concepts', value: 'QES_ConceptCategorized.verbatim', idField: 30800115 },
  ];

  const handleChangeCooccurrenceValue = (event) => {
    const {
      target: { value },
    } = event;
    setCooccurrenceField(
      typeof value === 'string' ? value.split(',') : value,
    );
    const keepWidgets = true;
    const joinedValue = value.join(',');
    dispatch(fetchWidgets(strategy, {
      cooccurrences: {
        facets: joinedValue,
        aggregates: [joinedValue],
        facetmax: 200,
        facetmax2: 5,
        mindoccount: 1,
        seriesNames: [joinedValue],
        type: 'networkgraph',
      },
    }, keepWidgets));
  };

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      const joinedValue = cooccurrenceField.join(',');
      dispatch(fetchWidgets(strategy, {
        publicationDates: {
          facets: 'PUB_DATE#auto_date_histogram|300 _key:asc',
          aggregates: ['agg_PUB_DATE'],
          seriesNames: ['Date de publication'],
          type: 'spline',
        },
        sources: {
          facets: 'SOURCE.verbatim',
          aggregates: ['agg_SOURCE.verbatim'],
          type: 'pie',
        },
        publicationAndHarvestDates: {
          facets: 'PUB_DATE#auto_date_histogram|300 _key:asc; DATE_COLLECT#auto_date_histogram|300 _key:asc',
          aggregates: ['agg_PUB_DATE', 'agg_DATE_COLLECT'],
          seriesNames: ['Date de publication', 'Date de collecte'],
          type: 'spline',
        },
        documentStatus: {
          facets: 'ETAT',
          aggregates: ['agg_ETAT'],
          type: 'treemap',
        },
        documentGaugeStatus: {
          facets: 'ETAT',
          aggregates: ['agg_ETAT'],
          type: 'solidgauge',
        },
        sentiments: {
          facets: 'QES_SentimentForce, PUB_DATE#date_histogram _key:asc',
          aggregates: ['agg_QES_SentimentForce'],
          pivots: ['agg_PUB_DATE'],
          type: 'scatter3d',
        },
        conceptOnCountries: {
          facets: 'QES_CountryTopicCode.verbatim, QES_Concept.verbatim',
          aggregates: ['agg_QES_CountryTopicCode.verbatim'],
          pivots: ['agg_QES_Concept.verbatim'],
          seriesNames: ['Concepts cités par pays'],
          type: 'heatmap',
          mindoccount: 1,
          facetmax: 5,
          facetmax2: 5,
        },
        locations: {
          facets: 'QES_Location.verbatim',
          aggregates: ['agg_QES_Location.verbatim'],
          seriesNames: ['Lieux mentionnés'],
          type: 'bar',
        },
        lastPublishedDocuments: {
          additionalQuery: 'ETAT:100',
          slice: 25,
          sort: 'DATE_COLLECT Desc',
          type: 'documentlist',
        },
        countryTopic: {
          facets: 'QES_CountryTopicCode.verbatim',
          aggregates: ['agg_QES_CountryTopicCode.verbatim'],
          type: 'map',
          mindoccount: 0,
          facetmax: 10000,
          facetmax2: 10000,
        },
        conceptsPersons: {
          facets: 'QES_Person.verbatim, QES_Concept.verbatim',
          aggregates: ['agg_QES_Person.verbatim'],
          pivots: ['agg_QES_Concept.verbatim'],
          facetmax: 5,
          facetmax2: 10,
          mindoccount: 1,
          type: 'sankey',
        },
        sourcesByOriginFolders: {
          facets: 'FOLDER_ID.verbatim, SOURCE.verbatim',
          aggregates: ['agg_FOLDER_ID.verbatim'],
          pivots: ['agg_SOURCE.verbatim'],
          list: 1001701270,
          type: 'sunburst',
        },
        persons: {
          facets: 'QES_Person.verbatim',
          aggregates: ['agg_QES_Person.verbatim'],
          type: 'pie',
        },
        companies: {
          facets: 'QES_Company.verbatim',
          aggregates: ['agg_QES_Company.verbatim'],
          type: 'pie',
        },
        concepts: {
          facets: _.keys(WORDCLOUD_CONCEPTS).join(';'),
          aggregates: _.keys(WORDCLOUD_CONCEPTS).map((c) => `agg_${c}`),
          seriesNames: _.map(WORDCLOUD_CONCEPTS, 'label'),
          facetmax: 50,
          facetmax2: 3,
          type: 'wordcloud',
          splitPerSerie: true,
        },
        cooccurrences: {
          facets: joinedValue,
          aggregates: [joinedValue],
          facetmax: 200,
          facetmax2: 5,
          mindoccount: 1,
          seriesNames: [joinedValue],
          type: 'networkgraph',
        },
      }));
    }
  }, [cooccurrenceField, dispatch, strategy]);

  return (
    <Grid
      container
      spacing={1}
      className="desktopOnlyOverflow"
      p={1}
    >
      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="publicationDates" height={200}>
          {({ series: publicationDatesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={ColumnHisto}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: { text: 'Documents publiés par dates' },
                  series: publicationDatesSerie,
                  yAxis: { title: { text: 'Nombre d\'articles' } },
                  legend: false,
                  xAxis: {
                    title: { text: '' },
                    startOnTick: true,
                    endOnTick: true,
                    gridLineWidth: 0,
                    dateTimeLabelFormats: {
                      second: '%d/%m/%Y<br/>%H:%M:%S',
                      minute: '%d/%m/%Y<br/>%H:%M',
                      hour: '%d/%m/%Y<br/>%H:%M',
                      day: '%Y<br/>%d/%m',
                      month: '%m/%Y',
                      year: '%Y',
                    },
                    events: {
                      afterSetExtremes: (e) => {
                        if (e.trigger === 'zoom') {
                          const min = publicationDatesSerie[0].data[0][0];
                          const max = publicationDatesSerie[0].data[
                            publicationDatesSerie[0].data.length - 1
                          ][0];
                          let valmin = e.min;
                          let valmax = e.max;
                          if (valmin < min) {
                            valmin = min;
                          }
                          if (valmax > max) {
                            valmax = max;
                          }
                          dispatch(refineAddFacetValue({
                            champ: 10000029,
                            formatFacet: 'date',
                            comparator: DATE_INTERVAL_COMPARATOR.between,
                            begin: format(new Date(valmin), 'yyyyMMdd'),
                            end: format(new Date(valmax), 'yyyyMMdd'),
                          }));
                        }
                      },
                    },
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="sources">
          {({ series: sourcesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  colors: monoColors(0.35, _.get(sourcesSerie, '[0].data.length', 10), 0),
                  plotOptions: {
                    pie: {
                      data: _.get(sourcesSerie, '[0].data', []),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetValue({ champ: 27, strategie: event.point.options.strategy }));
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: 'Nombre d\'articles' },
                  ],
                  title: { text: 'Sources mentionnées' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={8}>
        <WidgetContainer widgetId="publicationAndHarvestDates">
          {({ series: publicationAndHarvestDatesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Spline}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: { text: 'Documents publiés et collectés par dates' },
                  series: publicationAndHarvestDatesSerie,
                  yAxis: { title: { text: 'Nombre d\'articles' } },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={2}>
        <WidgetContainer widgetId="documentStatus">
          {({ series: documentStatusSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Treemap}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    treemap: {
                      animation: false,
                      colorByPoint: true,
                      dataLabels: {
                        formatter: function labelFormatter() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`ged.document.status.${this.point.name}`)}`;
                        },
                      },
                      tooltip: {
                        pointFormatter: function tooltipFormatter() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`ged.document.status.${this.name}`)}: ${this.value}`;
                        },
                      },
                      layoutStartingDirection: 'horizontal',
                      layoutAlgorithm: 'squarified',
                      point: {
                        events: {
                          click: (e) => {
                            dispatch(refineAddFacetValue({ champ: 110, strategie: e.point.name }));
                          },
                        },
                      },
                    },
                  },
                  series: documentStatusSerie,
                  title: { text: 'États des documents' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={3}>
        <WidgetContainer widgetId="documentGaugeStatus">
          {({ series: documentGaugeStatusSerie, axisX, height }) => (
            <DashboardWidget
              height={height}
              component={Gauge}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  pane: {
                    background: axisX.categories.map((aggregate, index) => ({
                      outerRadius: aggregate.outerRadius,
                      innerRadius: aggregate.innerRadius,
                      borderWidth: 0,
                      backgroundColor: `${Highcharts.getOptions().colors[9 - (index * 2)]}4D`,
                    })),
                  },
                  tooltip: {
                    enabled: false,
                  },
                  legend: {
                    align: 'center',
                    // eslint-disable-next-line func-names, object-shorthand
                    labelFormatter: function () {
                      // eslint-disable-next-line react/no-this-in-sfc
                      return `${t(`ged.document.status.${this.name}`)} - ${this.data[0].y}%`;
                    },
                  },
                  series: _.get(documentGaugeStatusSerie, '[0].data', []).map((aggregate, index) => ({
                    name: aggregate.name,
                    data: aggregate.data.map((aggdata) => ({
                      radius: aggdata.radius,
                      innerRadius: aggdata.innerRadius,
                      name: aggdata.name,
                      y: aggdata.y,
                      color: Highcharts.getOptions().colors[9 - (index * 2)],
                    })),
                    color: Highcharts.getOptions().colors[9 - (index * 2)],
                    showInLegend: true,
                  })),
                  title: { text: 'États des documents' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={7}>
        <WidgetContainer widgetId="sentiments">
          {({ series: sentimentsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Scatter3d}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: { text: 'Tonalité par dates de publication' },
                  series: sentimentsSerie,
                  plotOptions: {
                    series: {
                      zoneAxis: 'z',
                      // on calcule un interval de couleur à partir de la valeur min / max des sentiements
                      // rapportés au nombre de couleurs du thème courant
                      zones: _.range(-4, 5, 1).map((v, i, arr) => ({
                        value: v,
                        color: Highcharts.getOptions().colors[
                          Math.ceil((i / arr.length) * Highcharts.getOptions().colors.length)
                        ],
                      })),
                    },
                  },
                  xAxis: {
                    title: { text: 'Date de publication' },
                    min: _.get(sentimentsSerie, '[0].custom.minMaxX[0]', 0),
                    max: _.get(sentimentsSerie, '[0].custom.minMaxX[1]', 1),
                  },
                  yAxis: {
                    title: { text: 'Nombre de documents' },
                    min: _.get(sentimentsSerie, '[0].custom.minMaxY[0]', 0),
                    max: _.get(sentimentsSerie, '[0].custom.minMaxY[1]', 1),
                  },
                  zAxis: {
                    min: -4,
                    max: 4,
                    title: { text: 'Tonalité' },
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="conceptOnCountries">
          {({
            series: conceptOnCountriesSerie,
            axisX,
            axisY,
            height,
          }) => (
            <DashboardWidget
              height={height}
              component={Heatmap}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: { text: 'Concepts par pays' },
                  series: conceptOnCountriesSerie,
                  xAxis: {
                    categories: axisX
                      .categories
                      .map((countryCode) => t(`dashboard.countries.${countryCode}`)),
                  },
                  yAxis: {
                    categories: axisY.categories,
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="countryTopic">
          {({ series: countryTopicSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Map}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      tooltip: {
                        pointFormatter: function getTranslatedCountryName() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`dashboard.countries.${this['iso-a2']}`)}: <b>${this.value}</b>`;
                        },
                      },
                    },
                  },
                  series: countryTopicSerie,
                  title: { text: 'Les pays cités' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="lastPublishedDocuments">
          {({ series: lastPublishedDocs, height }) => (
            <DashboardWidget
              height={height}
              component={DocumentList}
              componentProps={{
                title: 'Derniers documents publiés',
                qesdocuments: _.get(lastPublishedDocs, '[0]', []),
                handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, baseId)),
                handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, baseId)),
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <WidgetContainer widgetId="locations">
          {({ series: locationsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Bar}
              highchartsOptions={{
                // F_902
                options: {
                  chart: {
                    height,
                  },
                  title: { text: 'Lieux mentionnés dans les documents' },
                  series: locationsSerie,
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <WidgetContainer widgetId="conceptsPersons">
          {({ series: conceptsPersonsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Sankey}
              highchartsOptions={{
                // Concepts : F_30800115
                // Person : F_901
                options: {
                  chart: {
                    height,
                  },
                  series: conceptsPersonsSerie,
                  title: { text: 'Personnes et concepts' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="sourcesByOriginFolders">
          {({ series: sourcesByOriginFoldersSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Sunburst}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    sunburst: {
                      point: {
                        events: {
                          click: (e) => {
                            if (e.point.name && e.point.node.isLeaf) {
                              dispatch(refineAddFacetValue({ champ: 27, strategie: e.point.name }));
                            } else if (e.shiftKey && e.point.node.index !== 0) {
                              dispatch(refineAddFacetValue({ champ: 1032000564, strategie: e.point.parent }));
                            }
                          },
                        },
                      },
                    },
                  },
                  series: [{
                    data: _.get(sourcesByOriginFoldersSerie, '[0].data', []),
                  }],
                  title: { text: 'Sources par dossiers d\'origine' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="companies">
          {({ series: companiesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: _.get(companiesSerie, '[0].data', []),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetValue(
                              { champ: 1032000431, strategie: event.point.options.strategy },
                            ));
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: 'Nombre d\'articles pour cette société' },
                  ],
                  title: { text: 'Sociétés mentionnées' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="persons">
          {({ series: personsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: _.get(personsSerie, '[0].data', []),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetValue({ champ: 901, strategie: event.point.options.strategy }));
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: 'Nombre d\'articles pour cette personne' },
                  ],
                  title: { text: 'Personnes mentionnées' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      { _.entries(WORDCLOUD_CONCEPTS).map(([fieldName, fieldDefinition]) => (
        <Grid item xs={12} sm={6} md={fieldName === 'Categorized' ? 8 : 4} key={`concepts${fieldName}`}>
          <WidgetContainer widgetId={`concepts_${fieldDefinition.label}`}>
            {({ series: conceptsSerie, height }) => (
              <DashboardWidget
                height={height}
                component={Wordcloud}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      wordcloud: {
                        point: {
                          events: {
                            click: (event) => {
                              dispatch(refineAddFacetValue(
                                {
                                  champ: fieldDefinition.field,
                                  strategie: event.point.options.name,
                                },
                              ));
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      data: _.map(conceptsSerie.data, ([word, weight]) => ({
                        name: word,
                        weight,
                        color: getColorForFrequency(0.35, conceptsSerie.minFreq, conceptsSerie.maxFreq, weight, 0),
                      })),
                      name: conceptsSerie.name,
                    }],
                    title: { text: conceptsSerie.name },
                  },
                }}
              />
            )}
          </WidgetContainer>
        </Grid>
      ))}

      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="cooccurrences" height={600}>
          {({ series: cooccurrenceCompany, height }) => (
            <Box position="relative">
              <Select
                onChange={handleChangeCooccurrenceValue}
                startAdornment={
                  <InputAdornment position="start">champs</InputAdornment>
                }
                multiple
                value={cooccurrenceField}
                sx={{
                  ml: 1,
                  mt: 1,
                  position: 'absolute',
                  bottom: smallerThanLarge ? '8px' : 'unset',
                  zIndex: 1,
                }}
                variant="outlined"
                renderValue={
                  (selected) => selected.map(
                    (v) => _.find(cooccurrenceFields, { value: v }).name,
                  ).join(', ')
                }
              >
                {cooccurrenceFields.map((field) => (
                  <MenuItem key={field.name} value={field.value}>
                    <Checkbox checked={cooccurrenceField.indexOf(field.value) > -1} />
                    <ListItemText primary={field.name} />
                  </MenuItem>
                ))}
              </Select>
              <DashboardWidget
                height={height}
                component={NetworkGraph}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      networkgraph: {
                        layoutAlgorithm: {
                          enableSimulation: true,
                        },
                      },
                      series: {
                        point: {
                          events: {
                            click: (e) => {
                              const { idField } = _.find(cooccurrenceFields, { value: e.point.options.group });
                              if (idField) {
                                dispatch(refineAddFacetValue({
                                  champ: idField,
                                  strategie: e.point.name,
                                }));
                              }
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      link: {
                        width: 1,
                      },
                      dataLabels: {
                        enabled: true,
                        linkFormat: '',
                      },
                      nodes: _.get(cooccurrenceCompany, '[0].nodes', []),
                      data: _.get(cooccurrenceCompany, '[0].data', []),
                    }],
                    title: { text: 'Graphe de cooccurrences' },
                  },
                }}
              />
            </Box>
          )}
        </WidgetContainer>
      </Grid>
    </Grid>
  );
};

export default React.memo(DashboardChartsContainer);
