import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AccountBalance,
  Place,
  Label,
  AccountCircle,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ExpandableTagBox from 'generic/components/ui/ExpandableTagBox';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { overrideRessource } from 'generic/utils/utils';

const { default: useDocumentFields } = overrideRessource('core/hooks/document', true);

const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const useStyles = makeStyles(() => ({
  itemImage: {
    objectFit: 'cover',
    display: 'block',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

const Document = ({
  document,
  displayTitle,
  context,
  handleGetDocumentComplete,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const {
    title, linkout, date, status, thumb, snippet, text, source,
  } = useDocumentFields(document);

  const { qesdocument } = document;

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }
  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word' }}>
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          fontSize="1.2rem"
          margin="6px 0"
          display="block"
        >
          <Box
            {...titleLinkProps}
            sx={{ cursor: 'pointer' }}
            color="text.dark"
          >
            {title}
          </Box>
        </Typography>
      )}
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow="1">
          {!smallerThanMedium && thumb && (
            <Box mr={1} mb={1} sx={{ float: 'left' }}>
              <ImgLoader
                src={thumb}
                fallbackComponent={(
                  <ImageNotFound
                    height={60}
                    width={100}
                  />
                )}
                className={classes.itemImage}
                skeletonClassName={classes.itemImage}
                height={60}
                width={100}
                loading="lazy"
              />
            </Box>
          )}
          <Box lineHeight="1.3rem">
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              {date && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.neutral"
                >
                  {date}
                </Typography>
              )}
              {status > 0 && (
                <Fragment>
                  {date && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t(`ged.document.status.${status}`)}
                  </Typography>
                </Fragment>
              )}
              {source && (
                <Fragment>
                  {(status > 0 || date) && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {source}
                  </Typography>
                </Fragment>
              )}
              {CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete' && linkout && (
                <Fragment>
                  {source && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Link
                    href={linkout}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      {t('actions.open_in_new_tab')}
                    </Typography>
                  </Link>
                </Fragment>
              )}
            </Box>
            {context !== 'resultComplete' && (
              <div>
                {snippet}
              </div>
            )}
            <ExpandableTagBox
              disableRefine={['cart', 'newsletter'].includes(context)}
              qesdocument={qesdocument}
              tags={[
                { fieldName: 'QES_Company', icon: AccountBalance, fieldId: 1032000431 },
                { fieldName: 'QES_Person', icon: AccountCircle, fieldId: 901 },
                { fieldName: 'QES_Location', icon: Place, fieldId: 902 },
                { fieldName: 'QES_ConceptCategorized', icon: Label, fieldId: 30800115 },
              ]}
            />
            {context === 'resultComplete' && (
              <Box mt={1} mb={2} whiteSpace="pre-wrap" sx={{ clear: 'both' }}>
                {text}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Document.propTypes = {
  context: PropTypes.oneOf(['cart', 'newsletter', 'result', 'resultComplete']).isRequired,
  displayTitle: PropTypes.bool,
  document: PropTypes.shape(),
  handleGetDocumentComplete: PropTypes.func,
};

Document.defaultProps = {
  document: {},
  displayTitle: true,
  handleGetDocumentComplete: null,
};

export default Document;
