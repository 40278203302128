import { types } from 'generic/core/config/actions';

const configReducer = (state = { form: {}, loading: false }, action) => {
  switch (action.type) {
    case types.SET_CONFIG: {
      return {
        ...state,
        bases: action.config.bases,
        settings: action.config.parametres,
        themesPanier: action.config.themesPanier,
      };
    }
    case types.FETCH_FORM_CONFIG: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.SET_ACTIVE_BASE: {
      return {
        ...state,
        activeBase: action.base,
      };
    }
    case types.SET_FORM_FIELDS: {
      return {
        ...state,
        form: {
          champs: action.fields,
        },
      };
    }
    case types.FETCH_FORM_CONFIG_SUCCESS: {
      return {
        ...state,
        form: action.results,
        loading: false,
      };
    }
    case types.FETCH_FORM_CONFIG_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default configReducer;
