import React, { Fragment, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import {
  AppBar,
  Box,
  ClickAwayListener,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  Dashboard,
  FormatListBulleted,
  Home,
  Menu,
  Notifications,
  PowerSettingsNew,
  QueryStats,
} from '@mui/icons-material';
import clsx from 'clsx';
import {
  Link, NavLink, useHistory, useLocation,
} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ToggleMobileSearchIconContainer from 'generic/containers/ToggleMobileSearchIconContainer';
import DropdownMenu from 'generic/components/ui/DropdownMenu';

import { fetchResults } from 'generic/core/search/actions';
import UploadFilesToCreateDocumentsDialogContainer
  from 'generic/containers/UploadFilesToCreateDocumentsDialogContainer';
import { overrideRessource } from 'generic/utils/utils';
import HeaderSearchWrapper from 'generic/components/ui/HeaderSearchWrapper';

const logo = overrideRessource('img/header-logo.svg', true);

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiToolbar-root': {
      paddingLeft: '0',
      paddingRight: '20px',
    },
    '& .MuiMenuItem-root': {
      lineHeight: '1.2rem',
    },
  },
  linkLogo: {
    marginLeft: '0',
    height: '64px',
    backgroundColor: '#0097a7',
    padding: '0 50px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
  },
  logo: {
    height: '38px',
    width: '60px',
  },
  companySelect: {
    marginLeft: '16px',
    color: '#ffffff',
    [`& .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
      borderColor: '#ffffff',
      borderWidth: '1px',
    },
    '& .MuiSvgIcon-root': {
      fill: '#ffffff',
    },
  },
}));

// eslint-disable-next-line react/prop-types
const LinksMenu = ({ userIsContributor }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Fragment>
      <MenuItem
        component={NavLink}
        to="/home"
        selected={location.pathname === '/home'}
      >
        <ListItemIcon>
          <Home fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t('header.home')}
        </ListItemText>
      </MenuItem>
      {!userIsContributor && (
        <MenuItem
          component={NavLink}
          to="/global"
          selected={location.pathname === '/global'}
        >
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('header.global')}</ListItemText>
        </MenuItem>
      )}
      <MenuItem
        component={NavLink}
        to="/alerts"
        selected={location.pathname.startsWith('/alerts')}
      >
        <ListItemIcon>
          <Notifications fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('header.alerts')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        component={NavLink}
        to={`/dashboard${location?.search || ''}`}
        selected={location.pathname === '/dashboard'}
      >
        <ListItemIcon>
          <QueryStats fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t('header.dashboard')}
        </ListItemText>
      </MenuItem>
      <MenuItem
        component={NavLink}
        to={`/results${location?.search || ''}`}
        selected={location.pathname === '/results'}
      >
        <ListItemIcon>
          <FormatListBulleted fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('header.results')}</ListItemText>
      </MenuItem>
    </Fragment>
  );
};

const Header = () => {
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const userIsContributor = useSelector((state) => state.auth.user.isContributor);
  const { t } = useTranslation();

  useEffect(() => {
    setIsSearchInputVisible(!smallerThanLarge);
  }, [smallerThanLarge]);

  const forceHideMenusForMobile = smallerThanLarge && isSearchInputVisible;
  const forceShowMenusForMobile = smallerThanLarge && !isSearchInputVisible;

  const handleClickSearch = (baseId, statusValue) => {
    history.push('/results');
    dispatch(fetchResults({
      bodyItems: {
        premier: 1,
        dernier: 50,
        base: baseId,
        champs: {
          F_110: statusValue,
        },
      },
      refreshForm: true,
      clearSelection: true,
      clearResults: true,
    }));
  };

  return (
    <Fragment>
      <ClickAwayListener
        onClickAway={() => (forceHideMenusForMobile ? setIsSearchInputVisible(false) : _.noop)}
        mouseEvent="onMouseDown"
      >
        <Box width="100%" className={classes.root}>
          <AppBar elevation={0} position="static">
            <Toolbar
              className={clsx('displayFlex', 'justifyContentSpaceBetween')}
            >
              <Fragment>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Link
                    to="/home"
                    className={classes.linkLogo}
                    style={{ display: forceHideMenusForMobile ? 'none' : 'flex' }}
                  >
                    <img
                      src={logo}
                      className={classes.logo}
                      alt="Qwam Logo"
                    />
                  </Link>

                  {smallerThanLarge && (
                    <DropdownMenu
                      btnChildren={<Menu />}
                      btnProps={{
                        sx: {
                          display: forceShowMenusForMobile ? 'flex' : 'none',
                          padding: '8px',
                          color: '#ffffff',
                        },
                      }}
                    >
                      <LinksMenu handleClickSearch={handleClickSearch} userIsContributor={userIsContributor} />
                    </DropdownMenu>
                  )}

                  {!smallerThanLarge && (
                    <Fragment>
                      <MenuItem
                        component={NavLink}
                        to="/home"
                        selected={location.pathname === '/home'}
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Trans>{t('header.home') }</Trans>
                      </MenuItem>
                      {!userIsContributor && (
                        <MenuItem
                          component={NavLink}
                          to="/global"
                          selected={location.pathname === '/global'}
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Trans>{t('header.global') }</Trans>
                        </MenuItem>
                      )}
                      <MenuItem
                        component={NavLink}
                        to="/alerts"
                        selected={location.pathname.startsWith('/alerts')}
                      >
                        {t('header.alerts')}
                      </MenuItem>
                    </Fragment>
                  )}
                </Box>

                <HeaderSearchWrapper showSearchInput={isSearchInputVisible} />
                <Box display="flex" alignItems="center">
                  <ToggleMobileSearchIconContainer
                    handleClick={() => setIsSearchInputVisible(true)}
                    isVisible={forceShowMenusForMobile}
                  />
                  <Box
                    sx={{
                      display: forceHideMenusForMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <Tooltip
                      title={t('account_menu.logout')}
                    >
                      <MenuItem
                        component={NavLink}
                        to="/logout"
                      >
                        <PowerSettingsNew fontSize="small" />
                      </MenuItem>
                    </Tooltip>
                  </Box>
                </Box>
              </Fragment>
            </Toolbar>
          </AppBar>
        </Box>
      </ClickAwayListener>
      <UploadFilesToCreateDocumentsDialogContainer
        baseId={22801208}
        baseMisePageId={7936310}
      />
    </Fragment>
  );
};

export default Header;
