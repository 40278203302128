import {
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';
import { snackActions } from 'generic/utils/snackbar';
import {
  submitExportError,
  submitExportSuccess,
  types,
} from 'generic/core/export/actions';
import { submitExport } from 'generic/api/export';
import { closeDialogExport } from 'generic/core/actions/actions';
import { clearSelection, clearSingleItem } from 'generic/core/selection/actions';
import QES_CONSTANTS from 'generic/core/qes/constants';
import {
  getTokenFromStorage,
  getLogonFromStorage,
} from 'generic/utils/utils';

const { API_ENTRY_POINT } = QES_CONSTANTS;

function* workSubmitExport({ params }) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let documentitemIds;
    if (params.scope === 'cart' && params.cartId !== 1000) {
      documentitemIds = params.idDocs;
    } else if (singleItem) {
      documentitemIds = [singleItem.documentitem];
    } else {
      const checkedItems = yield select((state) => state.selection.checkedItems);
      documentitemIds = _.map(checkedItems, 'documentitem');
    }

    const apiParams = {
      format: params.format,
      champ: params.champ,
      documentitem: documentitemIds,
      massif: params.massif,
      nombre: params.nombre,
    };

    if (params.scope === 'cart' && params.cartId !== 1000) {
      apiParams.panier = params.cartId;
    } else {
      const { mouvement } = yield select((state) => state.search.results);
      apiParams.mouvement = mouvement;
    }
    const results = yield call(submitExport, { uriParams: apiParams });
    if (params.massif) {
      snackActions.info(i18next.t('export.massive_export_conf'));
    } else {
      const url = new URL(`${API_ENTRY_POINT}/export`);
      const obj = {
        key: getTokenFromStorage(),
        logon: getLogonFromStorage(),
        fichier: results.response.fichier,
      };
      url.search = new URLSearchParams(obj);
      window.open(url, '_self').focus();
      snackActions.info(i18next.t('export.docs_exported', { count: documentitemIds.length }));
    }

    if (_.isEmpty(singleItem)) {
      yield put(clearSelection());
    } else {
      yield put(clearSingleItem());
    }

    yield put(closeDialogExport());
    yield put(submitExportSuccess());
  } catch (error) {
    yield put(submitExportError(error));
    console.error(error);
    snackActions.error(i18next.t('export.error_exporting'));
  }
}

function* watchSubmitExport() {
  yield takeLatest(types.EXPORT_SUBMIT, workSubmitExport);
}

export default {
  watchSubmitExport,
};
