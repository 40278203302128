import Highcharts from 'highcharts';

/**
 * retourne une couleur, plus la valeur sera proche de min, et plus elle sera transparente,
 * plus elle sera proche de max et plus elle sera opaque
 * @param {int} minOpacity le seuil d'opacitée minimum
 * @param {int} minFrequence la valeur minimum
 * @param {int} maxFrequence la valeur maximum
 * @param {int} currentFrequence la valeur actuel
 * @param {int} indexColor l'index de la couleur à prendre dans le thème Highcharts
 * @returns retourne une couleur rgba
 */
export const getColorForFrequency = (minOpacity, minFrequence, maxFrequence, currentFrequence, indexColor) => {
  const base = Highcharts.getOptions().colors[indexColor];
  const ratio = minOpacity + ((currentFrequence - minFrequence) * (1 - minOpacity)) / (maxFrequence - minFrequence);
  return Highcharts.color(base).setOpacity(ratio).get();
};

/**
 * Permet de calculer un tableau de couleurs, dégradées, la couleur de référence se base sur le thème
 * La fonction s'assure de ne pas générer de couleurs au dessous d'un seil afin d'éviter des couleurs illisibles
 * @param {int} minOpacity le seuil d'opacitée minimum
 * @param {int} maxColors le nombre de couleurs à calculer
 * @param {int} indexColor l'index de la couleur à prendre dans le thème Highcharts
 * @returns un tableau de dégradé de couleur couleurs en dégradé
 */
export const monoColors = (minOpacity, maxColors, indexColor) => {
  const base = Highcharts.getOptions().colors[indexColor];
  const colors = Array(maxColors)
    .fill()
    .map((__, i) => {
      const ratio = ((i + 1) * 100) / maxColors;
      const opacity = ratio + (minOpacity * (100 - ratio));
      return Highcharts.color(base).setOpacity(opacity / 100).get();
    })
    .reverse();

  return colors;
};
