import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { restoreStateFromStorage, login } from 'generic/core/auth/actions';
import Login from 'generic/components/pages/Login';

const AuthenticationContainer = () => {
  // Selectors
  const loading = useSelector((state) => state.auth.loading);
  const loginError = useSelector((state) => state.auth.loginError);
  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(restoreStateFromStorage());
  }, [dispatch]);

  const handleAuthSubmit = (values) => {
    const { logon, password } = values;
    dispatch(login({ logon, password }));
  };

  return (
    <Login
      onSubmit={handleAuthSubmit}
      loading={loading}
      loginError={loginError}
    />
  );
};

export default React.memo(AuthenticationContainer);
