import React, { useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import _ from 'lodash';

import {
  Grid,
  useMediaQuery,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import QES_CONSTANTS from 'generic/core/qes/constants';
import THEME_CONFIG from 'generic/core/theme';
import { fetchResults, fetchResultsComplete } from 'generic/core/search/actions';
import { fetchWidgets } from 'generic/core/dashboard/actions';

import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import DocumentList from 'generic/components/dashboard-items/DocumentList';
import WidgetContainer from 'generic/containers/WidgetContainer';
import GEDModalContainer from 'generic/containers/GEDModalContainer';
import { fastGedOpen } from 'generic/core/ged/actions';
import Bar from 'generic/components/dashboard-items/Bar';
import SolidGauge from 'generic/components/dashboard-items/SolidGauge';
import Pie from 'generic/components/dashboard-items/Pie';

const { stateDocumentColor, scoreDocumentColor } = THEME_CONFIG.HIGHCHARTS;
const { DEFAULT_SEARCH_FULL_BASE_UNPARSED } = QES_CONSTANTS;

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const widgetsForAllScreens = {
  toAnswerDocuments: {
    additionalQuery: 'ETAT:1',
    slice: 500,
    sort: 'DATE_COLLECT Desc',
    type: 'documentlist',
  },
  toValidateDocuments: {
    additionalQuery: 'ETAT:50',
    slice: 500,
    sort: 'DATE_COLLECT Desc',
    type: 'documentlist',
  },
  toCompleteDocuments: {
    additionalQuery: 'ETAT:70',
    slice: 500,
    sort: 'DATE_COLLECT Desc',
    type: 'documentlist',
  },
};
const widgetsForLargeScreens = {
  documentGaugeStatus: {
    facets: 'i_ETAT _key:asc',
    aggregates: ['agg_i_ETAT'],
    type: 'solidgauge',
  },
  controlTheme: {
    facets: 'Theme_controle.verbatim',
    aggregates: ['agg_Theme_controle.verbatim'],
    facetmax: 50,
    type: 'pie',
  },
  scores: {
    facets: 'Theme_controle.verbatim,Scoring',
    additionalQuery: 'Applicabilite:true',
    aggregates: ['agg_Theme_controle.verbatim'],
    pivots: ['agg_Scoring'],
    facetmax: 15,
    facetmax2: 5,
    type: 'bar_stacked',
  },
};
const widgetsToFetch = widgetsForAllScreens;
Object.assign(widgetsToFetch, widgetsForLargeScreens);

const HomeContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();

  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const knowledgeBase = useSelector((state) => state.config.settings.baseInterne_368);
  const userIsContributor = useSelector((state) => state.auth.user.isContributor);
  const userCompanies = useSelector((state) => _.get(state, 'auth.user.companies', []));

  const [activeCompany, setActiveCompany] = useState(userCompanies[0]);
  const [strategy, setStrategy] = useState(null);

  useEffect(() => {
    if (userIsContributor) {
      setStrategy({
        baseQuery: DEFAULT_SEARCH_FULL_BASE_UNPARSED,
      });
    } else if (activeCompany) {
      setStrategy({
        baseQuery: `Societe:${activeCompany}`,
      });
    }
  }, [userIsContributor, activeCompany]);

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      dispatch(fetchWidgets(Object.values(strategy).join(' AND '), widgetsToFetch, true, knowledgeBase));
    }
  }, [strategy, knowledgeBase, dispatch]);

  const raffinageParams = {
    premier: 1,
    dernier: 50,
    tranche: 50,
    base: knowledgeBase,
  };

  if (!strategy) {
    return null;
  }
  return (
    <Grid
      container
      flexWrap="nowrap"
    >
      <Grid
        item
        sx={{
          overflowY: 'scroll', display: 'flex', flexDirection: 'column', flex: 1,
        }}
      >
        <WidgetContainer widgetId="toAnswerDocuments" fullHeight>
          {({ series: toAnswerDocs }) => (
            <DashboardWidget
              flat
              component={DocumentList}
              componentProps={{
                title: t('ged.document.status.1'),
                qesdocuments: toAnswerDocs[0],
                handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, knowledgeBase)),
                handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, knowledgeBase)),
                groupField: 'Theme_controle',
                fullHeight: true,
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid
        item
        sx={{
          overflowY: 'scroll', display: 'flex', flexDirection: 'column', flex: 1,
        }}
      >
        <WidgetContainer widgetId="toValidateDocuments" fullHeight>
          {({ series: toValidateDocs }) => (
            <DashboardWidget
              flat
              component={DocumentList}
              componentProps={{
                title: t('ged.document.status.50'),
                qesdocuments: toValidateDocs[0],
                handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, knowledgeBase)),
                handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, knowledgeBase)),
                groupField: 'Theme_controle',
                fullHeight: true,
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid
        item
        sx={{
          overflowY: 'scroll', display: 'flex', flexDirection: 'column', flex: 1,
        }}
      >
        <WidgetContainer widgetId="toCompleteDocuments" fullHeight>
          {({ series: toCompleteDocs }) => (
            <DashboardWidget
              flat
              component={DocumentList}
              componentProps={{
                title: t('ged.document.status.1001'),
                qesdocuments: toCompleteDocs[0],
                handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, knowledgeBase)),
                handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, knowledgeBase)),
                groupField: 'Theme_controle',
                fullHeight: true,
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      {isLarge && (
        <Grid
          item
          flex={1}
          sx={{
            overflowY: 'scroll', rowGap: '5px', display: 'flex', flexDirection: 'column',
          }}
        >
          {!userIsContributor && (
            <FormControl sx={{
              width: '95%',
              mx: 'auto',
              mt: '16px',
              mb: '8px',
            }}
            >
              <InputLabel id="select_company">{t('safran.dashboard.company')}</InputLabel>
              <Select
                labelId="select_company"
                label={t('safran.dashboard.company')}
                value={activeCompany}
                onChange={(e) => setActiveCompany(e.target.value)}
              >
                {_.map(userCompanies, (company) => (
                  <MenuItem key={company} value={company}>{company}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <WidgetContainer widgetId="documentGaugeStatus" height={300} flat>
            {({ series: documentGaugeStatusSerie, axisX, height }) => (
              <DashboardWidget
                flat
                height={height}
                component={SolidGauge}
                highchartsOptions={{
                  options: {
                    exporting: {
                      enabled: false,
                    },
                    chart: {
                      height,
                    },
                    pane: {
                      background: axisX.categories.map((aggregate, index) => ({
                        outerRadius: aggregate.outerRadius,
                        innerRadius: aggregate.innerRadius,
                        borderWidth: 0,
                        backgroundColor: `${stateDocumentColor[documentGaugeStatusSerie[0].data[index].name]}4D`,
                      })),
                    },
                    tooltip: {
                      enabled: false,
                    },
                    legend: {
                      labelFormatter() {
                        const context = this;
                        return `${t(`ged.document.status.${context.name}`)} - ${context.data[0].y}%`;
                      },
                      layout: 'vertical',
                      align: 'right',
                      verticalAlign: 'bottom',
                      itemMarginBottom: 10,
                    },
                    series: documentGaugeStatusSerie[0].data.map((aggregate) => ({
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(fetchResults({
                              bodyItems: {
                                ...raffinageParams,
                                champs: { F_110: event.point.name },
                              },
                              refreshForm: true,
                              clearResults: true,
                              clearSelection: true,
                            }));
                            history.push('/results');
                          },
                        },
                      },
                      name: aggregate.name,
                      data: aggregate.data.map((aggdata) => ({
                        radius: aggdata.radius,
                        innerRadius: aggdata.innerRadius,
                        name: aggdata.name,
                        y: aggdata.y,
                        color: stateDocumentColor[aggdata.name],
                      })),
                      color: stateDocumentColor[aggregate.name],
                      showInLegend: true,
                    })),
                    title: { text: t('safran.dashboard.progression') },
                  },
                }}
              />
            )}
          </WidgetContainer>

          <WidgetContainer widgetId="controlTheme" height={320} flat>
            {({ series: controlThemeSerie, height }) => (
              <DashboardWidget
                flat
                height={height}
                component={Pie}
                highchartsOptions={{
                  options: {
                    exporting: {
                      enabled: false,
                    },
                    chart: {
                      height,
                      events: {
                        // eslint-disable-next-line func-names
                        render: strategy.controlTheme ? function () {
                          const chart = this;
                          chart.renderer.button(
                            t('safran.dashboard.cancel_filter'),
                            5,
                            5,
                            () => setTimeout(() => setStrategy(_.omit(strategy, ['controlTheme'])), 0),
                          ).attr({
                            padding: 5,
                          }).add(chart.customButtonGroup);
                        } : _.noop,
                      },
                    },
                    plotOptions: {
                      pie: {
                        data: controlThemeSerie[0].data,
                        point: {
                          events: {
                            click: (event) => {
                              setStrategy({
                                ...strategy,
                                controlTheme: `Theme_controle:(${event.point.name})`,
                              });
                            },
                          },
                        },
                      },
                    },
                    series: [
                      { name: '' },
                    ],
                    title: { text: t('safran.dashboard.control_topics') },
                  },
                }}
              />
            )}
          </WidgetContainer>

          {!userIsContributor && (
            <WidgetContainer widgetId="scores" flat>
              {({ series: scoresSeries, axisX, height }) => (
                <DashboardWidget
                  flat
                  height={height}
                  component={Bar}
                  highchartsOptions={{
                    options: {
                      title: { text: t('safran.dashboard.topics_and_scores') },
                      exporting: {
                        enabled: false,
                      },
                      chart: {
                        height,
                        events: {
                          // eslint-disable-next-line func-names
                          render: (strategy.controlTheme || strategy.scoring) ? function () {
                            const chart = this;
                            chart.renderer.button(
                              t('safran.dashboard.cancel_filter'),
                              5,
                              5,
                              () => setTimeout(
                                () => setStrategy(_.omit(strategy, ['controlTheme', 'scoring', 'applicabilite'])),
                                0,
                              ),
                            ).attr({
                              padding: 5,
                            }).add(chart.customButtonGroup);
                          } : _.noop,
                        },
                      },
                      plotOptions: {
                        series: {
                          stacking: 'normal',
                          point: {
                            events: {
                              click() {
                                const context = this;
                                setStrategy({
                                  ...strategy,
                                  controlTheme: `Theme_controle:(${context.category})`,
                                  scoring: `Scoring:(${context.series.name})`,
                                  applicabilite: 'Applicabilite:true',
                                });
                              },
                            },
                          },
                        },
                      },
                      xAxis: {
                        categories: axisX.categories,
                        labels: {
                          formatter() {
                            const context = this;
                            if (context.value.length > 20) {
                              return `${context.value.substring(0, 20)}...`;
                            }
                            return context.value;
                          },
                        },
                        title: { text: '' },
                      },
                      yAxis: {
                        min: 0,
                        title: { text: t('safran.dashboard.questions_count') },
                      },
                      series: scoresSeries.map((serie) => ({
                        ...serie,
                        color: scoreDocumentColor[serie.name],
                        legendIndex: +serie.name,
                        events: {
                          legendItemClick(event) {
                            event.preventDefault();
                            setTimeout(() => setStrategy({
                              ...strategy,
                              scoring: `Scoring:(${event.target.name})`,
                              applicabilite: 'Applicabilite:true',
                            }), 0);
                          },
                        },
                      })).sort((a, b) => b.name.localeCompare(a.name)),
                      legend: {
                        align: 'center',
                      },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          )}
        </Grid>
      )}

      <GEDModalContainer
        afterSave={() => {
          dispatch(fetchWidgets(Object.values(strategy).join(' AND '), widgetsToFetch, true, knowledgeBase));
        }}
      />
    </Grid>
  );
};

export default HomeContainer;
