import {
  fetchUnansweredFilesError,
  fetchUnansweredFilesSuccess,
  types,
} from 'custom/safran/core/redux/actions';
import { searchResult } from 'generic/api/dashboard';
import { snackActions } from 'generic/utils/snackbar';
import _ from 'lodash';
import i18next from 'i18next';
import {
  call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { getValueOrFirstValueFromArray } from 'generic/utils/qesUtils';
import { generateReport } from 'generic/api/export';
import { format } from 'date-fns';

function* workFetchUnansweredFiles() {
  try {
    const baseId = yield select((state) => state.config.settings.baseInterne_368);
    const params = {
      baseId,
      slice: 200,
      sort: 'Ordre_question ASC',
      query: 'NOT (ETAT:100)',
    };

    const results = yield call(searchResult, params);
    yield put(fetchUnansweredFilesSuccess(_.get(results, 'response.data.docs', [])));
  } catch (error) {
    yield put(fetchUnansweredFilesError(error));
    console.error(error);
    snackActions.error(i18next.t('safran.report.error_getting_unanswered_filelist'));
  }
}

function* watchFetchUnansweredFiles() {
  yield takeEvery(types.FETCH_UNANSWERED_FILES, workFetchUnansweredFiles);
}

function* workGenerateReport({ isTest }) {
  const baseId = yield select((state) => state.config.settings.baseInterne_368);
  const params = {
    baseId,
    slice: 500,
    sort: 'Ordre_question ASC',
    query: isTest ? 'tout:(*)' : 'NOT (ETAT:100)',
  };

  const results = yield call(searchResult, params);
  const docs = _.get(results, 'response.data.docs');

  const { nom, prenom } = yield select((state) => state.auth.user);

  const report = [];
  report.push('---');
  report.push('title: AFA REPORT');
  report.push(`author: ${prenom} ${nom}`);
  report.push(`date: ${format(new Date())}`);
  report.push('---');
  report.push('# QUESTIONS');

  docs.forEach((qesdocument, index) => {
    const question = getValueOrFirstValueFromArray(qesdocument?.Question);
    const reponse = getValueOrFirstValueFromArray(qesdocument?.Reponse);

    report.push(`**${index + 1}. ${question}**`);
    report.push('');
    report.push(reponse);
    report.push('');
  });

  const reportRes = yield call(generateReport, { fileUpload: true, bodyItems: report.join('\n') });
  const url = _.get(reportRes, 'urlRedirection', false);
  if (url) window.open(url, '_blank');
}

function* watchGenerateReport() {
  yield takeLatest(types.GENERATE_REPORT, workGenerateReport);
}

export default {
  watchFetchUnansweredFiles,
  watchGenerateReport,
};
