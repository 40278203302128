import React, { lazy, useRef } from 'react';
import {
  Switch,
  Redirect,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'i18n';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from '@mui/material';
import {
  Clear,
} from '@mui/icons-material';
import { SnackbarUtilsConfigurator } from 'generic/utils/snackbar';
import configureStore, { history } from 'generic/core/store';

import AuthenticationContainer from 'generic/containers/auth/AuthenticationContainer';
import ConfigWrapper from 'generic/containers/ConfigWrapper';
import ConfirmDialogContainer from 'generic/containers/ConfirmDialogContainer';
import LogoutContainer from 'generic/containers/auth/LogoutContainer';
import ProtectedRoutes from 'generic/components/routes/ProtectedRoutes';
import PublicRoutes from 'generic/components/routes/PublicRoutes';
import ResultsWrapper from 'generic/containers/ResultsWrapper';
import AlertsContainer from 'generic/containers/AlertsContainer';
import AlertContainer from 'generic/containers/AlertContainer';
import { CONSTANTS } from 'generic/core/constants';

import ReportContainer from 'custom/safran/components/pages/ReportContainer';

const GEDContainerHOC = lazy(() => import('generic/components/pages/GEDContainerHOC'));
const Home = lazy(() => import('custom/safran/containers/HomeContainerWrapper'));
const DashboardWrapper = lazy(() => import('generic/containers/DashboardWrapper'));

const store = configureStore();
const { MAIN_PAGE } = CONSTANTS;

// On va définir un cas particulier sur la sélection de texte, afin
// qu'on puisse faire de la sélection multiple de checkbox avec Shift
// sans sélectionner le texte
['keyup', 'keydown'].forEach((event) => {
  window.addEventListener(event, (e) => {
    document.onselectstart = () => !(e.key === 'Shift' && e.shiftKey);
  });
});

const snackbarClearButton = (ref) => (key) => (
  <Button variant="text" onClick={() => { ref.current.closeSnackbar(key); }}>
    <Clear />
  </Button>
);

function App() {
  const notistackRef = useRef();
  const { t } = useTranslation();

  return (
    <Provider store={store}>
      <ConfigWrapper>
        <ConfirmDialogContainer />
        <SnackbarProvider
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          ref={notistackRef}
          action={snackbarClearButton(notistackRef)}
        >
          <SnackbarUtilsConfigurator />
          <ConnectedRouter history={history}>
            <Switch>
              { /* protégé */ }
              <ProtectedRoutes
                path="/results"
                keepFormConfig
                component={ResultsWrapper}
                title={t('route.results')}
              />
              <ProtectedRoutes
                path="/ged/:base?/:id?"
                keepFormConfig
                component={GEDContainerHOC}
                title={t('route.article')}
              />
              <ProtectedRoutes
                path="/home"
                keepFormConfig
                component={Home}
                title={t('route.app_name')}
              />
              <ProtectedRoutes
                path="/dashboard"
                keepFormConfig
                component={DashboardWrapper}
                title={t('route.dashboard')}
              />
              <ProtectedRoutes
                path="/global"
                keepFormConfig
                component={() => 'TODO'}
                title={t('route.global_dashboard')}
              />
              <ProtectedRoutes
                path="/alerts"
                component={AlertsContainer}
                title={t('route.alerts')}
              />
              <ProtectedRoutes
                path="/alerts/:id"
                component={AlertContainer}
                title={t('route.alerts')}
              />
              <ProtectedRoutes
                path="/report"
                component={ReportContainer}
                title={t('route.report')}
              />
              <ProtectedRoutes
                path="/logout"
                component={LogoutContainer}
              />
              { /* non accessible si loggué */ }
              <PublicRoutes
                exact
                path="/login"
                component={AuthenticationContainer}
                title={t('route.app_name')}
              />
              { /* Default */ }
              <Redirect
                to={MAIN_PAGE}
              />
            </Switch>
          </ConnectedRouter>
        </SnackbarProvider>
      </ConfigWrapper>
    </Provider>
  );
}

export default App;
