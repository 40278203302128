const primary = '#263238';
const secondary = '#0097a7';

const themeConfig = {
  THEME: {
    components: {
    },
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      text: {
        dark: 'rgba(0, 0, 0, 0.87)',
        neutral: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
  HIGHCHARTS: {
    stateDocumentColor: {
      1: '#E96F51',
      50: '#F4A261',
      70: '#E9C46A',
      100: '#8AB17D',
    },
    scoreDocumentColor: {
      0: '#FF4E11',
      1: '#FF8E15',
      2: '#FAB733',
      3: '#ACB334',
      4: '#69B34C',
    },
  },
};

export default themeConfig;
