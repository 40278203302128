/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { CheckBoxOutlined, IndeterminateCheckBoxOutlined } from '@mui/icons-material';
import _ from 'lodash';
import i18next from 'i18next';
import { CUSTOM_PATH } from 'generic/core/constants';

// Site intéressant pour choisir des couleurs "associables" :
// https://www.canva.com/colors/color-wheel/

/* ------------ Complémentaires ------------ */
// Bleu et orange classique
// const primary = '#407ec9';
// const secondary = '#f3681f';

// Violet et jaune
// const primary = '#3d3d6b';
// const secondary = '#fdc431';

// Turquoise orange
// const primary = '#2a7b9b';
// const secondary = '#f58c2d';

// Bleu saumon pastel
// const primary = '#3a97a9';
// const secondary = '#cd7e6f';

/* ------------ Analogues ------------ */
// Bleu et Violet
// const primary = '#2E4A62';
// const secondary = '#4E6B89';

const primary = '#2E4A62';
const secondary = '#4E4E89';
const outlinedBorderColor = 'rgba(0, 0, 0, 0.23)';
const spacing = 8;

let THEME = {
  THEME: {
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '64px',
            '& .MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              borderRadius: '2px',
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          margin: 'dense',
          size: 'small',
          autoComplete: 'off',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '& .MuiIconButton-root': {
              padding: '4px',
            },
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          root: {
            fontSize: '1.2em',
            lineHeight: '1.4em',
          },
        },
      },
      // style des inputs outlined
      MuiOutlinedInput: {
        defaultProps: {
          notched: true,
          size: 'small',
        },
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: outlinedBorderColor,
              '& legend': {
                fontSize: '0.9em',
              },
            },
          },
        },
      },
      // style des Icônes des ListItem
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
            marginRight: '10px',
            minHeight: '22px',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            maxHeight: '500px',
          },
        },
      },
      // style des Icônes des ListItem dans le contexte
      // d'un menu
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '& .MuiListItemIcon-root': {
              minWidth: 'auto',
              marginRight: '10px',
            },
          },
        },
      },
      // style des "toasts"/tooltips
      MuiTooltip: {
        defaultProps: {
          arrow: true,
        },
        styleOverrides: {
          tooltip: {
            fontSize: '1em',
            maxWidth: '600px',
          },
        },
      },
      // style des cards
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: `0 2px 4px -2px rgb(0 0 0 / 24%),
              0 4px 5px -2px rgb(0 0 0 / 20%),
              0px -1px 3px 0px rgb(0 0 0 / 12%)`,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontWeight: 'bold',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          scrollButtons: {
            '&.Mui-disabled': {
              opacity: 0.3,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: '48px',
          },
        },
      },
      // style des labels des inputs
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#333333',
            fontSize: '1.1em',
          },
        },
      },
      // style des labels des autres inputs (checkbox, switch...)
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '100%',
            marginTop: spacing,
            marginBottom: spacing * 0.5,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '1em',
          },
        },
      },
      // style des checkbox
      MuiCheckbox: {
        defaultProps: {
          // eslint-disable-next-line react/jsx-filename-extension
          checkedIcon: <CheckBoxOutlined />,
          indeterminateIcon: <IndeterminateCheckBoxOutlined />,
        },
        styleOverrides: {
          root: {
            margin: '0px 0px 3px 10px',
            padding: '5px',
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          paper: {
            '@media (min-width: 1200px)': {
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            },
          },
          responsiveBase: {
            flexGrow: '1',
          },
        },
      },
      MUIDataTableHeadRow: {
        styleOverrides: {
          root: {
            position: 'relative',
            zIndex: 1300,
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            flexShrink: 0,
            '@media (max-width: 1200px)': {
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1300,
            },
          },
          actions: {
            flex: '0',
            display: 'flex',
          },
        },
      },
      MUIDataTableToolbarSelect: {
        styleOverrides: {
          root: {
            flex: '0',
            minHeight: '64px',
            padding: '0',
          },
        },
      },
      MUIDataTableFooter: {
        styleOverrides: {
          root: {
            '@media (max-width: 1200px)': {
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              zIndex: 1100,
            },
          },
        },
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'h6' },
            style: {
              fontSize: '1rem',
              lineHeight: 'unset',
            },
          },
        ],
      },
      // Style des boutons
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButtonGroup-groupedOutlined.Mui-disabled': {
              border: '1px solid rgba(0, 0, 0, 0.26)',
            },
            '&.MuiButtonGroup-grouped:not(:last-of-type):not(.Mui-disabled):not(.MuiButton-outlined)': {
              borderColor: 'white',
            },
          },
          endIcon: {
            margin: '0 -6px 0 4px',
          },
        },
        defaultProps: {
          variant: 'contained',
          color: 'primary',
          disableElevation: true,
        },
        variants: [
          {
            props: { size: 'extraSmall' },
            style: {
              padding: '2px 8px',
              '& svg': {
                fontSize: '18px',
              },
            },
          },
        ],
      },
      MuiLoadingButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary',
          disableElevation: true,
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: `0px 3px 5px -1px rgb(255 255 255 / 52%),
              0px 6px 10px 0px rgb(255 255 255 / 33%),
              0px 1px 18px 0px rgb(255 255 255 / 52%)`,
            '&:active': {
              boxShadow: `0px 3px 5px -1px rgb(255 255 255 / 52%),
              0px 6px 10px 0px rgb(255 255 255 / 33%),
              0px 1px 18px 0px rgb(255 255 255 / 52%)`,
            },
          },
        },
        variants: [
          {
            props: { size: 'extraSmall' },
            style: {
              height: '28px',
              minHeight: 'auto',
              width: '28px',
              '& svg': {
                fontSize: '20px',
              },
            },
          },
        ],
      },
      // Style des chips
      MuiChip: {
        styleOverrides: {
          root: {
            height: 'auto',
            minHeight: '32px',
          },
          label: {
            whiteSpace: 'normal',
          },
        },
        variants: [
          {
            props: { variant: 'filledPrimary' },
            style: {
              backgroundColor: `${primary}20`,
            },
          },
          {
            props: { variant: 'filledSecondary' },
            style: {
              backgroundColor: `${secondary}20`,
            },
          },
          {
            props: { size: 'extraSmall' },
            style: {
              minHeight: '20px',
              '& .MuiChip-icon': {
                marginLeft: '4px',
                marginRight: '-4px',
                fontSize: '14px',
              },
              '& .MuiChip-label': {
                paddingLeft: '8px',
                paddingRight: '8px',
              },
              '& .MuiChip-deleteIcon': {
                fontSize: '14px',
              },
            },
          },
        ],
      },
    },
    palette: {
      danger: {
        main: '#e53e3e',
        contrastText: '#fff',
      },
      primary: {
        main: primary,
        transparent: `${primary}20`,
        attenuated: `${primary}80`,
      },
      secondary: {
        main: secondary,
      },
      text: {
        dark: 'rgba(0, 0, 0, 0.87)',
        neutral: 'rgba(0, 0, 0, 0.54)',
      },
      // Couleurs utiles aux éléments associés aux réseaux sociaux
      dailymotion: {
        main: '#0166cc',
      },
      facebook: {
        main: '#1877f2',
      },
      linkedin: {
        main: '#0a66c2',
      },
      twitter: {
        main: '#55acee',
      },
      whatsapp: {
        main: '#3fc252',
      },
      youtube: {
        main: '#ef462c',
      },
    },
    // Valeur a modifier pour "aérer" ou "densifier" l'interface
    // si la valeur est 8 (par exemple), l'unité de "spacing" sera
    // de 8px (donc theme.spacing(2) vaudra 2*8px soit 16px)
    spacing,
    custom: {
      // style du logo dans le Header
      logo: {
        height: '38px',
        marginRight: '8px',
      },
      // style des mots highlités dans le search
      highlightKeyword: {
        fontWeight: 'bold',
        color: primary,
      },
    },
  },
  GLOBAL_STYLES: {
    '@global': {
      // bordure autour des fieldsets des formulaires
      '.formFieldset': {
        borderColor: outlinedBorderColor,
        borderRadius: '5px',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxSizing: 'border-box',
        // override du style des legends des fieldsets
        '& > legend': {
          marginLeft: '8px',
          paddingRight: '5px',
          paddingLeft: '5px',
          fontSize: '0.9em',
          '& .MuiFormControlLabel-root': {
            '& .MuiFormControlLabel-label': {
              fontSize: '1em',
            },
          },
        },
      },
      blockquote: {
        borderLeft: '5px solid #ccc',
        fontStyle: 'italic',
        marginLeft: '0',
        marginRight: '0',
        overflow: 'hidden',
        paddingLeft: '1.5em',
        paddingRight: '1.5em',
      },
      '.bottomBtnsContainer': {
        padding: '8px',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: 'white',
        position: 'sticky',
        zIndex: '1200',
        bottom: '0',
      },
      '#add-to-item-datatable .MuiToolbar-root': {
        '& .MuiFormControl-fullWidth.MuiTextField-root': {
          flex: '1 0',
        },
        '& .MuiButtonBase-root': {
          display: 'none',
        },
      },
      // styles des snackbar notistack
      '.SnackbarContent-root.SnackbarItem-contentRoot': {
        flexWrap: 'nowrap',
      },
      '#notistack-snackbar.SnackbarItem-message': {
        '@media (min-width: 600px)': {
          maxWidth: '400px',
        },
      },
      '.SnackbarItem-action .MuiButton-root': {
        color: 'white',
        minWidth: 'auto',
      },
      // style des inputs de date ayant cette classe
      '.datePickerInput': {
        width: '200px',
        paddingRight: '0',
      },
      'input[readonly]': {
        color: 'rgba(0, 0, 0, 0.38)',
        cursor: 'default',
      },
      '.inputLabelWithHelpIcon': {
        pointerEvents: 'auto',
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
          marginLeft: '8px',
        },
      },
      '.yellowStar': {
        color: '#fdd835',
        '& path': {
          stroke: '#757575',
        },
      },
      '.leftColumnAnRBtn': {
        width: '100%',
        borderRadius: 'unset',
      },
      '.height100': {
        height: '100%',
      },
      '.overflowAuto': {
        overflow: 'auto',
      },
      '.desktopOnlyOverflow': {
        '@media (min-width: 1200px)': {
          overflow: 'auto',
        },
      },
      '.padding0': {
        padding: '0',
      },
      '.width100': {
        width: '100%',
      },
      '.minWidthAuto': {
        minWidth: 'auto',
      },
      '.flexShrink0': {
        flexShrink: 0,
      },
      '.flexGrow1': {
        flexGrow: 1,
      },
      '.flexDirectionColumn': {
        flexDirection: 'column',
      },
      '.displayBlock': {
        display: 'block',
      },
      '.displayFlex': {
        display: 'flex',
      },
      '.alignItemsCenter': {
        alignItems: 'center',
      },
      '.justifyContentSpaceBetween': {
        justifyContent: 'space-between',
      },
      '.cursorPointer': {
        cursor: 'pointer',
      },
      '.positionRelative': {
        position: 'relative',
      },
    },
  },
  HIGHCHARTS: {
    // useful palettes website : https://coolors.co/palettes/popular/orange,gradient
    colors: [
      '#264653',
      '#287271',
      '#2A9D8F',
      '#8AB17D',
      '#BABB74',
      '#E9C46A',
      '#EFB366',
      '#F4A261',
      '#EE8959',
      '#E76F51',
    ],
    chartsDefaultHeight: 400,
    chart: {
      style: {
        color: '#333',
        fontFamily: 'Open Sans',
      },
    },
    title: {
      style: {
        fontFamily: 'Raleway',
        fontWeight: '100',
      },
    },
    subtitle: {
      style: {
        fontFamily: 'Raleway',
        fontWeight: '100',
      },
    },
    legend: {
      align: 'right',
      verticalAlign: 'bottom',
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineColor: '#F3F3F3',
      lineColor: '#F3F3F3',
      minorGridLineColor: '#F3F3F3',
      tickColor: '#F3F3F3',
      tickWidth: 1,
    },
    yAxis: {
      gridLineColor: '#F3F3F3',
      lineColor: '#F3F3F3',
      minorGridLineColor: '#F3F3F3',
      tickColor: '#F3F3F3',
      tickWidth: 1,
    },
    lang: {
      months: [
        i18next.t('commons.months.january'),
        i18next.t('commons.months.february'),
        i18next.t('commons.months.march'),
        i18next.t('commons.months.april'),
        i18next.t('commons.months.may'),
        i18next.t('commons.months.june'),
        i18next.t('commons.months.july'),
        i18next.t('commons.months.august'),
        i18next.t('commons.months.september'),
        i18next.t('commons.months.october'),
        i18next.t('commons.months.november'),
        i18next.t('commons.months.december'),
      ],
      shortMonths: [
        i18next.t('commons.short_months.jan'),
        i18next.t('commons.short_months.feb'),
        i18next.t('commons.short_months.mar'),
        i18next.t('commons.short_months.apr'),
        i18next.t('commons.short_months.may'),
        i18next.t('commons.short_months.jun'),
        i18next.t('commons.short_months.jul'),
        i18next.t('commons.short_months.aug'),
        i18next.t('commons.short_months.sep'),
        i18next.t('commons.short_months.oct'),
        i18next.t('commons.short_months.nov'),
        i18next.t('commons.short_months.dec'),
      ],
      weekdays: [
        i18next.t('commons.weekdays.sunday'),
        i18next.t('commons.weekdays.monday'),
        i18next.t('commons.weekdays.tuesday'),
        i18next.t('commons.weekdays.wednesday'),
        i18next.t('commons.weekdays.thursday'),
        i18next.t('commons.weekdays.friday'),
        i18next.t('commons.weekdays.saturday'),
      ],
      contextButtonTitle: i18next.t('dashboard.context_button_title'),
      downloadCSV: i18next.t('dashboard.download_csv'),
      downloadJPEG: i18next.t('dashboard.download_jpeg'),
      downloadPDF: i18next.t('dashboard.download_pdf'),
      downloadPNG: i18next.t('dashboard.download_png'),
      downloadSVG: i18next.t('dashboard.download_svg'),
      downloadXLS: i18next.t('dashboard.download_xls'),
      exitFullscreen: i18next.t('dashboard.exit_full_screen'),
      printChart: i18next.t('dashboard.print_chart'),
      viewFullscreen: i18next.t('dashboard.view_full_screen'),
      viewData: i18next.t('dashboard.view_data'),
    },
  },
};

if (CUSTOM_PATH) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: CUSTOM_THEME_CONFIG } = require(`custom/${CUSTOM_PATH}/core/theme`);
    THEME = _.merge(THEME, CUSTOM_THEME_CONFIG);
  } catch (err) {
    console.info('Pas de fichier custom de theme');
  }
}

const THEME_CONFIG = THEME;
export default THEME_CONFIG;
