import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { cartOrNewsletterPropType } from 'generic/core/qes/proptypes';
import { AddShoppingCart, ForwardToInbox } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const AddToCartOrNewsletterDialog = ({
  addToItemLoading,
  itemChosen,
  items,
  handleAddToItem,
  handleAddToNewItem,
  handleClose,
  itemsType,
  loading,
  themes,
}) => {
  const { t } = useTranslation();
  const [newItemName, setNewItemName] = useState();
  const [themeId, setThemeId] = useState(!_.isEmpty(themes) ? themes[0].theme_panier : null);
  const appTheme = useTheme();
  const smallerThanLarge = useMediaQuery(appTheme.breakpoints.down('lg'));
  const smallerThanSmall = useMediaQuery(appTheme.breakpoints.down('sm'));
  let responsiveTableType = 'standard';
  if (smallerThanLarge) {
    responsiveTableType = 'verticalAlways';
    if (smallerThanSmall) {
      responsiveTableType = 'simple';
    }
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: t(`${itemsType}.name`),
      options: {
        filter: false,
      },
    },
    {
      name: 'owner',
      label: t(`${itemsType}.owner`),
    },
    {
      name: 'number_of_documents',
      label: t(`${itemsType}.number_of_documents`),
    },
    {
      name: 'date_of_creation',
      label: t(`${itemsType}.date_of_creation`),
      options: {
        customBodyRender: (value) => {
          const date = new Date(value);
          return date.toLocaleDateString();
        },
      },
    },
    {
      name: 'actions',
      label: t('anr.action'),
      options: {
        sort: false,
        filter: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRender: (name, tableMeta) => (
          <TooltipButton
            tag="fab"
            color="primary"
            size="extraSmall"
            title={t(`${itemsType}.add_here`)}
            placement="left"
            disabled={addToItemLoading}
            onClick={() => handleAddToItem(tableMeta.rowData[0])}
          >
            {itemsType === 'newsletters' ? <ForwardToInbox /> : <AddShoppingCart />}
          </TooltipButton>
        ),
      },
    },
  ];

  const data = _.map(
    items,
    (item) => [
      item.panier,
      item.intitule,
      item.utilisateur_owner_libelle,
      item.panierDocumentsCount,
      item.date_creation,
    ],
  );

  const textLabels = t('datatable.textLabels', { returnObjects: true });
  const options = {
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    elevation: 0,
    fixedHeader: true,
    textLabels: {
      ...textLabels,
      body: {
        ...textLabels.body,
        noMatch: loading ? t('datatable.loading') : textLabels.body.noMatch,
      },
    },
    download: false,
    filter: false,
    print: false,
    searchAlwaysOpen: true,
    viewColumns: false,
    selectableRows: 'none',
    responsive: responsiveTableType,
    setTableProps: () => ({ size: 'small' }),
    onChangePage: () => {
      const table = document.querySelector('#add-to-item-datatable .MuiTable-root');
      table.scrollIntoView(true);
    },
    sortOrder: {
      name: 'date_of_creation',
      direction: 'desc',
    },
  };

  return (
    <Dialog
      scroll="paper"
      fullWidth
      maxWidth="lg"
      open
      PaperProps={{
        sx: { height: '100%' },
      }}
    >
      <DialogTitle>
        {t(`${itemsType}.choose`)}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box
          mb={2}
          display="flex"
          alignItems="center"
        >
          <Typography mr={2}>{t(`${itemsType}.create_new`)}</Typography>
          <Divider
            component="div"
            sx={{ flexGrow: '1' }}
          />
        </Box>
        <Box
          display="flex"
          alignItems="baseline"
          flexWrap="wrap"
          gap={1}
          mb={3}
        >
          <TextField
            label={t(`${itemsType}.name_of_new`)}
            sx={{ flexGrow: 1000, width: '50%' }}
            onChange={(event) => setNewItemName(event.target.value)}
          />
          {itemsType === 'newsletters' && !_.isEmpty(themes) && (
            <FormControl sx={{ minWidth: '250px', flexGrow: '1', width: 'auto' }}>
              <InputLabel id="select-theme-label">
                {t('newsletters.theme')}
              </InputLabel>
              <Select
                label={t('newsletters.theme')}
                labelId="select-theme-label"
                value={themeId}
                onChange={(event) => setThemeId(event.target.value)}
              >
                {_.map(themes, (theme) => (
                  <MenuItem key={theme.theme_panier} value={theme.theme_panier}>{theme.libelle}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TooltipButton
            title={t(`${itemsType}.create_new_and_add`)}
            tag="loadingButton"
            onClick={() => handleAddToNewItem(newItemName, themeId)}
            loading={itemChosen === 'new' && addToItemLoading}
            disabled={_.isEmpty(newItemName) || addToItemLoading}
            sx={{ flexShrink: 0 }}
          >
            {t(`${itemsType}.create_and_add`)}
          </TooltipButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography mr={2}>{t(`${itemsType}.pick_from_list`)}</Typography>
          <Divider
            component="div"
            sx={{ flexGrow: '1' }}
          />
        </Box>
        <Box
          id="add-to-item-datatable"
          overflow="auto"
        >
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            dense
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="text"
          onClick={handleClose}
          loading={addToItemLoading}
          disabled={addToItemLoading}
        >
          {t('dialog.cancel')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

AddToCartOrNewsletterDialog.propTypes = {
  itemsType: PropTypes.oneOf(['carts', 'newsletters']).isRequired,
  addToItemLoading: PropTypes.bool,
  itemChosen: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  items: PropTypes.arrayOf(cartOrNewsletterPropType),
  themes: PropTypes.arrayOf(PropTypes.shape({
    libelle: PropTypes.string,
    theme_panier: PropTypes.number,
  })),
  handleAddToItem: PropTypes.func.isRequired,
  handleAddToNewItem: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

AddToCartOrNewsletterDialog.defaultProps = {
  addToItemLoading: false,
  itemChosen: null,
  items: [],
  themes: [],
  loading: false,
};

export default AddToCartOrNewsletterDialog;
